<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <section class="page-header">
          <v-row>
            <v-col md="auto">
              <v-select
                filled
                outlined
                :items="viewModel.groups"
                item-text="name"
                item-value="id"
                label="Ministries"
                v-model="selectedMinistryId"
                no-data-text="No ministries available"
                @change="onSelectedMinistry()"
                hide-details
              ></v-select>
            </v-col>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-2"
                    icon
                    outlined
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="refreshScreen"
                    ><v-icon>mdi-refresh</v-icon></v-btn
                  >
                </template>
                <span>Refresh</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </section>
      </v-col>
    </v-row>

    <v-row v-if="!hasGroups">
      <v-col>
        <v-card elevation="0">
          <v-row>
            <v-col md="4">
              It seems that no ministries have been set up yet or you do not
              have access. Please contact your system administrator and ask them
              to set up your branch.
            </v-col>
            <v-col md="3">
              <lottie-player
                src="https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json"
                background="transparent"
                speed="1"
                style="width: 400px; height: 400px"
                loop
                autoplay
              ></lottie-player>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-show="hasGroups">
      <v-col>
        <v-tabs v-model="tab" @change="onTabClick">
          <v-tab :disabled="!allowGeneralTab">General</v-tab>
          <v-tab>People</v-tab>
          <v-tab>Positions</v-tab>
          <v-tab>Events</v-tab>
          <v-tab>Messaging</v-tab>
          <v-tab disabled>Returns</v-tab>
          <v-tab>Media</v-tab>
          <v-tab>Galleries</v-tab>
          <v-tab disabled>Polls</v-tab>
          <v-tab disabled>Reports</v-tab>
          <v-tab disabled>Security</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row v-show="hasGroups">
      <v-col>
        <v-tabs-items v-model="tab">
          <v-tab-item transition="false" eager>
            <editor-group-general
              v-show="allowGeneralTab"
              ref="EditorGroupGeneral"
              @show-alert-panel="showSuccessPanel"
            >
            </editor-group-general>
            <no-permission-card v-show="!allowGeneralTab"></no-permission-card>
          </v-tab-item>
          <v-tab-item transition="false" eager>
            <list-manager-people
              v-show="allowPeopleTab"
              ref="ListManagerPeople"
              @show-alert-panel="showSuccessPanel"
            >
            </list-manager-people>
            <no-permission-card v-show="!allowPeopleTab"></no-permission-card>
          </v-tab-item>
          <v-tab-item transition="false" eager>
            <list-manager-positions
              v-show="allowPositionsTab"
              ref="ListManagerPositions"
              @show-alert-panel="showSuccessPanel"
            >
            </list-manager-positions>
            <no-permission-card
              v-show="!allowPositionsTab"
            ></no-permission-card>
          </v-tab-item>
          <v-tab-item transition="false" eager>
            <list-manager-events
              v-show="allowEventsTab"
              ref="ListManagerEvents"
              @show-alert-panel="showSuccessPanel"
            >
            </list-manager-events>
            <no-permission-card v-show="!allowEventsTab"></no-permission-card>
          </v-tab-item>
          <v-tab-item transition="false" eager>
            <list-manager-demographics-queries
              v-show="allowMessagingTab"
              ref="ListManagerDemographicsQueries"
            >
            </list-manager-demographics-queries>
            <no-permission-card
              v-show="!allowMessagingTab"
            ></no-permission-card>
          </v-tab-item>
          <v-tab-item transition="false" eager>
            <list-manager-quarterly-return
              v-show="allowQuarterlyReturnTab"
              ref="ListManagerQuarterlyReturn"
            >
            </list-manager-quarterly-return>
            <no-permission-card
              v-show="!allowQuarterlyReturnTab"
            ></no-permission-card>
          </v-tab-item>
          <v-tab-item transition="false" eager>
            <list-manager-groups-media
              v-show="allowMediaTab"
              ref="ListMediaGroupsMedia"
              @show-alert-panel="showSuccessPanel"
            >
            </list-manager-groups-media>
            <no-permission-card v-show="!allowMediaTab"></no-permission-card>
          </v-tab-item>
          <v-tab-item transition="false" eager>
            <list-manager-galleries
              v-show="allowGalleriesTab"
              ref="ListManagerGalleries"
              @show-alert-panel="showSuccessPanel"
            >
            </list-manager-galleries>
            <no-permission-card
              v-show="!allowGalleriesTab"
            ></no-permission-card>
          </v-tab-item>
          <v-tab-item transition="false" eager>
            <list-manager-polls
              v-show="allowPollsTab"
              ref="ListManagerPolls"
              @show-alert-panel="showSuccessPanel"
            >
            </list-manager-polls>
            <no-permission-card v-show="!allowPollsTab"></no-permission-card>
          </v-tab-item>
          <v-tab-item transition="false" eager>
            <list-manager-reports
              v-show="allowReportsTab"
              ref="ListManagerReports"
            ></list-manager-reports>
            <no-permission-card v-show="!allowReportsTab"></no-permission-card>
          </v-tab-item>
          <v-tab-item transition="false" eager>
            <h2 v-show="allowSecurityTab">Security</h2>
            <no-permission-card v-show="!allowSecurityTab"></no-permission-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="auto">
        <action-alert
          :show="showAlert"
          :message="alertMessage"
          :type="alertType"
        ></action-alert>
      </v-col>
    </v-row>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import ActionAlert from "@/components/common/action-alert.vue";

import ListManagerPeople from "@/views/list-managers/list-manager-people.vue";
import ListManagerPositions from "@/views/list-managers/list-manager-positions.vue";
import ListManagerPolls from "@/views/list-managers/list-manager-polls.vue";
import ListManagerEvents from "@/views/list-managers/list-manager-events.vue";
import ListManagerMedia from "@/views/list-managers/list-manager-groups-media.vue";
import ListManagerDemographicsQueries from "@/views/list-managers/list-manager-demographics-queries.vue";
import ListManagerQuarterlyReturn from "@/views/list-managers/list-manager-quarterly-return.vue";
import ListManagerReports from "@/views/list-managers/list-manager-reports.vue";
import ListManagerGalleries from "@/views/list-managers/list-manager-galleries.vue";
import EditorGroupGeneral from "@/views/editors/editor-group-general.vue";

import NoPermissionCard from "@/components/common/no-permission-card.vue";
import { BaseListItem } from "@/models/common.model";
import { GroupType, LoadingType } from "@/models/common.model";
import {
  BranchSelectionItem,
  BranchSelectionViewModel,
} from "@/models/group.model";
import {
  PermissionArea,
  PermissionsManager,
  PermissionType,
} from "@/models/security.model";
import { GroupService } from "@/services/group.service";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "HostGroupManager",
  mixins: [CommonMixin],
  components: {
    ActionAlert,
    "editor-group-general": EditorGroupGeneral,
    "list-manager-people": ListManagerPeople,
    "list-manager-positions": ListManagerPositions,
    "list-manager-polls": ListManagerPolls,
    "list-manager-events": ListManagerEvents,
    "list-manager-groups-media": ListManagerMedia,
    "list-manager-demographics-queries": ListManagerDemographicsQueries,
    "list-manager-quarterly-return": ListManagerQuarterlyReturn,
    "list-manager-reports": ListManagerReports,
    "list-manager-galleries": ListManagerGalleries,
    "no-permission-card": NoPermissionCard,
  },

  computed: {
    hasGroups(): boolean {
      return this.viewModel.groups.length > 0;
    },
  },
  data() {
    return {
      tab: 0,
      selectedMinistryId: 0,
      selectedMinistry: {} as BranchSelectionItem | undefined,
      viewModel: new BranchSelectionViewModel(),
      groups: new Array<BaseListItem>(),
      showMainView: false,
      showNewGroupDialog: false,
      showDeleteGroupDialog: false,
      canManageGroups: false,
      allowGeneralTab: false,
      allowPeopleTab: false,
      allowPositionsTab: false,
      allowEventsTab: false,
      allowPollsTab: false,
      allowMediaTab: false,
      allowReportsTab: false,
      allowMessagingTab: false,
      allowSecurityTab: false,
      allowQuarterlyReturnTab: false,
      allowGalleriesTab: false,
      tabIndexGeneral: 0,
      tabIndexPeople: 1,
      tabIndexPositions: 2,
      tabIndexEvents: 3,
      tabIndexMessaging: 4,
      tabIndexReturns: 5,
      tabIndexMedia: 6,
      tabIndexGalleries: 7,
      tabIndexPolls: 8,
      tabIndexReports: 9,
      tabIndexSecurity: 10,
    };
  },
  created() {
    if (this.$route.query.ministryId && this.$route.query.tab) {
      let ministryId = parseInt(this.$route.query.ministryId.toString());

      let tab = this.$route.query.tab.toString();
      let tabIndex = this.getTabIndex(tab);

      this.getMinistrySelections(ministryId, tabIndex);
    } else {
      this.getMinistrySelections(0, 0);
    }
  },
  methods: {
    refreshScreen() {
      this.onTabClick(this.tab);
    },

    getTabIndex(name: string): number {
      let index = this.tabIndexGeneral;

      switch (name) {
        case "general":
          index = this.tabIndexGeneral;
          break;
        case "people":
          index = this.tabIndexPeople;
          break;
        case "positions":
          index = this.tabIndexPositions;
          break;
        case "events":
          index = this.tabIndexEvents;
          break;
        case "messaging":
          index = this.tabIndexMessaging;
          break;
        case "quarterly-return":
          index = this.tabIndexReturns;
          break;
        case "media":
          index = this.tabIndexMedia;
          break;
        case "polls":
          index = this.tabIndexPolls;
          break;
        case "reports":
          index = this.tabIndexReports;
          break;
        case "security":
          index = this.tabIndexSecurity;
          break;
        case "galleries":
          index = this.tabIndexGalleries;
          break;
      }

      return index;
    },

    getMinistrySelections(ministryId: number, tabIndex: number) {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Getting Information, Please Wait..."
      );

      this.selectedMinistryId = ministryId;

      const service = new GroupService();
      service
        .getGroupsForSelection(GroupType.NationalMinistry)
        .then((response) => {
          this.hideProgressIndicator();
          this.viewModel = response.data;

          if (this.viewModel.groups.length > 0) {
            // Add a blank item so that the user can select just a branch
            this.viewModel.groups.forEach((element) => {
              element.groups.unshift(new BaseListItem());
            });

            // If we have come into this screen for the 1st time then
            // ensure that the 1st branch is considered the selected branch
            if (this.selectedMinistryId == 0) {
              this.selectedMinistryId = this.viewModel.groups[0].id;
            }

            // Get the selected branch so that we can get hold of it's groups
            let match = this.viewModel.groups.find(
              (b) => b.id == this.selectedMinistryId
            );
            if (match) {
              this.selectedMinistry = match;
            }

            // If we have come into this screen for the 1st then ensure that the
            // selected group Id is the 1st one if the branch has groups.
            // Coming into this screen from a sub-screen may have this already set
            if (this.selectedMinistry && this.selectedMinistry.groups.length > 0) {
              this.groups = this.selectedMinistry.groups;
            }
          }

          this.tab = tabIndex;
          this.setTabPermissions();
          this.onBranchOrGroupChange();
        })
        .catch((error) => this.showErrorDialog(error));
    },

    onSelectedMinistry() {
      const ministry = this.viewModel.groups.find(
        (f) => f.id === this.selectedMinistryId
      );
      if (ministry) {
        this.selectedMinistry = ministry;
      }
      this.groups = ministry ? ministry.groups : [];
      this.setTabPermissions();
      this.onBranchOrGroupChange();
    },

    onBranchOrGroupChange() {
      if (this.selectedMinistry) {
        let name = `Ministries: ${this.selectedMinistry.name}`;
        this.updateNavTitle(name);
      }
      this.onTabClick(this.tab);
    },

    setTabPermissions() {
      const isSuperAdmin: boolean = PermissionsManager.isSuperAdministrator();
      const groupType = GroupType.NationalMinistry;
      this.canManageGroups = PermissionsManager.hasPermission(
        this.selectedMinistryId,
        null,
        groupType,
        PermissionType.ManageGroups,
        PermissionArea.None
      );

      if (isSuperAdmin) {
        this.allowGeneralTab = true;
        this.allowPeopleTab = true;
        this.allowPositionsTab = true;
        this.allowEventsTab = true;
        this.allowPollsTab = true;
        this.allowMediaTab = true;
        this.allowMessagingTab = true;
        this.allowReportsTab = true;
        this.allowSecurityTab = true;
        this.allowQuarterlyReturnTab = true;
        this.allowGalleriesTab = true;
      } else {
        this.allowGeneralTab = PermissionsManager.hasPermission(
          this.selectedMinistryId,
          null,
          groupType,
          PermissionType.List,
          PermissionArea.Overview
        );
        this.allowPeopleTab = PermissionsManager.hasPermission(
          this.selectedMinistryId,
          null,
          groupType,
          PermissionType.List,
          PermissionArea.Membership
        );
        this.allowPositionsTab = PermissionsManager.hasPermission(
          this.selectedMinistryId,
          null,
          groupType,
          PermissionType.List,
          PermissionArea.Positions
        );
        this.allowEventsTab = PermissionsManager.hasPermission(
          this.selectedMinistryId,
          null,
          groupType,
          PermissionType.List,
          PermissionArea.Events
        );
        this.allowPollsTab = PermissionsManager.hasPermission(
          this.selectedMinistryId,
          null,
          groupType,
          PermissionType.List,
          PermissionArea.Polls
        );
        this.allowMediaTab = PermissionsManager.hasPermission(
          this.selectedMinistryId,
          null,
          groupType,
          PermissionType.List,
          PermissionArea.Media
        );
        this.allowMessagingTab = PermissionsManager.hasPermission(
          this.selectedMinistryId,
          null,
          groupType,
          PermissionType.List,
          PermissionArea.Messaging
        );
        this.allowReportsTab = PermissionsManager.hasPermission(
          this.selectedMinistryId,
          null,
          groupType,
          PermissionType.List,
          PermissionArea.Reports
        );
        this.allowSecurityTab = PermissionsManager.hasPermission(
          this.selectedMinistryId,
          null,
          groupType,
          PermissionType.List,
          PermissionArea.Security
        );
        this.allowQuarterlyReturnTab = PermissionsManager.hasPermission(
          this.selectedMinistryId,
          null,
          groupType,
          PermissionType.List,
          PermissionArea.QuarterlyReturn
        );
        this.allowGalleriesTab = PermissionsManager.hasPermission(
          this.selectedMinistryId,
          null,
          groupType,
          PermissionType.List,
          PermissionArea.Galleries
        );
      }
    },

    onTabClick(index: any) {
      // Make sure there are no temporary event info dialogs about
      if (index !== 3) {
        this.removeTemporaryEvents();
      }

      switch (index) {
        case this.tabIndexGeneral:
          this.showBranchMainView();
          break;
        case this.tabIndexPeople:
          this.showGroupMembershipManager();
          break;
        case this.tabIndexPositions:
          this.showGroupPositionManager();
          break;
        case this.tabIndexEvents:
          this.showGroupEventsManager();
          break;
        case this.tabIndexMessaging:
          this.showMessagingManager();
          break;
        case this.tabIndexReturns:
          this.showQuarterlyReturnManager();
          break;
        case this.tabIndexMedia:
          this.showGroupContributionManager();
          break;
        case this.tabIndexPolls:
          this.showGroupPollsManager();
          break;
        case this.tabIndexSecurity:
          this.showSecurity();
          break;
        case this.tabIndexGalleries:
          this.showGalleries();
          break;
      }
    },

    removeTemporaryEvents() {
      if (this.allowEventsTab) {
        const ref: any = this.$refs.ListManagerEvents;
        if (ref) {
          ref.removeTemporaryEvents();
        }
      }
    },

    showBranchMainView() {
      if (this.allowGeneralTab) {
        const ref: any = this.$refs.EditorGroupGeneral;
        if (ref) {
          ref.load(this.selectedMinistryId, 0);
        }
      }
    },

    showGroupMembershipManager() {
      if (this.allowPeopleTab) {
        const ref: any = this.$refs.ListManagerPeople;
        if (ref) {
          ref.getItems(this.selectedMinistryId, 0, GroupType.NationalMinistry);
        }
      }
    },

    showGroupPositionManager() {
      if (this.allowPositionsTab) {
        const ref: any = this.$refs.ListManagerPositions;
        if (ref) {
          ref.getItems(this.selectedMinistryId, 0, GroupType.NationalMinistry);
        }
      }
    },

    showGroupEventsManager() {
      if (this.allowEventsTab) {
        const ref: any = this.$refs.ListManagerEvents;
        if (ref) {
          ref.getItems(this.selectedMinistryId, 0, GroupType.NationalMinistry);
        }
      }
    },

    showGroupPollsManager() {
      if (this.allowPollsTab) {
        const ref: any = this.$refs.ListManagerPolls;
        if (ref) {
          ref.getItems(this.selectedMinistryId, 0, GroupType.NationalMinistry);
        }
      }
    },

    showGroupContributionManager() {
      if (this.allowMediaTab) {
        const ref: any = this.$refs.ListMediaGroupsMedia;
        if (ref) {
          ref.getItems(this.selectedMinistryId, 0, GroupType.NationalMinistry);
        }
      }
    },

    showMessagingManager() {
      if (this.allowMessagingTab) {
        const ref: any = this.$refs.ListManagerDemographicsQueries;
        if (ref) {
          ref.getItems();
        }
      }
    },

    showSecurity() {},

    showQuarterlyReturnManager() {
      if (this.allowQuarterlyReturnTab) {
        const ref: any = this.$refs.ListManagerQuarterlyReturn;
        if (ref) {
          ref.getItems(this.selectedMinistryId, 0, GroupType.NationalMinistry);
        }
      }
    },

    showGalleries() {
      if (this.allowGalleriesTab) {
        const ref: any = this.$refs.ListManagerGalleries;
        if (ref) {
          ref.getItems(this.selectedMinistryId, 0, GroupType.NationalMinistry);
        }
      }
    },

    showSuccessPanel(message: string, type: string) {
      this.showAlertPanel(message, type);
    },
  },
});
</script>
<style scoped src="@/assets/css/wg.css"></style>