<template>
  <v-container fluid>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">1</span>About</h6>
        <p class="small" style="padding-right: 40px">
          This section is to provide basic summary about the
          <b>{{ formData.groupType }}.</b>
        </p>

        <image-selector
          :imageUrl="imageUrl"
          @uploaded-image-selected="uploadedImageSelected"
          @approved-image-selected="approvedImageSelected"
        >
        </image-selector>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.name"
              label="Name"
              placeholder="Name"
              outlined
              clearable
              hide-details="auto"
              maxlength="80"
              :rules="[rules.required]"
              :disabled="formData.id > 0"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="formData.ourReference"
              label="Our Reference"
              placeholder="Our Reference"
              outlined
              clearable
              hide-details="auto"
              maxlength="8"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.charityNumber"
              label="Charity Number"
              placeholder="Charity Number"
              outlined
              clearable
              hide-details="auto"
              maxlength="20"
            ></v-text-field>
          </v-col>
          <v-col>
            <date-picker
              :date.sync="formData.dateEstablished"
              :dateRules="validateDateEstablished"
              label="Date Established"
            ></date-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="formData.donationPaymentsUrl"
              label="Donations Payment Link"
              placeholder="Donations Payment Link"
              outlined
              clearable
              hide-details="auto"
              maxLength="400"
              :rules="[rules.url]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.strapline"
              label="Strapline"
              placeholder="Please specify in a few short words that encapsulates you!"
              outlined
              clearable
              hide-details="auto"
              maxlength="280"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              label="Overview"
              placeholder="Provide a description of you including your vision and mission"
              auto-grow
              outlined
              counter
              clearable
              dense
              rows="6"
              hide-details="auto"
              maxlength="4000"
              v-model="formData.overview"
              :rules="[rules.required]"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              label="Vision and Mission"
              placeholder="Provide a summary of your vision and mission."
              auto-grow
              outlined
              counter
              clearable
              dense
              rows="6"
              hide-details="auto"
              maxlength="1000"
              v-model="formData.visionAndMission"
              :rules="[rules.required]"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">2</span>Services</h6>
        <p class="small" style="padding-right: 40px">
          This section is to provide details on the type of services provided by
          the
          {{ formData.groupType }}.
        </p>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <v-textarea
              label="Services Overview"
              placeholder="Provide a summarised description of the services you provide."
              auto-grow
              outlined
              counter
              clearable
              rows="4"
              hide-details="auto"
              maxlength="2000"
              v-model="formData.servicesOverview"
              :rules="[rules.required]"
              dense
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.services1"
              label="Service 1"
              placeholder="List a specific that you provide service here"
              outlined
              clearable
              hide-details="auto"
              maxlength="200"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="formData.services2"
              label="Service 2"
              placeholder="List a specific that you provide service here"
              outlined
              clearable
              hide-details="auto"
              maxlength="200"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.services3"
              label="Service 3"
              placeholder="List a specific that you provide service here"
              outlined
              clearable
              hide-details="auto"
              maxlength="200"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="formData.services4"
              label="Service 4"
              placeholder="List a specific that you provide service here"
              outlined
              clearable
              hide-details="auto"
              maxlength="200"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.services5"
              label="Service 5"
              placeholder="List a specific that you provide service here"
              outlined
              clearable
              hide-details="auto"
              maxlength="200"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">3</span>Location</h6>
        <p class="small" style="padding-right: 40px">
          This section is to provide details on where to locate or send
          correspondence to the
          {{ formData.groupType }}.
        </p>
      </v-col>
      <v-col>
        <v-row>
          <v-col v-for="location of formData.locations" :key="location.localId">
            <map-autocomplete
              :id="location.localId"
              :address="location.address"
              :placeholder="`${location.category} Location`"
              :ref="location.localId"
              :hideDetails="true"
              @addressChanged="onAddressChange"
            >
            </map-autocomplete>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">4</span>Contact</h6>
        <p class="small" style="padding-right: 40px">
          This section is to provide the main contact points for the
          {{ formData.groupType }}.
        </p>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.email"
              label="Email"
              placeholder="Email"
              outlined
              clearable
              hide-details="auto"
              maxlength="256"
              :rules="[rules.required, rules.email]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="formData.contactNumber"
              label="Contact Number"
              placeholder="Contact Number"
              outlined
              clearable
              hide-details="auto"
              maxlength="20"
              :rules="[rules.phone]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.webSiteUrl"
              label="Web Site Url"
              placeholder="Enter your web site link."
              outlined
              clearable
              hide-details="auto"
              maxlength="120"
              :rules="[rules.url]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="formData.feedbackUrl"
              label="Feedback Url"
              placeholder="Url that links to a form that allows others to provide feedback."
              outlined
              clearable
              hide-details="auto"
              maxlength="400"
              :rules="[rules.url]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">5</span>Socials</h6>
        <p class="small" style="padding-right: 40px">
          This section is to provide information on any social media or external
          platforms that the group is on.
        </p>
      </v-col>
      <v-col>
        <v-row v-for="social of formData.socials" :key="social.platform">
          <v-col>
            <v-select
              :items="formData.externalPlatforms"
              v-model="social.platform"
              label="Platform"
              placeholder="type of social platform"
              menu-props="auto"
              hide-details="auto"
              outlined
              clearable
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              v-model="social.contentUrl"
              label="Platform Url"
              placeholder="The url of the platform"
              outlined
              clearable
              hide-details="auto"
              maxlength="200"
              :rules="[rules.url]"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="ma-2"
                  text
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="addExternalPlatform"
                  hide-details="auto"
                >
                  <v-icon> mdi-cellphone </v-icon>
                </v-btn>
              </template>
              <span>add platform</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red"
                  class="ma-2"
                  text
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="removeExternalPlatform(social)"
                  hide-details="auto"
                >
                  <v-icon> mdi-cellphone-remove </v-icon>
                </v-btn>
              </template>
              <span>remove platform</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <approved-image-selector
      :dialog="showApprovedImageSelector"
      :multiSelect="false"
      :items="approvedImages"
      @dialog-option-selected="onApprovedImageSelection"
    >
    </approved-image-selector>
    <image-uploader
      :dialog="showImageUploadDialog"
      @dialog-option-selected="onImageUploaded"
    ></image-uploader>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import GroupMixin from "@/mixins/group.mixin";
import dateTimePicker from "@/components/common/date-time-picker.vue";
import ApprovedImageSelector from "@/components/common/approved-image-selector.vue";
import imageUploader from "@/components/common/image-uploader.vue";
import MapAutocomplete from "@/components/common/maps-autocomplete.vue";
import { ManageGroupViewModel } from "@/models/group.model";
import {
  ApprovedImages,
  ISocialMediaReference,
  LoadingType,
  SocialMediaReference,
} from "@/models/common.model";
import {
  RichTextEditorPlugin,
  Toolbar,
  Link,
  Image,
  Count,
  HtmlEditor,
  QuickToolbar,
} from "@syncfusion/ej2-vue-richtexteditor";
import { DateTime } from "luxon";
import { EventLocation, IPlaceResultData } from "@/models/events.model";
import { ConferenceService } from "@/services/conference.service";
import { IApprovedImage } from "@/models/conference.model";

import DatePicker from "@/components/common/date-picker.vue";
import ImageSelector from "@/components/common/image-selector.vue";
import { GroupService } from "@/services/group.service";

Vue.use(RichTextEditorPlugin);

export default (
  Vue as VueConstructor<
    Vue & InstanceType<typeof CommonMixin> & InstanceType<typeof GroupMixin>
  >
).extend({
  name: "EditorSectionGroupMainDetails",
  mixins: [CommonMixin, GroupMixin],
  components: {
    "map-autocomplete": MapAutocomplete,
    dateTimePicker,
    ApprovedImageSelector,
    imageUploader,
    "date-picker": DatePicker,
    "image-selector": ImageSelector,
  },
  provide: {
    richtexteditor: [Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar],
  },
  computed: {
    validateDateEstablished(): string[] {
      let err: string[] = [];

      if (this.formData.dateEstablished) {
        const dr: DateTime = DateTime.fromISO(
          this.formData.dateEstablished.toString()
        );
        if (dr.diffNow("days").days > 1) {
          err.push("The date registered cannot be in the future");
        }
      }

      return err;
    },
  },
  props: {
    formData: {
      type: Object,
      default: new ManageGroupViewModel(),
    },
  },
  watch: {
    formData(val: ManageGroupViewModel) {
      this.imageUrl = val.imageUrl
        ? val.imageUrl
        : val.imageUrl ?? this.placeholder;
      if (this.formData.socials.length == 0) {
        this.formData.socials.push(new SocialMediaReference());
      }
    },
  },
  data() {
    return {
      country: ["GB"],
      toolbarSettings: {
        type: "Expand",
        items: [
          "Bold",
          "Italic",
          "Underline",
          "|",
          "FontSize",
          "|",
          "Alignments",
          "|",
          "OrderedList",
          "UnorderedList",
          "Outdent",
          "Indent",
          "|",
          "CreateLink",
          "|",
          "Undo",
          "Redo",
        ],
      },
      approvedImages: Array<IApprovedImage>(),
      showApprovedImageSelector: false,
      placeholder: require("@/assets/images/image-placeholder.png"),
      imageUrl: "",
      showImageUploadDialog: false,
    };
  },

  methods: {
    uploadedImageSelected(imageUrl: string) {
      this.imageUrl = imageUrl;
      this.formData.imageUrl = imageUrl;
      this.updateImage();
    },

    approvedImageSelected(imageUrl: string) {
      this.imageUrl = imageUrl;
      this.formData.imageUrl = imageUrl;
      this.updateImage();
    },

    updateImage() {
      if (this.formData.id) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Updating group image, Please Wait..."
        );

        const service = new GroupService();
        service
          .updateImage(this.formData.id, this.formData.imageUrl)
          .then(() => {
            this.hideProgressIndicator();
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    onAddressChange(val: EventLocation) {
      let location = this.formData.locations.find(
        (f: EventLocation) => f.localId === val.localId
      ) as EventLocation;

      if (location) {
        location.address = val.address;
        location.houseNameOrNumber = val.houseNameOrNumber;
        location.street = val.street;
        location.city = val.city;
        location.country = val.country;
        location.postCode = val.postCode;
        location.region = val.region;
        location.url = val.url;
        location.longitude = val.longitude;
        location.latitude = val.latitude;
      }
    },

    showImageSelector() {
      this.getApprovedImages();
    },

    onApprovedImageSelection(items: IApprovedImage[]) {
      this.showApprovedImageSelector = false;
      if (items.length > 0) {
        this.formData.imageUrl = items[0].imageUrl;
      }
      this.imageUrl = this.formData.imageUrl
        ? this.formData.imageUrl
        : this.formData.imageUrl ?? this.placeholder;
    },

    getApprovedImages() {
      if (this.approvedImages.length === 0) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Getting Approved Images, Please Wait..."
        );

        // Get the list of approved images from the web platform as they are easier to see
        // in the CMS and the equivalent ones will be used when used in the mobile application
        const service = new ConferenceService();
        service
          .getApprovedImages(
            ApprovedImages.Platform.Web,
            ApprovedImages.Category.Events
          )
          .then((response) => {
            this.hideProgressIndicator();
            this.approvedImages = response.data;
            this.showApprovedImageSelector = true;
          })
          .catch((error) => this.showErrorDialog(error));
      } else {
        for (let item of this.approvedImages) {
          item.selected = false;
        }
        this.showApprovedImageSelector = true;
      }
    },

    clearImage() {
      this.formData.imageUrl = "";
      this.imageUrl = this.placeholder;
    },

    showImageUploader() {
      this.showImageUploadDialog = true;
    },

    onImageUploaded(url: string) {
      this.showImageUploadDialog = false;
      if (url) {
        this.imageUrl = url;
        this.formData.imageUrl = url;
      }
    },

    addExternalPlatform() {
      if (this.formData.socials.length < 5) {
        this.formData.socials.push(new SocialMediaReference());
      }
    },

    removeExternalPlatform(item: ISocialMediaReference) {
      if (this.formData.socials.length > 0) {
        const index = this.formData.socials.indexOf(item);
        this.formData.socials.splice(index, 1);
      }
    },
  },
});
</script>
<style scoped src="@/assets/css/wg.css"></style>