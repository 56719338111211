<template>
  <v-menu rounded offset-y>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <v-btn icon v-if="hasImageUrl">
          <v-avatar rounded>
            <img
              class="avatar"
              transition="scale-transition"
              v-bind:src="imageUrl"
              v-bind:alt="displayName"
            />
          </v-avatar>
        </v-btn>
        <v-btn icon v-else-if="!hasImageUrl">
          <v-avatar color="grey lighten-2">{{ initials }}</v-avatar>
        </v-btn>
        <!-- {{ displayName | titlecase }} -->
      </span>
    </template>
    <v-list>
      <v-list-item
        v-for="item in menuItems"
        :key="item"
        link
        @click="menuActionClick(item)"
      >
        <v-list-item-title v-text="item"></v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import Vue from "vue";
import firebase from "firebase";
import store from "@/store";
import { mapGetters } from "vuex";
import { PermissionsManager } from "@/models/security.model";

export default Vue.extend({
  name: "UserProfile",
  computed: {
    ...mapGetters({
      user: "user",
    }),
    displayName() {
      return this.user.profile && this.user.profile.displayName.length > 0
        ? this.user.profile.displayName
        : "Unknown";
    },
    initials() {
      let displayName =
        this.user.profile && this.user.profile.displayName.length > 0
          ? this.user.profile.displayName
          : "Unknown";

      const indexOfSplitInName = displayName.indexOf(" ");

      return indexOfSplitInName !== -1
        ? `${displayName[0].toUpperCase()} ${displayName[
            indexOfSplitInName + 1
          ].toUpperCase()}`
        : "N/A";
    },
    hasImageUrl(): boolean {
      return this.user.profile && this.user.profile.photoURL ? true : false;
    },
    imageUrl(): string {
      return this.user.profile && this.user.profile.photoURL
        ? this.user.profile.photoURL
        : "";
    },
    menuItems(): string[] {
      let items: string[] = [];

      //items.push("Settings");
      if (PermissionsManager.isSuperAdministrator()) {
        items.push("Conference Manager");
      }
      items.push("Sign Out");

      return items;
    },
  },
  methods: {
    menuActionClick(action: string) {
      switch (action) {
        case "Sign Out":
          firebase
            .auth()
            .signOut()
            .then(async () => {
              await store.dispatch("refreshUser", null);
              localStorage.clear();
              this.$router.replace({ name: "login" });
            });
          break;
        case "Conference Manager":
          this.$router.replace({ name: "host-conference-manager" });
          break;
        case "Settings":
          this.$router.replace({ name: "settings" });
          break;
      }
    },
  },
  filters: {
    titlecase(value: string) {
      return value.replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>
