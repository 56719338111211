var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "e-panel",
      attrs: {
        id: _vm.id,
        "data-row": _vm.row,
        "data-col": _vm.col,
        "data-sizeX": _vm.colSpan,
        "data-sizeY": _vm.rowSpan,
      },
    },
    [
      _c("div", { staticClass: "e-panel-container" }, [
        _c("div", { staticClass: "panel-container" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "panel-row panel-body" },
            [
              _c(
                "ejs-accumulationchart",
                {
                  attrs: {
                    id: _vm.chartId,
                    legendSettings: _vm.legendSettings,
                    tooltip: _vm.tooltip,
                    enableSmartLabels: _vm.enableSmartLabels,
                  },
                },
                [
                  _c(
                    "e-accumulation-series-collection",
                    [
                      _c("e-accumulation-series", {
                        attrs: {
                          xName: "x",
                          yName: "y",
                          startAngle: 0,
                          endAngle: 360,
                          dataSource: _vm.pieData,
                          dataLabel: _vm.dataLabel,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._m(1),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-row" }, [
      _c("span", [_vm._v("header")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-row" }, [
      _c("span", [_vm._v("Footer")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }