var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "3", xl: "2" } },
            [
              _c("h6", [
                _c("span", { staticClass: "number" }, [_vm._v("1")]),
                _vm._v("About"),
              ]),
              _c(
                "p",
                {
                  staticClass: "small",
                  staticStyle: { "padding-right": "40px" },
                },
                [
                  _vm._v(
                    " This section is to provide basic summary about the "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.formData.groupType) + ".")]),
                ]
              ),
              _c("image-selector", {
                attrs: { imageUrl: _vm.imageUrl },
                on: {
                  "uploaded-image-selected": _vm.uploadedImageSelected,
                  "approved-image-selected": _vm.approvedImageSelected,
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Name",
                          placeholder: "Name",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "80",
                          rules: [_vm.rules.required],
                          disabled: _vm.formData.id > 0,
                        },
                        model: {
                          value: _vm.formData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.isLocalMinistry
                    ? _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.formData.nationalMinistries,
                              label: "Affiliated National Ministry",
                              placeholder:
                                "if applicable, select affiliated national ministry",
                              "menu-props": "auto",
                              "hide-details": "auto",
                              "item-text": "name",
                              "item-value": "id",
                              "return-object": false,
                              outlined: "",
                              clearable: "",
                            },
                            model: {
                              value: _vm.formData.affiliatedNationalMinistryId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "affiliatedNationalMinistryId",
                                  $$v
                                )
                              },
                              expression:
                                "formData.affiliatedNationalMinistryId",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Our Reference",
                          placeholder: "Our Reference",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "8",
                          rules: [_vm.rules.required],
                        },
                        model: {
                          value: _vm.formData.ourReference,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "ourReference", $$v)
                          },
                          expression: "formData.ourReference",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Charity Number",
                          placeholder: "Charity Number",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "20",
                        },
                        model: {
                          value: _vm.formData.charityNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "charityNumber", $$v)
                          },
                          expression: "formData.charityNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("date-picker", {
                        attrs: {
                          date: _vm.formData.dateEstablished,
                          dateRules: _vm.validateDateEstablished,
                          label: "Date Established",
                        },
                        on: {
                          "update:date": function ($event) {
                            return _vm.$set(
                              _vm.formData,
                              "dateEstablished",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Donations Payment Link",
                          placeholder: "Donations Payment Link",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxLength: "400",
                          rules: [_vm.rules.url],
                        },
                        model: {
                          value: _vm.formData.donationPaymentsUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "donationPaymentsUrl", $$v)
                          },
                          expression: "formData.donationPaymentsUrl",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Strapline",
                          placeholder:
                            "Please specify in a few short words that encapsulates you!",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "280",
                        },
                        model: {
                          value: _vm.formData.strapline,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "strapline", $$v)
                          },
                          expression: "formData.strapline",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-textarea", {
                        attrs: {
                          label: "Overview",
                          placeholder:
                            "Provide a description of you including your vision and mission",
                          "auto-grow": "",
                          outlined: "",
                          counter: "",
                          clearable: "",
                          dense: "",
                          rows: "6",
                          "hide-details": "auto",
                          maxlength: "4000",
                          rules: [_vm.rules.required],
                        },
                        model: {
                          value: _vm.formData.overview,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "overview", $$v)
                          },
                          expression: "formData.overview",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-textarea", {
                        attrs: {
                          label: "Vision and Mission",
                          placeholder:
                            "Provide a summary of your vision and mission.",
                          "auto-grow": "",
                          outlined: "",
                          counter: "",
                          clearable: "",
                          dense: "",
                          rows: "6",
                          "hide-details": "auto",
                          maxlength: "1000",
                          rules: [_vm.rules.required],
                        },
                        model: {
                          value: _vm.formData.visionAndMission,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "visionAndMission", $$v)
                          },
                          expression: "formData.visionAndMission",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("2")]),
              _vm._v("Services"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " This section is to provide details on the type of services provided by the " +
                    _vm._s(_vm.formData.groupType) +
                    ". "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-textarea", {
                        attrs: {
                          label: "Services Overview",
                          placeholder:
                            "Provide a summarised description of the services you provide.",
                          "auto-grow": "",
                          outlined: "",
                          counter: "",
                          clearable: "",
                          rows: "4",
                          "hide-details": "auto",
                          maxlength: "2000",
                          rules: [_vm.rules.required],
                          dense: "",
                        },
                        model: {
                          value: _vm.formData.servicesOverview,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "servicesOverview", $$v)
                          },
                          expression: "formData.servicesOverview",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Service 1",
                          placeholder:
                            "List a specific that you provide service here",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "200",
                        },
                        model: {
                          value: _vm.formData.services1,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "services1", $$v)
                          },
                          expression: "formData.services1",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Service 2",
                          placeholder:
                            "List a specific that you provide service here",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "200",
                        },
                        model: {
                          value: _vm.formData.services2,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "services2", $$v)
                          },
                          expression: "formData.services2",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Service 3",
                          placeholder:
                            "List a specific that you provide service here",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "200",
                        },
                        model: {
                          value: _vm.formData.services3,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "services3", $$v)
                          },
                          expression: "formData.services3",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Service 4",
                          placeholder:
                            "List a specific that you provide service here",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "200",
                        },
                        model: {
                          value: _vm.formData.services4,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "services4", $$v)
                          },
                          expression: "formData.services4",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Service 5",
                          placeholder:
                            "List a specific that you provide service here",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "200",
                        },
                        model: {
                          value: _vm.formData.services5,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "services5", $$v)
                          },
                          expression: "formData.services5",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("3")]),
              _vm._v("Location"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " This section is to provide details on where to locate or send correspondence to the " +
                    _vm._s(_vm.formData.groupType) +
                    ". "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            [
              _c(
                "v-row",
                _vm._l(_vm.formData.locations, function (location) {
                  return _c(
                    "v-col",
                    { key: location.localId },
                    [
                      _c("map-autocomplete", {
                        ref: location.localId,
                        refInFor: true,
                        attrs: {
                          id: location.localId,
                          address: location.address,
                          placeholder: location.category + " Location",
                          hideDetails: true,
                        },
                        on: { addressChanged: _vm.onAddressChange },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("4")]),
              _vm._v("Contact"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " This section is to provide the main contact points for the " +
                    _vm._s(_vm.formData.groupType) +
                    ". "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Email",
                          placeholder: "Email",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "256",
                          rules: [_vm.rules.required, _vm.rules.email],
                        },
                        model: {
                          value: _vm.formData.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "email", $$v)
                          },
                          expression: "formData.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Contact Number",
                          placeholder: "Contact Number",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "20",
                          rules: [_vm.rules.phone],
                        },
                        model: {
                          value: _vm.formData.contactNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "contactNumber", $$v)
                          },
                          expression: "formData.contactNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Web Site Url",
                          placeholder: "Enter your web site link.",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "120",
                          rules: [_vm.rules.url],
                        },
                        model: {
                          value: _vm.formData.webSiteUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "webSiteUrl", $$v)
                          },
                          expression: "formData.webSiteUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Feedback Url",
                          placeholder:
                            "Url that links to a form that allows others to provide feedback.",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "400",
                          rules: [_vm.rules.url],
                        },
                        model: {
                          value: _vm.formData.feedbackUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "feedbackUrl", $$v)
                          },
                          expression: "formData.feedbackUrl",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("5")]),
              _vm._v("Socials"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " This section is to provide information on any social media or external platforms that the group is on. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            _vm._l(_vm.formData.socials, function (social) {
              return _c(
                "v-row",
                { key: social.platform },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.formData.externalPlatforms,
                          label: "Platform",
                          placeholder: "type of social platform",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          outlined: "",
                          clearable: "",
                        },
                        model: {
                          value: social.platform,
                          callback: function ($$v) {
                            _vm.$set(social, "platform", $$v)
                          },
                          expression: "social.platform",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Platform Url",
                          placeholder: "The url of the platform",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "200",
                          rules: [_vm.rules.url],
                        },
                        model: {
                          value: social.contentUrl,
                          callback: function ($$v) {
                            _vm.$set(social, "contentUrl", $$v)
                          },
                          expression: "social.contentUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { md: "3" } },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ma-2",
                                            attrs: {
                                              color: "primary",
                                              text: "",
                                              icon: "",
                                              "hide-details": "auto",
                                            },
                                            on: {
                                              click: _vm.addExternalPlatform,
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v(" mdi-cellphone "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("add platform")])]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ma-2",
                                            attrs: {
                                              color: "red",
                                              text: "",
                                              icon: "",
                                              "hide-details": "auto",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeExternalPlatform(
                                                  social
                                                )
                                              },
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v(" mdi-cellphone-remove "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("remove platform")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("approved-image-selector", {
        attrs: {
          dialog: _vm.showApprovedImageSelector,
          multiSelect: false,
          items: _vm.approvedImages,
        },
        on: { "dialog-option-selected": _vm.onApprovedImageSelection },
      }),
      _c("image-uploader", {
        attrs: { dialog: _vm.showImageUploadDialog },
        on: { "dialog-option-selected": _vm.onImageUploaded },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }