<template>
  <v-container fluid>
    <v-row>
      <v-col lg="4">
        <v-autocomplete
          :items="members"
          :loading="isMatching"
          :search-input.sync="matching"
          item-text="name"
          item-value="id"
          v-model="selectedMember"
          outlined
          clearable
          label="Member"
          placeholder="Member"
          menu-props="auto"
          hide-details="auto"
          return-object
        ></v-autocomplete>
      </v-col>
      <v-col md="2">
        <v-btn color="success" @click="onNewItem" :disabled="!hasSelectedMember"
          >Add to Ministry</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-show="showEmptyView" elevation="0">
          <v-row>
            <v-col md="4">
              It seems that you have not added any members yet. Add your first
              one by selecting one from the list below.
            </v-col>
            <v-col md="3">
              <lottie-player
                src="https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json"
                background="transparent"
                speed="1"
                style="width: 400px; height: 400px"
                loop
                autoplay
              ></lottie-player>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-show="showGrid">
          <v-card-title>
            <v-row>
              <v-col md="4"> Membership </v-col>
              <v-spacer></v-spacer>
              <v-col md="3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  clearable
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :items-per-page="10"
            :headers="headers"
            :items="items"
            :search="search"
            :single-select="false"
            item-key="id"
            :show-select="showMultiSelect"
            v-model="selectedItems"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-row>
                <v-col>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="error"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="onDeleteItem(item)"
                      >
                        <v-icon> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span>Click here to remove</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <confirmation-dialog
      :dialog="showDeleteDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { LoadingType } from "@/models/common.model";
import { MemberSearchSuggestion } from "@/models/group.model";
import { DialogResponse } from "@/models/common.model";
import { IUserListItem } from "@/models/security.model";
import { GroupService } from "@/services/group.service";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ListLocalGroupMembers",
  mixins: [CommonMixin],
  data() {
    return {
      showDeleteDialog: false,
      search: "",
      headers: [
        { text: "Actions", value: "actions", sortable: false, width: 80 },
        { text: "Name", value: "knownAs" },
        { text: "Age", value: "age" },
        { text: "Gender", value: "gender" },
        { text: "Email", value: "email", sortable: false },
        { text: "Contact No", value: "primaryContactNumber", sortable: false },
      ],
      items: Array<IUserListItem>(),
      selectedItems: Array<IUserListItem>(),
      showMultiSelect: false,
      actionWidth: 4,
      showGrid: false,
      showEmptyView: false,
      ministryId: 0,
      isMatching: false,
      selectedMember: new MemberSearchSuggestion(),
      members: new Array<MemberSearchSuggestion>(),
      matching: null,
      hasSelectedMember: false,
    };
  },
  watch: {
    selectedMember(val: MemberSearchSuggestion) {
      this.hasSelectedMember = val && val.id !== 0 ? true : false;
    },
    matching(val: string) {
      if (this.isMatching) {
        return;
      }

      if (val) {
        this.isMatching = true;
        const service = new GroupService();
        service
          .getMemberSuggestion(this.ministryId, val)
          .then((response) => {
            this.hideProgressIndicator();
            this.members = response.data;
          })
          .catch((error) => {
            this.showErrorDialog(error);
          })
          .finally(() => (this.isMatching = false));
      } else {
        this.members = [];
      }
    },
  },
  methods: {
    onNewItem() {
      if (this.selectedMember) {
        const match = this.items.find((f) => f.id == this.selectedMember.id);
        if (match) {
          this.showConfirmationDialog(
            "Add Member",
            `${this.selectedMember.name} is already a member of this group.`
          );
        } else {
          this.showProgressIndicator(
            LoadingType.Panel,
            "Adding user, Please Wait..."
          );
          const service = new GroupService();
          service
            .addLocalGroupMember(this.ministryId, this.selectedMember.id)
            .then((response) => {
              this.hideProgressIndicator();
              let item = response.data;
              if (!item.imageUrl) {
                item.imageUrl = require("@/assets/images/COG7-logo.png");
              }
              item.age = this.ageInYears(item.dateOfBirth);
              this.items.push(item);
              this.showGrid = this.items.length > 0;
              this.showEmptyView = this.items.length == 0;
              this.selectedMember = new MemberSearchSuggestion();
            })
            .catch((error) => this.showErrorDialog(error));
        }
      }
    },

    onDeleteItem(item: IUserListItem) {
      this.showDeleteConfirmationDialog(item);
    },

    getItems(id: number) {
      this.ministryId = id;
      this.showGrid = true;
      this.showEmptyView = false;

      this.showProgressIndicator(
        LoadingType.Panel,
        `Getting Users, Please Wait...`
      );

      const service = new GroupService();
      service
        .getGroupMembers(this.ministryId, 0, null)
        .then((response) => {
          this.hideProgressIndicator();
          for (let item of response.data) {
            if (!item.imageUrl) {
              item.imageUrl = require("@/assets/images/COG7-logo.png");
            }
            item.age = this.ageInYears(item.dateOfBirth);
          }
          this.items = response.data;
          this.actionWidth = this.showMultiSelect ? 5 : 4;
          this.showGrid = response.data.length > 0;
          this.showEmptyView = response.data.length == 0;
          this.selectedMember = new MemberSearchSuggestion();
        })
        .catch((error) => this.showErrorDialog(error));
    },

    showDeleteConfirmationDialog(item: IUserListItem) {
      this.setNewDialogMessage(
        `Remove ${item.knownAs} from this group?`,
        `Are you sure you want to remove ${item.knownAs} from this group? This member will removed from the records and will no longer be available.`,
        true,
        item,
        []
      );
      this.showDeleteDialog = true;
    },

    onDeleteOptionSelected(item: DialogResponse) {
      this.showDeleteDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing member, Please Wait..."
        );
        const service = new GroupService();
        service
          .removeLocalGroupMember(this.ministryId, item.entity.id)
          .then(() => {
            this.hideProgressIndicator();
            let index = this.items.findIndex((f) => f.id == item.entity.id);
            if (index !== -1) {
              this.items.splice(index, 1);
            }
            this.showGrid = this.items.length > 0;
            this.showEmptyView = this.items.length == 0;
            this.selectedMember = new MemberSearchSuggestion();
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },
  },
});
</script>
<style scoped>
img {
  border: 1px solid rgb(177, 172, 172);
  border-radius: 4px;
  padding: 5px;
  object-fit: cover;
  max-height: 64px;
  max-width: 64px;
  margin-left: 4px;
  margin-bottom: 4px;
}

.img-container {
  width: 64px;
  height: 64px;
  border: 1px solid rgb(124, 120, 120);
}
</style>