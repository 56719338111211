import { render, staticRenderFns } from "./editor-conference-group.vue?vue&type=template&id=3dc7d24a&scoped=true"
import script from "./editor-conference-group.vue?vue&type=script&lang=ts"
export * from "./editor-conference-group.vue?vue&type=script&lang=ts"
import style0 from "@/assets/css/wg.css?vue&type=style&index=0&id=3dc7d24a&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dc7d24a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCol,VContainer,VForm,VIcon,VRow,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3dc7d24a')) {
      api.createRecord('3dc7d24a', component.options)
    } else {
      api.reload('3dc7d24a', component.options)
    }
    module.hot.accept("./editor-conference-group.vue?vue&type=template&id=3dc7d24a&scoped=true", function () {
      api.rerender('3dc7d24a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/editors/editor-conference-group.vue"
export default component.exports