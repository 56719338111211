<template>
  <div
    class="e-panel"
    :id="id"
    :data-row="row"
    :data-col="col"
    :data-sizeX="colSpan"
    :data-sizeY="rowSpan"
  >
    <div class="e-panel-container">
      <div class="panel-container">
        <div class="panel-row">
          <span>header</span>
        </div>
        <div class="panel-row panel-body">
          <ejs-accumulationchart
            :id="chartId"
            :legendSettings="legendSettings"
            :tooltip="tooltip"
            :enableSmartLabels="enableSmartLabels"
          >
            <e-accumulation-series-collection>
              <e-accumulation-series
                xName="x"
                yName="y"
                :startAngle="0"
                :endAngle="360"
                :dataSource="pieData"
                :dataLabel="dataLabel"
              ></e-accumulation-series>
            </e-accumulation-series-collection>
          </ejs-accumulationchart>
        </div>
        <div class="panel-row">
          <span>Footer</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import {
  AccumulationChartPlugin,
  PieSeries,
  AccumulationDataLabel,
  ChartPlugin,
  ColumnSeries,
  Category,
  Legend,
  Tooltip,
} from "@syncfusion/ej2-vue-charts";
import { PieChartDataItem } from "@/models/demographics.model";

Vue.use(ChartPlugin);
Vue.use(AccumulationChartPlugin);

export default Vue.extend({
  name: "DashboardPanel",
  props: {
    row: Number,
    col: Number,
    colSpan: Number,
    rowSpan: Number,
    id: String,
    title: String,
  },
  provide: {
    chart: [ColumnSeries, Category, Legend, Tooltip],
    accumulationchart: [PieSeries, AccumulationDataLabel, Legend, Tooltip],
  },
  data() {
    return {
      pieData: Array<PieChartDataItem>(),
      legendSettings: {
        visible: true,
      },
      tooltip: { enable: true },
      enableSmartLabels: true,
      dataLabel: { visible: true, name: "text", position: "Outside" },
      chartId: `chart-${this.id}`,
    };
  },
  created() {
    this.pieData = [];
    for (let i = 0; i < 10; i++) {
      let item = new PieChartDataItem();
      item.x = "amount";
      item.y = i;
      item.text = `item-${i}`;
      item.groupName = "group";
      this.pieData.push(item);
    }
  },
});
</script>

<style scoped>
.panel-container {
  height: 100%;

  background-color: lightblue;

  display: flex;
  flex-direction: column;
}

.panel-row {
  display: flex;
}

.panel-body {
  flex-grow: 1;
  background-color: red;
  align-items: center;
  justify-content: center;
  min-height: 120px;
  overflow: hidden;
}

@media (max-width: 600px) {
  .panel-body {
    background-color: #0ec161;
  }
}

/*
#defaultLayout .e-panel .e-panel-container {
  vertical-align: middle;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.panel-content {
  line-height: 80px;
}

#defaultLayout .e-panel {
  transition: none !important;
}
  */
</style>