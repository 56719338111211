<template>
  <v-app>
    <navbar
      @nav-button-clicked="onNavButtonClick"
      v-if="user.authenticated"
    ></navbar>
    <flat-side-menu
      v-if="user.authenticated"
      @item-selected="onItemSelected"
      :selectedMenuItem="selectedMenuItem"
      :drawerSelected="drawer"
      :items="items"
    ></flat-side-menu>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import FlatSideMenu from "./components/common/flat-side-menu.vue";
import Navbar from "./components/common/navbar.vue";
import { mapGetters } from "vuex";
import { IMenuItem, MenuItem } from "./models/common.model";
import { PermissionsManager } from "./models/security.model";
import { IUser } from "./models/security.model";
import { environment } from "./environment";
import store from "./store";

export default Vue.extend({
  name: "App",
  components: {
    Navbar,
    FlatSideMenu,
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  watch: {
    user: {
      deep: true,
      handler(val: IUser) {
        if (val.authenticated) {
          this.buildMenuOptions();
        }
      },
    },
  },
  data() {
    return {
      drawer: true,
      selectedMenuItem: 0,
      items: Array<MenuItem>(),
    };
  },
  methods: {
    onItemSelected(item: IMenuItem) {
      store.commit("setTitle", item.title);
      if (item.route && this.$router.currentRoute.name !== item.route) {
        this.$router.replace({ name: item.route });
      }
    },
    onNavButtonClick() {
      this.drawer = !this.drawer;
    },
    buildMenuOptions() {
      const isSuperAdmin: boolean = PermissionsManager.isSuperAdministrator();
      let menuOptions: IMenuItem[] = [];

      if (!environment.production && !isSuperAdmin) {
        menuOptions.push({
          id: 1,
          icon: "mdi-home-outline",
          title: "Home",
          route: "landing",
          enabled: true,
        });
        menuOptions.push({
          id: 3,
          title: "Branches",
          icon: "mdi-church",
          route: "host-groups",
          enabled: true,
        });
      } else {
        menuOptions.push({
          id: 1,
          icon: "mdi-home-outline",
          title: "Home",
          route: "landing",
          enabled: true,
        });
        /*
        menuOptions.push({
          id: 2,
          icon: "mdi-home-outline",
          title: "Dashboard",
          route: "host-dashboard",
          enabled: true,
        });
        */
        /*
        menuOptions.push({
          id: 2,
          title: "Calendar",
          icon: "mdi-calendar-clock",
          route: "list-events",
          enabled: true,
        });
        */
        menuOptions.push({
          id: 3,
          title: "Branches",
          icon: "mdi-church",
          route: "host-groups",
          enabled: true,
        });
        menuOptions.push({
          id: 4,
          title: "National Ministries",
          icon: "mdi-charity",
          route: "host-national-ministries",
          enabled: true,
        });
        /*
        menuOptions.push({
          id: 5,
          title: "Reports",
          icon: "mdi-chart-bar-stacked",
          route: "",
          enabled: true,
        });
        */
        menuOptions.push({
          id: 6,
          title: "Communication",
          icon: "mdi-bullhorn-outline",
          route: "host-communications",
          enabled: isSuperAdmin,
        });

        if (isSuperAdmin) {
          menuOptions.push({
            id: 8,
            title: "Mobile App Builder",
            icon: "mdi-camera",
            route: "host-mobile-app-builder",
            enabled: true,
          });
        }
      }

      this.items = menuOptions;
    },
  },
});
</script>

<style>
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-richtexteditor/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-layouts/styles/material.css";
</style>
