<template>
  <v-container fluid>
    <v-dialog v-model="showSenderDialog" :max-width="maxWidth" persistent eager>
      <v-form ref="manageForm" v-model="valid" @submit.prevent="submitForm">
        <v-card>
          <v-toolbar flat dense elevation="1" color="#E5E3E3">
            <div>New Message</div>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="onCancel()">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <div style="height: 20px"></div>
            <v-row>
              <v-col md="8">
                <v-text-field
                  v-model="viewModel.subject"
                  label="Subject"
                  placeholder="Subject"
                  clearable
                  :rules="validateSubject"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="8">
                <extended-autocomplete
                  :items="allRecipients"
                  :selected.sync="selectedRecipients"
                  :input="searchRecipientsInput"
                  label="Contactable Recipients"
                  placeholder="Only recipients who permit contact will be listed"
                >
                </extended-autocomplete>
              </v-col>
              <v-col md="3">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-progress-circular
                      :rotate="-90"
                      :size="100"
                      :width="15"
                      :value="reach"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ reach }} %
                    </v-progress-circular>
                  </template>
                  <span
                    >This is the percentage of your audience that will be
                    reached.<br />This number may not reach 100% if some have
                    opted out of being contacted<br />
                    or have not provided contact information.</span
                  >
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <template>
                  <v-textarea
                    label="Message"
                    placeholder="Enter your message here."
                    counter="4000"
                    outlined
                    clearable
                    v-model="viewModel.message"
                    rows="8"
                    :rules="[rules.required]"
                  ></v-textarea>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="8">
                <v-file-input
                  show-size
                  counter
                  multiple
                  truncate-length="5"
                  label="Attachments"
                  placeholder="Add file attachments here"
                  v-model="attachments"
                  :rules="[rules.attachmentSize]"
                  accept=".txt,.mp4,.mp3,.gif,.png,.jpg,.jpeg,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                >
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-switch
                  v-model="viewModel.email"
                  label="Send by email"
                  hide-details="auto"
                  :rules="validateSendByEmail"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-switch
                  v-model="viewModel.sms"
                  label="Send by text"
                  hide-details="auto"
                ></v-switch>
              </v-col>
              <v-col md="6" v-show="viewModel.sms === true && incursCost">
                <b>Texts are charged. Please check the cost before sending.</b>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-switch
                  v-model="viewModel.app"
                  label="Send by mobile app"
                  hide-details="auto"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="onCancel"> Cancel </v-btn>
            <v-btn
              color="primary"
              :disabled="!incursCost"
              @click="calculateCost"
            >
              Get Cost
            </v-btn>
            <v-btn color="primary" type="submit" :disabled="!valid">
              Send Message
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { ISendMessageViewModel, LoadingType } from "@/models/common.model";
import { IUserListItem } from "@/models/security.model";
import ExtendedAutoComplete from "@/components/common/extended-autocomplete.vue";
import { MediaService } from "@/services/media.service";
import { SendGroupMembersMessageCommand } from "@/models/group.model";
import { GroupService } from "@/services/group.service";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "MessageSenderDialog",
  mixins: [CommonMixin],
  components: {
    "extended-autocomplete": ExtendedAutoComplete,
  },
  props: {
    groupId: Number,
    dialog: Boolean,
    data: Object,
  },
  watch: {
    dialog(val: boolean) {
      this.showSenderDialog = val;
      const ref: any = this.$refs.manageForm;
      if (ref) {
        ref.validate();
      }
    },
    data(val: ISendMessageViewModel) {
      this.viewModel = val;
      this.allRecipients = [];
      this.selectedRecipients = [];
      val.recipients.forEach((a) => {
        if (a.contactable) {
          this.allRecipients.push(a);
        }
      });
    },
    selectedRecipients: {
      deep: true,
      handler(val: IUserListItem[]) {
        this.calculateReach();
        this.willIncurCost();
        this.searchRecipientsInput = "";
      },
    },
    viewModel: {
      deep: true,
      handler(val: ISendMessageViewModel) {
        this.calculateReach();
        this.willIncurCost();
      },
    },
  },
  computed: {
    validateSubject(): string[] {
      let err: string[] = [];
      if (
        !this.viewModel.subject ||
        (this.viewModel.subject && this.viewModel.subject.length === 0)
      ) {
        err.push("You must enter a subject for the message");
      }
      return err;
    },
    validateSendByEmail(): string[] {
      let err: string[] = [];
      if (
        this.viewModel.email === false &&
        this.viewModel.sms === false &&
        this.viewModel.app === false
      ) {
        err.push("You must select at least one means of notification.");
      }
      return err;
    },
  },
  data() {
    return {
      valid: true,
      showSenderDialog: false,
      maxWidth: "40%",
      viewModel: {} as ISendMessageViewModel,
      allRecipients: Array<IUserListItem>(),
      selectedRecipients: Array<IUserListItem>(),
      searchRecipientsInput: "",
      reach: 0,
      attachments: Array<File>(),
      incursCost: false,
    };
  },
  methods: {
    calculateReach() {
      let result = 0;
      if (
        this.viewModel.recipients.length > 0 &&
        this.selectedRecipients.length > 0
      ) {
        let eligible: IUserListItem[] = [];
        for (let recipient of this.selectedRecipients) {
          if (
            this.viewModel.email === true &&
            recipient.isContactableByEmail === true
          ) {
            eligible.push(recipient);
          } else if (
            this.viewModel.sms === true &&
            recipient.isContactableBySms === true
          ) {
            eligible.push(recipient);
          } else if (
            this.viewModel.app === true &&
            recipient.isContactableByApp === true
          ) {
            eligible.push(recipient);
          }
        }

        result = Math.round(
          (eligible.length / this.viewModel.recipients.length) * 100
        );
      }

      this.reach = result;
    },

    willIncurCost() {
      let eligible: IUserListItem[] = [];
      for (let recipient of this.selectedRecipients) {
        if (
          this.viewModel.sms === true &&
          recipient.isContactableBySms === true
        ) {
          eligible.push(recipient);
        }
      }
      this.incursCost = eligible.length > 0;
    },

    calculateCost() {
      let eligible: IUserListItem[] = [];
      for (let recipient of this.selectedRecipients) {
        if (
          this.viewModel.sms === true &&
          recipient.isContactableBySms === true
        ) {
          eligible.push(recipient);
        }
      }
      if (eligible.length > 0) {
        let cost = eligible.length * 0.04;
        this.showConfirmationDialog(
          "Cost to send message",
          `It is estimated that it will cost approximately ${this.formatCurrency(cost)} to send this message.`
        );
      }
    },

    async submitForm() {
      var model = new SendGroupMembersMessageCommand();

      model.groupId = this.groupId;

      // State what platforms to send to
      model.app = this.viewModel.app;
      model.sms = this.viewModel.sms;
      model.email = this.viewModel.email;

      // Define the subject and message
      model.subject = this.viewModel.subject;
      model.message = this.viewModel.message;

      // Specify the recipients by their user id's
      this.selectedRecipients.forEach((r) => {
        model.recipients.push(r.id);
      });

      // State the attachments that should be referred to
      for (let fileAttachment of this.attachments) {
        if (fileAttachment.size > 0) {
          this.showProgressIndicator(
            LoadingType.Panel,
            `Compressing attachment ${fileAttachment.name}, Please Wait...`
          );

          try {
            const service = new MediaService();
            const result = await service.uploadFile(
              fileAttachment,
              fileAttachment.name
            );
            if (result.status === 200) {
              model.attachmentUrls.push(result.data);
            }
          } catch (error) {
            this.showErrorDialog(error);
            return;
          }
        }
      }

      this.showProgressIndicator(
        LoadingType.Panel,
        `Sending message, Please Wait...`
      );

      const service = new GroupService();
      service
        .sendMessage(this.groupId, model)
        .then((response) => {
          this.hideProgressIndicator();
          if (response.data === true) {
            this.showConfirmationDialog(
              "Send Message",
              "The message was sent."
            );
            this.$emit("dialog-closed");
          } else {
            this.showConfirmationDialog(
              "Send Message",
              "The message failed to send for an un-known reason. Please try again later."
            );
            this.$emit("dialog-closed");
          }
        })
        .catch((error) => this.showErrorDialog(error));
    },

    onCancel() {
      this.$emit("dialog-closed");
    },
  },
});
</script>

<style scoped src="../../assets/css/wg.css">
.v-progress-circular {
  margin: 1rem;
}
</style>