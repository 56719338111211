var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-sheet",
                    { attrs: { height: "64" } },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-1",
                              attrs: { outlined: "", color: "primary" },
                              on: { click: _vm.onNewEvent },
                            },
                            [_vm._v(" New Event ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isCalendarView,
                                  expression: "isCalendarView",
                                },
                              ],
                              staticClass: "mr-4",
                              attrs: { outlined: "", color: "grey darken-2" },
                              on: { click: _vm.onCalendarTodayButtonClicked },
                            },
                            [_vm._v(" Today ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                fab: "",
                                text: "",
                                small: "",
                                color: "grey darken-2",
                              },
                              on: { click: _vm.onCalendarPrevIntervalClicked },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(" mdi-chevron-left "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                fab: "",
                                text: "",
                                small: "",
                                color: "grey darken-2",
                              },
                              on: { click: _vm.onCalendarNextIntervalClicked },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(" mdi-chevron-right "),
                              ]),
                            ],
                            1
                          ),
                          _vm.$refs.calendar
                            ? _c("v-toolbar-title", [
                                _vm._v(
                                  " " + _vm._s(_vm.$refs.calendar.title) + " "
                                ),
                              ])
                            : _vm._e(),
                          _c("v-spacer"),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                bottom: "",
                                right: "",
                                "menu-props": "auto",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.isCalendarView,
                                                  expression: "isCalendarView",
                                                },
                                              ],
                                              attrs: {
                                                outlined: "",
                                                color: "grey darken-2",
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.interval)),
                                          ]),
                                          _c(
                                            "v-icon",
                                            { attrs: { right: "" } },
                                            [_vm._v(" mdi-menu-down ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.onCalendarIntervalChanged(
                                            "day"
                                          )
                                        },
                                      },
                                    },
                                    [_c("v-list-item-title", [_vm._v("Day")])],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.onCalendarIntervalChanged(
                                            "week"
                                          )
                                        },
                                      },
                                    },
                                    [_c("v-list-item-title", [_vm._v("Week")])],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.onCalendarIntervalChanged(
                                            "working-week"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Work Week"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.onCalendarIntervalChanged(
                                            "month"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Month"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticStyle: { width: "12px" } }),
                          _c(
                            "v-btn-toggle",
                            {
                              attrs: { color: "primary" },
                              on: { change: _vm.onCalendarViewTypeChange },
                              model: {
                                value: _vm.calendarViewType,
                                callback: function ($$v) {
                                  _vm.calendarViewType = $$v
                                },
                                expression: "calendarViewType",
                              },
                            },
                            [
                              _c("v-btn", [_vm._v("Calendar")]),
                              _c("v-btn", [_vm._v("Grid")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-sheet",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "auto" } },
                            [
                              _c("v-date-picker", {
                                attrs: {
                                  "no-title": "",
                                  elevation: "2",
                                  events: _vm.sideCalendarEvents,
                                  "event-color": "green lighten-1",
                                },
                                on: { input: _vm.onSideCalendarDateSelected },
                                model: {
                                  value: _vm.sideCalendarSelectedDate,
                                  callback: function ($$v) {
                                    _vm.sideCalendarSelectedDate = $$v
                                  },
                                  expression: "sideCalendarSelectedDate",
                                },
                              }),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "span",
                                            _vm._g(
                                              _vm._b({}, "span", attrs, false),
                                              on
                                            ),
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  label:
                                                    "Incl. National Calendar Events",
                                                },
                                                on: {
                                                  change:
                                                    _vm.onNationalCalendarCheckChanged,
                                                },
                                                model: {
                                                  value:
                                                    _vm.includeNationalCalendarEvents,
                                                  callback: function ($$v) {
                                                    _vm.includeNationalCalendarEvents =
                                                      $$v
                                                  },
                                                  expression:
                                                    "includeNationalCalendarEvents",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Check this box to include published events "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "from other branches and ministries"
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-calendar", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isCalendarView,
                                    expression: "isCalendarView",
                                  },
                                ],
                                ref: "calendar",
                                attrs: {
                                  color: "primary",
                                  type: _vm.type,
                                  events: _vm.events,
                                  "event-ripple": true,
                                  weekdays: _vm.weekdays,
                                  "event-more": false,
                                },
                                on: {
                                  change: _vm.onDateChange,
                                  "click:event": _vm.onEventClicked,
                                  "click:more": _vm.onCalendarDayClicked,
                                  "click:date": _vm.onCalendarDayClicked,
                                  "click:time": _vm.onCalendarTimeClicked,
                                  "click:day": _vm.onCalendarDayClicked,
                                  "mouseenter:event": _vm.onEventMouseEnter,
                                  "mousedown:event": _vm.onEventStartDrag,
                                  "mousedown:time": _vm.onCalendarMouseDown,
                                  "mousemove:time": _vm.onCalendarMouseMove,
                                  "mouseup:time": _vm.onEventEndDrag,
                                },
                                nativeOn: {
                                  mouseleave: function ($event) {
                                    return _vm.onEventCancelDrag.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "event",
                                    fn: function (ref) {
                                      var event = ref.event
                                      var timed = ref.timed
                                      var eventSummary = ref.eventSummary
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "v-event-draggable" },
                                          [
                                            _c(
                                              { render: eventSummary },
                                              { tag: "component" }
                                            ),
                                          ],
                                          1
                                        ),
                                        timed
                                          ? _c("div", {
                                              staticClass:
                                                "v-event-drag-bottom",
                                              on: {
                                                mousedown: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.onExtendEventDate(
                                                    event
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.focus,
                                  callback: function ($$v) {
                                    _vm.focus = $$v
                                  },
                                  expression: "focus",
                                },
                              }),
                              _c(
                                "v-card",
                                [
                                  _c("v-data-table", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.isCalendarView,
                                        expression: "!isCalendarView",
                                      },
                                    ],
                                    attrs: {
                                      headers: _vm.headers,
                                      items: _vm.eventData,
                                      "single-select": false,
                                      "items-per-page": 10,
                                      "show-select": false,
                                      "item-key": "id",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item.actions",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "error",
                                                                                icon: "",
                                                                                disabled:
                                                                                  _vm.disableEventToolBar(
                                                                                    item
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.onDeleteEventItem(
                                                                                    item
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-trash-can-outline"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Click here to remove the event"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "primary",
                                                                                icon: "",
                                                                                disabled:
                                                                                  _vm.disableEventToolBar(
                                                                                    item
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.onEditEventItem(
                                                                                    item
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-file-document-edit-outline "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Click here to edit the event"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "primary",
                                                                                icon: "",
                                                                                disabled:
                                                                                  _vm.disableEventToolBar(
                                                                                    item
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.onNotifyEventItem(
                                                                                    item
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-bell-ring "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Click here to notify others of the event"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    !item.isPublished,
                                                                                  expression:
                                                                                    "!item.isPublished",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "primary",
                                                                                icon: "",
                                                                                disabled:
                                                                                  _vm.disableEventToolBar(
                                                                                    item
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.onPublishEventItem(
                                                                                    item
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-publish "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Click here to publish the event"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    item.isPublished,
                                                                                  expression:
                                                                                    "item.isPublished",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "primary",
                                                                                icon: "",
                                                                                disabled:
                                                                                  _vm.disableEventToolBar(
                                                                                    item
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.onUnPublishEventItem(
                                                                                    item
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-publish-off "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Click here to un-publish the event"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    !item.isCancelled,
                                                                                  expression:
                                                                                    "!item.isCancelled",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "primary",
                                                                                icon: "",
                                                                                disabled:
                                                                                  _vm.disableEventToolBar(
                                                                                    item
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.onCancelEventItem(
                                                                                    item
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-calendar-remove "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Click here to cancel the event"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    item.isCancelled,
                                                                                  expression:
                                                                                    "item.isCancelled",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "primary",
                                                                                icon: "",
                                                                                disabled:
                                                                                  _vm.disableEventToolBar(
                                                                                    item
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.onUnCancelEventItem(
                                                                                    item
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-calendar "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Click here to un-cancel the event"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.startDateTime",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formatDateTime(
                                                      item.startDateTime
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.endDateTime",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formatDateTime(
                                                      item.endDateTime
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.isCancelled",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _c("v-simple-checkbox", {
                                                attrs: { disabled: "" },
                                                model: {
                                                  value: item.isCancelled,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "isCancelled",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.isCancelled",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.isPublished",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _c("v-simple-checkbox", {
                                                attrs: { disabled: "" },
                                                model: {
                                                  value: item.isPublished,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "isPublished",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.isPublished",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.isOnNationalCalendar",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _c("v-simple-checkbox", {
                                                attrs: { disabled: "" },
                                                model: {
                                                  value:
                                                    item.isOnNationalCalendar,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "isOnNationalCalendar",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.isOnNationalCalendar",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.isHeldInPhysicalLocation",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _c("v-simple-checkbox", {
                                                attrs: { disabled: "" },
                                                model: {
                                                  value:
                                                    item.isHeldInPhysicalLocation,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "isHeldInPhysicalLocation",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.isHeldInPhysicalLocation",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.isHeldOnline",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _c("v-simple-checkbox", {
                                                attrs: { disabled: "" },
                                                model: {
                                                  value: item.isHeldOnline,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "isHeldOnline",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.isHeldOnline",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.address",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.getAddress(item)) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("event-info-popup", {
                        attrs: {
                          selectedEventInfoDialog: _vm.showInfoPopup,
                          nudgeLeft: _vm.nudgeLeft,
                          selectedElement: _vm.selectedElement,
                          selectedEvent: _vm.selectedEvent,
                          maxEventInfoDialogWidth: _vm.maxEventInfoDialogWidth,
                          disableToolbar: _vm.disableInfoPopupToolbar,
                        },
                        on: {
                          showEditEventScreen: _vm.showFullEditScreen,
                          deleteEvent: _vm.deleteEvent,
                          closeEventInfoDialog: _vm.closeEventInfoDialog,
                          cancelEvent: _vm.cancelEvent,
                          unCancelEvent: _vm.unCancelEvent,
                          publishEvent: _vm.publishEvent,
                          unPublishEvent: _vm.unPublishEvent,
                          notifyEvent: _vm.notifyEvent,
                        },
                      }),
                      _c("event-info-edit-popup", {
                        attrs: {
                          selectedEventInfoEditDialog: _vm.showEditPopup,
                          nudgeLeft: _vm.nudgeLeft,
                          selectedNewElement: _vm.selectedNewElement,
                          model: _vm.newEventItem,
                          maxEventInfoDialogWidth: 440,
                        },
                        on: {
                          "save-event": _vm.onEventSave,
                          "more-options": _vm.onMoreOptions,
                          "close-dialog": _vm.closeEventInfoEditDialog,
                        },
                      }),
                      _c("notification-sender-dialog", {
                        attrs: {
                          dialog: _vm.showNotificationDialog,
                          message: _vm.notificationMessage,
                          title: _vm.notificationTitle,
                          category: _vm.notificationCategory,
                          entityId: _vm.notificationEventId,
                        },
                        on: { "dialog-closed": _vm.onNotificationDialogClosed },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDeleteDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDeleteOptionSelected },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("confirmation-dialog", {
        attrs: {
          dialog: _vm.showCancelDialog,
          data: _vm.dialogMessage,
          errors: _vm.dialogErrors,
        },
        on: { "dialog-option-selected": _vm.onCancelDialogSelected },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }