<template>
    <h1>Funky Reports</h1>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";


export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
    name: "ListManagerReports",
    mixins: [CommonMixin],
})
</script>
