<template>
  <v-container fluid>
    <v-form ref="manageForm" v-model="valid">
      <v-row>
        <v-col lg="3" xl="2">
          <v-file-input
            show-size
            counter
            multiple
            truncate-length="5"
            label="Images"
            placeholder="Add images here"
            v-model="selectedFilesForUpload"
            :rules="[rules.attachmentSize]"
            accept=".png,.jpg"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip
                v-if="index < 2"
                color="primary accent-4"
                dark
                label
                small
              >
                {{ text }}
              </v-chip>

              <span
                v-else-if="index === 2"
                class="text-overline grey--text text--darken-3 mx-2"
              >
                +{{ selectedFilesForUpload.length - 2 }} File(s)
              </span>
            </template>
          </v-file-input>
        </v-col>
        <v-col>
          <v-btn
            class="ma-2"
            color="success"
            @click="onAddImages"
            :disabled="!canUpload"
            >Add Images</v-btn
          >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2"
                icon
                outlined
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="onRemoveMultipleItems"
                :disabled="!hasSelectedImages"
                ><v-icon>mdi-delete-sweep-outline</v-icon></v-btn
              >
            </template>
            <span>Remove Selected</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2"
                icon
                outlined
                v-bind="attrs"
                v-on="on"
                color="primary"
                @click="onRemoveAllItems"
                :disabled="!hasImages"
                ><v-icon>mdi-delete-empty-outline</v-icon></v-btn
              >
            </template>
            <span>Remove All</span>
          </v-tooltip>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-col>
          <v-card v-show="showEmptyView" elevation="0">
            <v-row>
              <v-col md="4">
                It seems that you do not have any images in this gallery. Add
                some by clicking the Add Images button.
              </v-col>
              <v-col md="3">
                <lottie-player
                  src="https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json"
                  background="transparent"
                  speed="1"
                  style="width: 400px; height: 400px"
                  loop
                  autoplay
                ></lottie-player>
              </v-col>
            </v-row>
          </v-card>
          <v-data-iterator
            v-show="showGrid"
            item-key="id"
            :items="images"
            :items-per-page.sync="itemsPerPage"
            :footer-props="footerOptions"
            :options.sync="options"
            :server-items-length="totalItems"
          >
            <template v-slot:default="props">
              <v-row>
                <v-col md="auto" v-for="item in props.items" :key="item.id">
                  <v-hover v-slot="{ hover }" open-delay="100">
                    <v-card
                      :elevation="!multiSelect && hover ? 12 : 3"
                      :outlined="!multiSelect && hover"
                      :class="{ 'on-hover': hover }"
                    >
                      <v-img
                        :src="item.imageUrl"
                        :lazy-src="placeholder"
                        :class="[!multiSelect ? imageCss : '']"
                        @click="!multiSelect"
                        aspect-ratio="1"
                      >
                      </v-img>

                      <v-card-actions>
                        <v-checkbox
                          v-show="multiSelect"
                          v-model="item.selected"
                        ></v-checkbox>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click.stop="onRemoveSingleItem(item)"
                        >
                          Remove
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-col>
      </v-row>
    </v-form>
    <confirmation-dialog
      :dialog="showDeleteSingleItemDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteSingleItemSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDeleteMultipleItemsDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteMultipleItemsSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDeleteAllItemsDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteAllItemsSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { IImageListItem } from "@/models/events.model";
import { DataOptions } from "vuetify";
import {
  DialogMessage,
  DialogResponse,
  LoadingType,
} from "@/models/common.model";
import { GroupService } from "@/services/group.service";
import { MediaService } from "@/services/media.service";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "GalleriesImageSection",
  mixins: [CommonMixin],
  props: {
    groupId: {
      type: Number,
      required: true,
    },
    galleryId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
  computed: {
    hasImages(): boolean {
      return this.images.length > 0;
    },
    hasSelectedImages(): boolean {
      const items = this.images.filter((f) => f.selected);
      return items.length > 0;
    },
    canUpload(): boolean {
      return this.valid && this.selectedFilesForUpload.length > 0;
    },
  },
  data() {
    return {
      showDeleteSingleItemDialog: false,
      showDeleteMultipleItemsDialog: false,
      showDeleteAllItemsDialog: false,
      images: new Array<IImageListItem>(),
      selectedFilesForUpload: Array<File>(),
      options: {} as DataOptions,
      totalItems: 0,
      itemsPerPage: 8,
      placeholder: require("@/assets/images/banner-slot.png"),
      footerOptions: {
        "items-per-page-options": [4, 8, 12, 16, 20, 24],
        "items-per-page-text": "images per page:",
        "show-current-page": true,
        "show-first-last-page": true,
      },
      imageCss: "imageCursor",
      valid: true,
      multiSelect: true,
      showGrid: false,
      showEmptyView: false,
    };
  },
  methods: {
    resetData() {
      this.images = [];
      this.totalItems = 0;
      this.showDeleteSingleItemDialog = false;
      this.showDeleteMultipleItemsDialog = false;
      this.showDeleteAllItemsDialog = false;
      this.resetSelectedFilesForUpload();
    },

    getItems() {
      this.resetData();
      const { page, itemsPerPage }: DataOptions = this.options;

      // If the search is being called from a dialog property watch
      // we may not have the data options. In this case take it from
      // the default values we have set up in the data section.
      let pageNumber = page ? page : 1;
      let perPage = itemsPerPage ? itemsPerPage : this.itemsPerPage;

      this.showGrid = true;
      this.showEmptyView = false;

      this.showProgressIndicator(
        LoadingType.Panel,
        `Getting Images, Please Wait...`
      );

      const service = new GroupService();
      service
        .getGalleryImages(this.groupId, this.galleryId, pageNumber, perPage)
        .then((response) => {
          this.hideProgressIndicator();
          this.images = response.data.items;
          this.totalItems = response.data.totalCount;

          this.showGrid = response.data.items.length > 0;
          this.showEmptyView = response.data.items.length == 0;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    async onAddImages() {
      if (this.selectedFilesForUpload.length > 0) {
        let imageUrls: string[] = [];
        for (let fileAttachment of this.selectedFilesForUpload) {
          if (fileAttachment.size > 0) {
            this.showProgressIndicator(
              LoadingType.Panel,
              `Uploading image ${fileAttachment.name}, Please Wait...`
            );

            try {
              const service = new MediaService();
              const result = await service.uploadFile(
                fileAttachment,
                fileAttachment.name
              );
              if (result.status === 200) {
                imageUrls.push(result.data);
              }
            } catch (error) {
              this.showErrorDialog(error);
              return;
            }
          }
        }

        this.resetSelectedFilesForUpload();

        if (imageUrls.length > 0) {
          this.showProgressIndicator(
            LoadingType.Panel,
            `Updating gallery, Please Wait...`
          );

          const service = new GroupService();
          service
            .uploadGalleryImages(this.groupId, this.galleryId, imageUrls)
            .then((response) => {
              this.hideProgressIndicator();
              if (response.data === true) {
                this.getItems();
              }
            })
            .catch((error) => this.showErrorDialog(error));
        }
      }
    },

    onRemoveSingleItem(item: IImageListItem) {
      this.showDeleteSingleItemConfirmationDialog(item);
    },

    showDeleteSingleItemConfirmationDialog(item: IImageListItem) {
      this.dialogMessage = new DialogMessage(
        `Delete image?`,
        `Are you sure you want to delete this image? This image will be removed from the gallery and will no longer be available.`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = item;
      this.dialogMessage.errors = [];
      this.showDeleteSingleItemDialog = true;
    },

    onDeleteSingleItemSelected(item: DialogResponse) {
      this.showDeleteSingleItemDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing image, Please Wait..."
        );
        const service = new GroupService();
        service
          .removeGalleryImage(this.groupId, this.galleryId, item.entity.id)
          .then(() => {
            this.hideProgressIndicator();
            let index = this.images.findIndex((f) => f.id == item.entity.id);
            if (index !== -1) {
              this.images.splice(index, 1);
            }
            this.showGrid = this.images.length > 0;
            this.showEmptyView = this.images.length == 0;
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    onRemoveMultipleItems() {
      this.showDeleteMultipleItemsConfirmationDialog();
    },

    showDeleteMultipleItemsConfirmationDialog() {
      let selectedImages = this.images
        .filter((f) => f.selected)
        .map((m) => m.id);
      this.dialogMessage = new DialogMessage(
        `Delete ${selectedImages.length} images?`,
        `Are you sure you want to delete the ${selectedImages.length} selected images? These images will be removed from the gallery and will no longer be available.`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = selectedImages;
      this.dialogMessage.errors = [];
      this.showDeleteMultipleItemsDialog = true;
    },

    onDeleteMultipleItemsSelected(item: DialogResponse) {
      this.showDeleteMultipleItemsDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          `Removing ${item.entity.length} selected images, Please Wait...`
        );
        const service = new GroupService();
        service
          .removeSelectedGalleryImages(this.groupId, this.galleryId, item.entity)
          .then(() => {
            this.hideProgressIndicator();
            this.getItems();
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    onRemoveAllItems() {
      this.showDeleteAllItemsConfirmationDialog();
    },

    showDeleteAllItemsConfirmationDialog() {
      this.dialogMessage = new DialogMessage(
        `Delete ALL images?`,
        `Are you sure you want to delete all the images in this gallery? These images will be removed from the gallery and will no longer be available.`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = this.galleryId;
      this.dialogMessage.errors = [];
      this.showDeleteAllItemsDialog = true;
    },

    onDeleteAllItemsSelected(item: DialogResponse) {
      this.showDeleteAllItemsDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing all images, Please Wait..."
        );
        const service = new GroupService();
        service
          .removeAllGalleryImages(this.groupId, item.entity)
          .then(() => {
            this.hideProgressIndicator();
            this.getItems();
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    resetSelectedFilesForUpload() {
      if (this.selectedFilesForUpload.length > 0) {
        for (let attachmentUrl of this.selectedFilesForUpload) {
          URL.revokeObjectURL(attachmentUrl.name);
        }
      }
      this.selectedFilesForUpload = [];
    },
  },
});
</script>