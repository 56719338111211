var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("list-groups", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showListView,
            expression: "showListView",
          },
        ],
        ref: "listgroups",
        attrs: { groupType: _vm.groupType },
        on: {
          "new-group-requested": _vm.onNewGroup,
          "show-group": _vm.onShowGroup,
        },
      }),
      _c("edit-conference-group", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showManageView,
            expression: "showManageView",
          },
        ],
        ref: "managegroup",
        attrs: { groupType: _vm.groupType },
        on: {
          "show-alert-panel": _vm.showSuccessPanel,
          "manage-group-cancel": _vm.onManageGroupCancel,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }