var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        {
          ref: "manageForm",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitForm.apply(null, arguments)
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c(
                  "section",
                  { staticClass: "page-header" },
                  [
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { md: "2" } }, [
                          _c("h5", { staticClass: "wg-title" }, [
                            _vm._v("Conference Manager"),
                          ]),
                          _c(
                            "p",
                            {
                              staticClass: "small",
                              staticStyle: { "margin-bottom": "0" },
                            },
                            [
                              _vm._v(
                                " Manage your top-level conference settings here "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { md: "5" } },
                [
                  _c(
                    "v-tabs",
                    {
                      on: { change: _vm.tabChanged },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _c("v-tab", [_vm._v("General")]),
                      _c("v-tab", [_vm._v("Branches")]),
                      _c("v-tab", [_vm._v("Ministries")]),
                      _c("v-tab", [_vm._v("Valid Options")]),
                      _c("v-tab", [_vm._v("Security")]),
                      _c("v-tab", [_vm._v("Users")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _c(
                        "v-tab-item",
                        { attrs: { transition: "false", eager: "" } },
                        [
                          _c("general-section", {
                            ref: "generalsection",
                            on: { "show-alert-panel": _vm.showSuccessPanel },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { transition: "false", eager: "" } },
                        [
                          _c("list-conference-groups", {
                            ref: "listbranches",
                            attrs: { groupType: _vm.branchGroupType },
                            on: { "show-alert-panel": _vm.showSuccessPanel },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { transition: "false", eager: "" } },
                        [
                          _c("list-conference-groups", {
                            ref: "listministries",
                            attrs: { groupType: _vm.ministryGroupType },
                            on: { "show-alert-panel": _vm.showSuccessPanel },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { transition: "false", eager: "" } },
                        [
                          _c("list-valid-options", {
                            ref: "listvalidoptions",
                            on: { "show-alert-panel": _vm.showSuccessPanel },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { transition: "false", eager: "" } },
                        [
                          _c("security-manager", {
                            ref: "securitymanager",
                            on: { "show-alert-panel": _vm.showSuccessPanel },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { transition: "false", eager: "" } },
                        [
                          _c("list-registered-users", {
                            ref: "listregisteredusers",
                            on: { "show-alert-panel": _vm.showSuccessPanel },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c("action-alert", {
                attrs: {
                  show: _vm.showAlert,
                  message: _vm.alertMessage,
                  type: _vm.alertType,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
      _c("confirmation-dialog", {
        attrs: {
          dialog: _vm.showDialog,
          data: _vm.dialogMessage,
          errors: _vm.dialogErrors,
        },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }