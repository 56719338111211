<template>
  <v-container fluid>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <v-dialog v-model="showSenderDialog" :max-width="maxWidth" persistent eager>
      <v-form ref="manageForm" v-model="valid" @submit.prevent="submitForm">
        <v-card>
          <v-toolbar flat dense elevation="1" color="#E5E3E3">
            <div>Message to {{ viewModel.title }}</div>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="onCancel()">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <div style="height: 20px" />
            <v-row>
              <v-col md="10"> Send a message to {{ description }} </v-col>
            </v-row>
            <div style="height: 10px" />
            <v-row>
              <v-col md="8">
                <v-text-field
                  v-model="notification.subject"
                  label="Subject"
                  placeholder="Subject"
                  clearable
                  :rules="validateSubject"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="8">
                <extended-autocomplete
                  :items="allRecipients"
                  :selected.sync="selectedRecipients"
                  :input="searchRecipientsInput"
                  label="Contactable Recipients"
                  placeholder="Only recipients who permit contact will be listed"
                >
                </extended-autocomplete>
              </v-col>
              <v-col md="3">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-progress-circular
                      :rotate="-90"
                      :size="100"
                      :width="15"
                      :value="reach"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ reach }} %
                    </v-progress-circular>
                  </template>
                  <span
                    >This is the percentage of your audience that will be
                    reached.<br />This number may not reach 100% if some have
                    opted out of being contacted<br />
                    or have not provided contact information.</span
                  >
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <template>
                  <v-textarea
                    label="Message"
                    placeholder="Enter your message here."
                    counter="4000"
                    outlined
                    clearable
                    v-model="notification.message"
                    rows="8"
                    :rules="[rules.required]"
                  ></v-textarea>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="8">
                <v-file-input
                  show-size
                  counter
                  multiple
                  truncate-length="5"
                  label="Attachments"
                  placeholder="Add file attachments here"
                  v-model="attachments"
                  :rules="[rules.attachmentSize]"
                  accept=".txt,.mp4,.mp3,.gif,.png,.jpg,.jpeg,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                >
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-switch
                  v-model="notification.email"
                  label="Send by email"
                  hide-details="auto"
                  :rules="validateSendByEmail"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-switch
                  v-model="notification.sms"
                  label="Send by text"
                  hide-details="auto"
                ></v-switch>
              </v-col>
              <v-col md="6" v-show="notification.sms === true && incursCost">
                <b>Texts are charged. Please check the cost before sending.</b>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-switch
                  v-model="notification.app"
                  label="Send by mobile app"
                  hide-details="auto"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="onCancel"> Cancel </v-btn>
            <v-btn
              color="primary"
              :disabled="!incursCost"
              @click="calculateCost"
            >
              Get Cost
            </v-btn>
            <v-btn color="primary" type="submit" :disabled="!valid">
              Send Message
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import {
  IDemographicsNotificationRecipient,
  IDemographicsQueryResultViewModel,
  IRunDemographicsQueryViewModel,
  NotificationMessage,
} from "@/models/demographics.model";
import {
  RichTextEditorPlugin,
  Toolbar,
  Link,
  Image,
  Count,
  HtmlEditor,
  QuickToolbar,
} from "@syncfusion/ej2-vue-richtexteditor";
import { DemographicsService } from "@/services/demographics.service";
import { MediaService } from "@/services/media.service";
import ExtendedAutoComplete from "@/components/common/extended-autocomplete.vue";
import { LoadingType } from "@/models/common.model";

Vue.use(RichTextEditorPlugin);

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "QueryResultMessageSenderDialog",
  mixins: [CommonMixin],
  components: {
    "extended-autocomplete": ExtendedAutoComplete,
  },
  provide: {
    richtexteditor: [Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar],
  },
  props: {
    dialog: Boolean,
    data: Object,
  },
  watch: {
    data(val: IRunDemographicsQueryViewModel) {
      this.viewModel = val;
      this.allRecipients = [];
      this.selectedRecipients = [];
      this.attachments = [];
      this.description = val.description
        .toLowerCase()
        .replaceAll("show me", "");

      val.attendees.forEach((a) => {
        const isNotifyable: boolean =
          a.gdprPermissionGranted === true &&
          (a.allowContactByEmail === true ||
            a.allowContactBySms === true ||
            a.allowContactByApp == true ||
            a.allowContactByPhone == true);
        if (isNotifyable) {
          this.allRecipients.push({
            id: a.id,
            name: `${a.firstName} ${a.lastName}`,
            isNotifyable: true,
          });
        }
      });
    },
    dialog(val: boolean) {
      this.showSenderDialog = val;
      if (val === true) {
        if (this.notification.attachmentUrls.length > 0) {
          for (let attachmentUrl of this.notification.attachmentUrls) {
            URL.revokeObjectURL(attachmentUrl);
          }
        }
        this.notification = new NotificationMessage();
        const ref: any = this.$refs.manageForm;
        if (ref) {
          ref.validate();
        }
      }
    },
    notification: {
      deep: true,
      handler(val: NotificationMessage) {
        this.calculateReach();
        this.willIncurCost();
      },
    },
    selectedRecipients: {
      deep: true,
      handler(val: IDemographicsNotificationRecipient[]) {
        this.calculateReach();
        this.willIncurCost();
        this.searchRecipientsInput = "";
      },
    },
  },
  computed: {
    validateSubject(): string[] {
      let err: string[] = [];
      if (
        !this.notification.subject ||
        (this.notification.subject && this.notification.subject.length === 0)
      ) {
        err.push("You must enter a subject for the message");
      }
      return err;
    },
    validateSendByEmail(): string[] {
      let err: string[] = [];
      if (
        this.notification.email === false &&
        this.notification.sms === false &&
        this.notification.app === false
      ) {
        err.push("You must select at least one means of notification.");
      }
      return err;
    },
  },
  data() {
    return {
      valid: true,
      showSenderDialog: false,
      viewModel: {} as IRunDemographicsQueryViewModel,
      allRecipients: Array<IDemographicsNotificationRecipient>(),
      selectedRecipients: Array<IDemographicsNotificationRecipient>(),
      notification: new NotificationMessage(),
      id: 0,
      maxWidth: "40%",
      attachments: Array<File>(),
      toolbarSettings: {
        type: "Expand",
        items: [
          "Bold",
          "Italic",
          "Underline",
          "|",
          "FontSize",
          "|",
          "Alignments",
          "|",
          "OrderedList",
          "UnorderedList",
          "Outdent",
          "Indent",
          "|",
          "CreateLink",
          "|",
          "Undo",
          "Redo",
        ],
      },
      reach: 0,
      incursCost: false,
      searchRecipientsInput: "",
      description: "",
    };
  },
  methods: {
    willIncurCost() {
      let eligible: IDemographicsQueryResultViewModel[] = [];
      for (let recipient of this.selectedRecipients) {
        const match = this.viewModel.attendees.find(
          (f) => f.id === recipient.id
        );
        if (match) {
          if (
            this.notification.sms === true &&
            match.allowContactBySms === true
          ) {
            eligible.push(match);
          }
        }
      }
      this.incursCost = eligible.length > 0;
    },

    calculateCost() {
      this.showProgressIndicator(
        LoadingType.Panel,
        `Calculating cost to send message, Please Wait...`
      );

      this.notification.recipients = [];

      this.notification.queryId = this.viewModel.id;
      this.selectedRecipients.forEach((r) => {
        this.notification.recipients.push(r.id);
      });

      const service = new DemographicsService();
      service
        .getSmsMessageCost(this.viewModel.id, this.notification)
        .then((response) => {
          this.hideProgressIndicator();
          this.showConfirmationDialog(
            "Cost to send message",
            `It is estimated that it will cost approximately ${this.formatCurrency(response.data)} to send this message.`
          );
        })
        .catch((error) => this.showErrorDialog(error));
    },

    calculateReach() {
      let result = 0;
      if (
        this.viewModel.attendees.length > 0 &&
        this.selectedRecipients.length > 0
      ) {
        let eligible: IDemographicsQueryResultViewModel[] = [];
        for (let recipient of this.selectedRecipients) {
          const match = this.viewModel.attendees.find(
            (f) => f.id === recipient.id
          );
          if (match) {
            if (
              this.notification.email === true &&
              match.allowContactByEmail === true
            ) {
              eligible.push(match);
            } else if (
              this.notification.sms === true &&
              match.allowContactBySms === true
            ) {
              eligible.push(match);
            } else if (
              this.notification.app === true &&
              match.allowContactByApp === true
            ) {
              eligible.push(match);
            }
          }
        }

        result = Math.round(
          (eligible.length / this.viewModel.attendees.length) * 100
        );
      }

      this.reach = result;
    },

    async submitForm() {
      this.notification.recipients = [];
      this.notification.attachmentUrls = [];

      this.notification.queryId = this.viewModel.id;
      this.selectedRecipients.forEach((r) => {
        this.notification.recipients.push(r.id);
      });

      for (let fileAttachment of this.attachments) {
        if (fileAttachment.size > 0) {
          this.showProgressIndicator(
            LoadingType.Panel,
            `Compressing attachment ${fileAttachment.name}, Please Wait...`
          );

          try {
            const service = new MediaService();
            const result = await service.uploadFile(
              fileAttachment,
              fileAttachment.name
            );
            if (result.status === 200) {
              this.notification.attachmentUrls.push(result.data);
            }
          } catch (error) {
            this.showErrorDialog(error);
            return;
          }
        }
      }

      this.showProgressIndicator(
        LoadingType.Panel,
        `Sending message, Please Wait...`
      );

      const service = new DemographicsService();
      service
        .sendMessage(this.notification)
        .then((response) => {
          this.hideProgressIndicator();
          if (response.data === true) {
            this.showConfirmationDialog(
              "Send Message",
              "The message was sent."
            );
            this.$emit("dialog-closed");
          } else {
            this.showConfirmationDialog(
              "Send Message",
              "The message failed to send for an un-known reason. Please try again later."
            );
            this.$emit("dialog-closed");
          }
        })
        .catch((error) => this.showErrorDialog(error));
    },

    onCancel() {
      this.$emit("dialog-closed");
    },
  },
});
</script>

<style scoped src="../../assets/css/wg.css">
.v-progress-circular {
  margin: 1rem;
}
</style>