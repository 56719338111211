<template>
  <v-container>
    <v-dialog v-model="dialog" max-width="40%" persistent>
      <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
        <v-card>
          <v-toolbar flat dense elevation="1" color="#E5E3E3">
            {{ dialogData.title }}
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="onExit()">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-row class="top-buffer">
              <v-col md="8">
                <v-select
                  :items="allFilters"
                  v-show="!inEditMode"
                  v-model="selection"
                  label="Filters"
                  placeholder="Select a filter"
                  menu-props="auto"
                  hide-details="auto"
                  item-text="title"
                  item-value="id"
                  return-object
                  outlined
                ></v-select>
                <v-text-field
                  v-model="selection.title"
                  :rules="validateTitle"
                  v-show="inEditMode"
                  clearable
                  outlined
                  hide-details
                  label="Filter Title"
                  placeholder="Enter the title of your filter"
                ></v-text-field>
              </v-col>
              <v-col md="4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-show="!inEditMode"
                      class="ma-2"
                      icon
                      outlined
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="onCreateNewFilter"
                      ><v-icon>mdi-filter-plus</v-icon></v-btn
                    >
                  </template>
                  <span>Click here create a new filter</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-show="!inEditMode"
                      class="ma-2"
                      icon
                      outlined
                      color="success"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!canEditFilter"
                      @click="onEditExistingFilter"
                      ><v-icon>mdi-filter-cog-outline</v-icon></v-btn
                    >
                  </template>
                  <span>Click here to edit this filter</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-show="!inEditMode"
                      class="ma-2"
                      icon
                      outlined
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!canEditFilter"
                      @click="onDeleteItem"
                      ><v-icon>mdi-filter-minus-outline</v-icon></v-btn
                    >
                  </template>
                  <span>Click here to remove this filter</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-show="inEditMode"
                      class="ma-2"
                      icon
                      outlined
                      color="success"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!valid"
                      @click="onNewFilterSave"
                      ><v-icon>mdi-filter-check-outline</v-icon></v-btn
                    >
                  </template>
                  <span>Click here save your new filter settings</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-show="inEditMode"
                      class="ma-2"
                      outlined
                      color="indigo"
                      v-bind="attrs"
                      v-on="on"
                      @click="onCancelNewFilter"
                    >
                      Cancel</v-btn
                    >
                  </template>
                  <span>Click here to cancel creating a new filter</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4">
                <v-autocomplete
                  v-model="selection.currentStatus"
                  :items="filters.currentStatus"
                  label="Current Status"
                  placeholder="Select one or more options"
                  multiple
                  clearable
                  auto-select-first
                  outlined
                  hide-details
                  :disabled="!inEditMode"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index === 0">
                      <span>{{ item }} </span>
                    </div>
                    <div style="width: 4px"></div>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ selection.currentStatus.length - 1 }} others)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col md="4">
                <v-autocomplete
                  v-model="selection.gender"
                  :items="filters.gender"
                  label="Gender"
                  placeholder="Select one or more options"
                  multiple
                  clearable
                  auto-select-first
                  outlined
                  hide-details
                  :disabled="!inEditMode"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index === 0">
                      <span>{{ item }} </span>
                    </div>
                    <div style="width: 4px"></div>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ selection.gender.length - 1 }} others)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                <v-text-field
                  v-model.number="selection.ageStart"
                  :rules="validateAgeStart"
                  type="number"
                  outlined
                  label="Age (from)"
                  hide-details="auto"
                  :disabled="!inEditMode"
                >
                </v-text-field>
              </v-col>
              <v-col md="2">
                <v-text-field
                  v-model.number="selection.ageEnd"
                  :rules="validateAgeEnd"
                  type="number"
                  outlined
                  label="Age (to)"
                  hide-details="auto"
                  :disabled="!inEditMode"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4">
                <v-select
                  :items="filters.contactable"
                  v-model="selection.contactable"
                  label="Contactable Only"
                  placeholder="Select an option"
                  menu-props="auto"
                  hide-details="auto"
                  outlined
                  :disabled="!inEditMode"
                ></v-select>
              </v-col>
              <v-col md="4">
                <v-autocomplete
                  v-model="selection.attributes"
                  :items="filters.attributes"
                  label="Attributes"
                  placeholder="Select one or more options"
                  multiple
                  clearable
                  auto-select-first
                  outlined
                  hide-details
                  :disabled="!inEditMode"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index === 0">
                      <span>{{ item }} </span>
                    </div>
                    <div style="width: 4px"></div>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ selection.attributes.length - 1 }} others)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4">
                <v-autocomplete
                  v-model="selection.maritalStatus"
                  :items="filters.maritalStatus"
                  label="Marital Status"
                  placeholder="Select one or more options"
                  multiple
                  clearable
                  auto-select-first
                  outlined
                  hide-details
                  :disabled="!inEditMode"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index === 0">
                      <span>{{ item }} </span>
                    </div>
                    <div style="width: 4px"></div>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ selection.maritalStatus.length - 1 }} others)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col md="4">
                <v-autocomplete
                  v-model="selection.profession"
                  :items="filters.profession"
                  label="Profession"
                  placeholder="Select one or more options"
                  multiple
                  clearable
                  auto-select-first
                  outlined
                  hide-details
                  :disabled="!inEditMode"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index === 0">
                      <span>{{ item }} </span>
                    </div>
                    <div style="width: 4px"></div>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ selection.profession.length - 1 }} others)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4">
                <v-autocomplete
                  v-model="selection.ethnicity"
                  :items="filters.ethnicity"
                  label="Ethnicity"
                  placeholder="Select one or more options"
                  multiple
                  clearable
                  auto-select-first
                  outlined
                  hide-details
                  :disabled="!inEditMode"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index === 0">
                      <span>{{ item }} </span>
                    </div>
                    <div style="width: 4px"></div>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ selection.ethnicity.length - 1 }} others)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col md="4">
                <v-autocomplete
                  v-model="selection.nationality"
                  :items="filters.nationality"
                  label="Nationality"
                  placeholder="Select one or more options"
                  multiple
                  clearable
                  auto-select-first
                  outlined
                  hide-details
                  :disabled="!inEditMode"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index === 0">
                      <span>{{ item }} </span>
                    </div>
                    <div style="width: 4px"></div>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ selection.nationality.length - 1 }} others)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4">
                <date-picker
                  :date.sync="selection.dateOfBaptismStart"
                  :dateRules="validateDateOfBaptism"
                  :disabled="!inEditMode"
                  label="Baptism Date (Start)"
                ></date-picker>
              </v-col>
              <v-col md="4">
                <date-picker
                  :date.sync="selection.dateOfBaptismEnd"
                  :dateRules="validateDateOfBaptism"
                  :disabled="!inEditMode"
                  label="Baptism Date (End)"
                ></date-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4">
                <date-picker
                  :date.sync="selection.fellowshipDateStart"
                  :dateRules="validateDateOfFellowship"
                  :disabled="!inEditMode"
                  label="Fellowship Date (Start)"
                ></date-picker>
              </v-col>
              <v-col md="4">
                <date-picker
                  :date.sync="selection.fellowshipDateEnd"
                  :dateRules="validateDateOfFellowship"
                  :disabled="!inEditMode"
                  label="Fellowship Date (End)"
                ></date-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4">
                <date-picker
                  :date.sync="selection.resignationDateStart"
                  :dateRules="validateDateOfResignation"
                  :disabled="!inEditMode"
                  label="Resignation Date (Start)"
                ></date-picker>
              </v-col>
              <v-col md="4">
                <date-picker
                  :date.sync="selection.resignationDateEnd"
                  :dateRules="validateDateOfResignation"
                  :disabled="!inEditMode"
                  label="Resignation Date (End)"
                ></date-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4">
                <date-picker
                  :date.sync="selection.datePassedStart"
                  :dateRules="validateDateOfPassing"
                  :disabled="!inEditMode"
                  label="Date Passed (Start)"
                ></date-picker>
              </v-col>
              <v-col md="4">
                <date-picker
                  :date.sync="selection.datePassedEnd"
                  :dateRules="validateDateOfPassing"
                  :disabled="!inEditMode"
                  label="Date Passed (End)"
                ></date-picker>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="onCancel()" :disabled="inEditMode">
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              type="submit"
              :disabled="!valid || inEditMode"
            >
              Apply
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <confirmation-dialog
      :dialog="showDeleteDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showNoFiltersSetDialog"
      :data="dialogMessage"
      @dialog-option-selected="onNoFiltersSetOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import {
  AlertType,
  DialogMessage,
  DialogResponse,
  LoadingType,
  UserFilterName,
} from "@/models/common.model";
import { ValidOptionService } from "@/services/valid-option.service";
import { PeopleFilterOptionsViewModel } from "@/models/valid-option.model";
import DatePicker from "@/components/common/date-picker.vue";
import ExtendedAutoComplete from "@/components/common/extended-autocomplete.vue";
import { PeopleSearchFilter } from "@/models/people.model";
import { RegisteredUserService } from "@/services/registered-user.service";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "RegisteredUserFilterDialog",
  mixins: [CommonMixin],
  components: {
    "extended-autocomplete": ExtendedAutoComplete,
    "date-picker": DatePicker,
  },
  props: {
    dialog: Boolean,
    data: DialogMessage,
    canViewSensitiveData: Boolean,
    filterId: Number,
  },
  data() {
    return {
      valid: false,
      dialogData: new DialogMessage("", ""),
      filters: new PeopleFilterOptionsViewModel(),
      selection: new PeopleSearchFilter(),
      selectionCopy: new PeopleSearchFilter(),
      allFilters: [] as PeopleSearchFilter[],
      isMemberIndeterminate: true,
      showNewFilterDialog: false,
      showDeleteDialog: false,
      deletionHasOccurred: false,
      inEditMode: false,
      showNoFiltersSetDialog: false,
    };
  },
  computed: {
    validateAgeStart(): string[] {
      let err: string[] = [];
      if (this.selection.ageStart) {
        if (!this.isNumber(this.selection.ageStart?.toString()))
          err.push("This is not a number");
        else if (this.selection.ageStart < 0) err.push("Cannot be less than 0");
        else if (this.selection.ageStart > 130)
          err.push("Cannot be greater than 130");
        else if (
          this.selection.ageEnd &&
          this.isNumber(this.selection.ageEnd?.toString())
        ) {
          if (this.selection.ageStart > this.selection.ageEnd)
            err.push("Invalid age range.");
        }
      }
      return err;
    },
    validateAgeEnd(): string[] {
      let err: string[] = [];
      if (this.selection.ageEnd) {
        if (!this.isNumber(this.selection.ageEnd?.toString()))
          err.push("This is not a number");
        else if (this.selection.ageEnd < 0) err.push("Cannot be less than 0");
        else if (this.selection.ageEnd > 130)
          err.push("Cannot be greater than 130");
        else if (
          this.selection.ageStart &&
          this.isNumber(this.selection.ageStart?.toString())
        ) {
          if (this.selection.ageStart > this.selection.ageEnd)
            err.push("Invalid age range.");
        }
      }
      return err;
    },
    validateDateOfBaptism(): string[] {
      let err: string[] = [];
      if (
        this.selection.dateOfBaptismStart &&
        this.selection.dateOfBaptismEnd &&
        this.selection.dateOfBaptismStart > this.selection.dateOfBaptismEnd
      ) {
        err.push("Start date is greater than end date.");
      }
      return err;
    },
    validateDateOfFellowship(): string[] {
      let err: string[] = [];
      if (
        this.selection.fellowshipDateStart &&
        this.selection.fellowshipDateEnd &&
        this.selection.fellowshipDateStart > this.selection.fellowshipDateEnd
      ) {
        err.push("Start date is greater than end date.");
      }
      return err;
    },
    validateDateOfResignation(): string[] {
      let err: string[] = [];
      if (
        this.selection.resignationDateStart &&
        this.selection.resignationDateEnd &&
        this.selection.resignationDateStart > this.selection.resignationDateEnd
      ) {
        err.push("Start date is greater than end date.");
      }
      return err;
    },
    validateDateOfWedding(): string[] {
      let err: string[] = [];
      if (
        this.selection.weddingDateStart &&
        this.selection.weddingDateEnd &&
        this.selection.weddingDateStart > this.selection.weddingDateEnd
      ) {
        err.push("Start date is greater than end date.");
      }
      return err;
    },
    validateDateOfPassing(): string[] {
      let err: string[] = [];
      if (
        this.selection.datePassedStart &&
        this.selection.datePassedEnd &&
        this.selection.datePassedStart > this.selection.datePassedEnd
      ) {
        err.push("Start date is greater than end date.");
      }
      return err;
    },
    validateTitle(): string[] {
      let err: string[] = [];
      if (this.inEditMode && !this.selection.title) {
        err.push("You need to enter title.");
      }
      return err;
    },
    canEditFilter(): boolean {
      return !this.inEditMode && this.selection.title != UserFilterName.All;
    },
  },
  watch: {
    data(val: DialogMessage) {
      this.dialogData = val;
      this.inEditMode = false;
      this.populate(this.filterId);
    },
  },
  methods: {
    submitForm() {
      this.data.entity = this.selection;
      if (this.selection.title == UserFilterName.All) {
        this.$emit("reset-filter");
      } else {
        this.$emit(
          "dialog-option-selected",
          new DialogResponse(true, this.data.entity)
        );
      }
    },

    onCancel() {
      if (this.deletionHasOccurred) {
        this.$emit("reset-filter");
      } else {
        this.$emit(
          "dialog-option-selected",
          new DialogResponse(false, this.data.entity)
        );
      }
    },

    onExit() {
      this.$emit(
        "dialog-option-selected",
        new DialogResponse(false, this.data.entity)
      );
    },

    async populate(id: number) {
      const validOptionService = new ValidOptionService();
      let response = await validOptionService.getPeopleFilterOptions();
      if (response.data) {
        this.filters = response.data;
      }

      const userService = new RegisteredUserService();
      let userResponse = await userService.getPeopleFilters();
      if (userResponse.data) {
        this.allFilters = userResponse.data;
        this.setSelectedFilter(this.allFilters, id);
      }
    },

    showNewFilterSelectionDialog() {
      this.hideProgressIndicator();
      this.dialogMessage = new DialogMessage(
        "New People Filter",
        "Please enter the name of your new filter."
      );
      this.showNewFilterDialog = true;
    },

    onDeleteItem() {
      this.showDeleteConfirmationDialog();
    },

    showDeleteConfirmationDialog() {
      this.dialogMessage = new DialogMessage(
        `Delete ${this.selection.title} filter?`,
        `Are you sure you want to delete the ${this.selection.title} filter?`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = this.selection;
      this.dialogMessage.errors = [];
      this.showDeleteDialog = true;
    },

    onDeleteOptionSelected(item: DialogResponse) {
      this.showDeleteDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing Filter, Please Wait..."
        );
        const service = new RegisteredUserService();
        service
          .removePeopleFilter(this.selection.id)
          .then(() => {
            this.hideProgressIndicator();

            // Set this flag to state that a deletion has happened at some stage and
            // that the owning view should reset it's list accordingly
            this.deletionHasOccurred = true;

            // Refresh the view from the database
            this.populate(this.selection.id);
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    onCreateNewFilter() {
      this.inEditMode = true;
      this.selection = new PeopleSearchFilter();

      // Force the screen to validate
      const ref: any = this.$refs.form;
      if (ref) {
        ref.validate();
      }
    },

    onEditExistingFilter() {
      this.inEditMode = true;

      // Make a copy of the current selection so that if it is cancelled the state can be restored
      this.selectionCopy = JSON.parse(JSON.stringify(this.selection));
    },

    onCancelNewFilter() {
      this.inEditMode = false;

      // Restore the current selection from the copy
      let copy = JSON.parse(
        JSON.stringify(this.selectionCopy)
      ) as PeopleSearchFilter;

      let index = this.allFilters.findIndex((f) => f.id == copy.id);
      if (index !== -1) {
        this.allFilters.splice(index, 1);
        this.allFilters.splice(index, 0, copy);
      }

      this.setSelectedFilter(this.allFilters, this.filterId);
    },

    async onNewFilterSave() {
      if (!this.hasFiltersSelected()) {
        this.showNoFiltersSetConfirmationDialog();
      } else {
        if (this.selection.title !== UserFilterName.All) {
          this.showProgressIndicator(
            LoadingType.Panel,
            `Saving Filter ${this.selection.title}, Please Wait...`
          );

          const service = new RegisteredUserService();
          service
            .savePeopleFilter(this.selection)
            .then((response) => {
              this.hideProgressIndicator();
              this.inEditMode = false;
              let id = response.data as number;
              this.populate(id);
            })
            .catch((error) => {
              this.showErrorDialog(error);
            });
        }
      }
    },

    showNoFiltersSetConfirmationDialog() {
      this.dialogMessage = new DialogMessage(
        `INVALID FILTER SET UP`,
        `You have not selected any filter criteria for ${this.selection.title}. You must select at least 1 filter from the options for it to be considered a usable filter.`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = this.selection;
      this.dialogMessage.errors = [];
      this.showNoFiltersSetDialog = true;
    },

    onNoFiltersSetOptionSelected(item: DialogResponse) {
      this.showNoFiltersSetDialog = false;
    },

    hasFiltersSelected(): boolean {
      let hasFilters: boolean =
        this.selection.maritalStatus.length > 0 ||
        this.selection.ethnicity.length > 0 ||
        this.selection.profession.length > 0 ||
        this.selection.nationality.length > 0 ||
        this.selection.gender.length > 0 ||
        this.selection.contactable !== null ||
        this.selection.ageStart !== null ||
        this.selection.ageEnd !== null ||
        this.selection.dateOfBaptismStart !== null ||
        this.selection.dateOfBaptismEnd !== null ||
        this.selection.fellowshipDateStart !== null ||
        this.selection.fellowshipDateEnd !== null ||
        this.selection.resignationDateStart !== null ||
        this.selection.resignationDateEnd !== null ||
        this.selection.weddingDateStart != null ||
        this.selection.weddingDateEnd !== null ||
        this.selection.datePassedStart !== null ||
        this.selection.datePassedEnd !== null ||
        this.selection.attributes.length > 0 ||
        this.selection.currentStatus.length > 0;

      return hasFilters;
    },

    setSelectedFilter(
      peopleFilters: PeopleSearchFilter[],
      potentialFilterId: number
    ) {
      let match = peopleFilters.find((r) => r.id == potentialFilterId);
      if (match) {
        this.selection = match;
      } else {
        match = peopleFilters.find((r) => r.title == UserFilterName.All);
        if (match) {
          this.selection = match;
        } else {
          this.selection = peopleFilters[0];
        }
      }
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>