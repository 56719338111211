import Axios, { AxiosResponse } from "axios";
import { environment } from "@/environment";
import { BaseService } from "./base.service";
import { IPagedList } from "@/models/common.model";
import { Contributor, ManageContributorViewModel } from "@/models/page.model";
import { ISaveUserFilterColumnSelectionCommand, PeopleSearchFilter } from "@/models/people.model";

export class RegisteredUserService extends BaseService {
    constructor() {
        super();
    }

    public getContributions(userId: number, pageNumber: number, itemsPerPage: number): Promise<AxiosResponse<IPagedList<Contributor>>> {
        const url = `${environment.apiUrl}/admin/registered-user/${userId}/contributions/?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`;
        return Axios.get<IPagedList<Contributor>>(url, { headers: this.getAuthenticationHeader() });
    }

    public saveContributor(userId: number, viewModel: ManageContributorViewModel) {
        const url = `${environment.apiUrl}/admin/registered-user/${userId}/contribution`;
        return Axios.post<ManageContributorViewModel>(url, viewModel, { headers: this.getAuthenticationHeader() });
    }

    public removeContributor(userId: number, contributorId: number): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/registered-user/${userId}/contribution/${contributorId}`;
        return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader() });
    }

    public getSelectedColumns(filterId: number): Promise<AxiosResponse<string[]>> {
        const url = `${environment.apiUrl}/admin/registered-user/filter/columns?id=${filterId}`;
        return Axios.get<string[]>(url, { headers: this.getAuthenticationHeader() });
    }

    public getDefaultColumns(): Promise<AxiosResponse<string[]>> {
        const url = `${environment.apiUrl}/admin/registered-user/filter/columns/default`;
        return Axios.get<string[]>(url, { headers: this.getAuthenticationHeader() });
    }

    public saveColumns(filterId: number, columns: string[]) : Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/registered-user/filter/columns`;  
        let body: ISaveUserFilterColumnSelectionCommand = {
            id: filterId,
            columns: columns
        };
        return Axios.post<boolean>(url, body, { headers: this.getAuthenticationHeader() });
    }

    public getPeopleFilters(): Promise<AxiosResponse<PeopleSearchFilter[]>> {
        const url = `${environment.apiUrl}/admin/registered-user/filters`;
        return Axios.get<PeopleSearchFilter[]>(url, { headers: this.getAuthenticationHeader() });
    }

    public getPeopleAndGroupFilters(groupId: number): Promise<AxiosResponse<PeopleSearchFilter[]>> {
        const url = `${environment.apiUrl}/admin/registered-user/filters/group?id=${groupId}`;
        return Axios.get<PeopleSearchFilter[]>(url, { headers: this.getAuthenticationHeader() });
    }

    public savePeopleFilter(filter: PeopleSearchFilter): Promise<AxiosResponse<number>> {
        const url = `${environment.apiUrl}/admin/registered-user/filter`;
        return Axios.post<number>(url, filter, { headers: this.getAuthenticationHeader() });
    }

    public removePeopleFilter(filterId: number): Promise<AxiosResponse<boolean>> {
        const url = `${environment.apiUrl}/admin/registered-user/filter?id=${filterId}`;
        return Axios.delete<boolean>(url, { headers: this.getAuthenticationHeader() });
    }

}