<template>
  <v-container fluid>
    <v-form ref="manageForm" v-model="valid" @submit.prevent="submitForm">
      <v-row>
        <v-col>
          <section class="page-header">
            <v-row>
              <v-col md="2">
                <h5 class="wg-title">Conference Manager</h5>
                <p class="small" style="margin-bottom: 0">
                  Manage your top-level conference settings here
                </p>
              </v-col>
            </v-row>
          </section>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="5">
          <v-tabs v-model="tab" @change="tabChanged">
            <v-tab>General</v-tab>
            <v-tab>Branches</v-tab>
            <v-tab>Ministries</v-tab>
            <v-tab>Valid Options</v-tab>
            <v-tab>Security</v-tab>
            <v-tab>Users</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs-items v-model="tab">
            <v-tab-item transition="false" eager>
              <general-section
                ref="generalsection"
                @show-alert-panel="showSuccessPanel"
              ></general-section>
            </v-tab-item>
            <v-tab-item transition="false" eager>
              <list-conference-groups
                ref="listbranches"
                :groupType="branchGroupType"
                @show-alert-panel="showSuccessPanel"
              ></list-conference-groups>
            </v-tab-item>
            <v-tab-item transition="false" eager>
              <list-conference-groups
                ref="listministries"
                :groupType="ministryGroupType"
                @show-alert-panel="showSuccessPanel"
              ></list-conference-groups>
            </v-tab-item>
            <v-tab-item transition="false" eager>
              <list-valid-options
                ref="listvalidoptions"
                @show-alert-panel="showSuccessPanel"
              ></list-valid-options>
            </v-tab-item>
            <v-tab-item transition="false" eager>
              <security-manager
                ref="securitymanager"
                @show-alert-panel="showSuccessPanel"
              >
              </security-manager>
            </v-tab-item>
            <v-tab-item transition="false" eager>
              <list-registered-users
                ref="listregisteredusers"
                @show-alert-panel="showSuccessPanel"
              >
              </list-registered-users>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col md="auto">
        <action-alert
          :show="showAlert"
          :message="alertMessage"
          :type="alertType"
        ></action-alert>
      </v-col>
    </v-row>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      :errors="dialogErrors"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import ActionAlert from "@/components/common/action-alert.vue";
import GeneralSection from "@/views/editors/editor-conference-general.vue";
import ListConferenceGroups from "@/views/list-managers/list-manager-groups-conference.vue";
import ListValidOptions from "@/views/list-managers/list-manager-valid-options.vue";
import ListRegisteredUsers from "@/views/lists/list-registered-users.vue";
import SecurityManager from "@/views/list-managers/list-manager-security.vue";
import { GroupType } from "@/models/common.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "EditorConferenceManager",
  mixins: [CommonMixin],
  components: {
    ActionAlert,
    "general-section": GeneralSection,
    "list-conference-groups": ListConferenceGroups,
    "list-valid-options": ListValidOptions,
    "list-registered-users": ListRegisteredUsers,
    "security-manager": SecurityManager,
  },

  data() {
    return {
      tab: null,
      valid: true,
      branchGroupType: GroupType.Branch,
      ministryGroupType: GroupType.NationalMinistry,
    };
  },

  mounted() {
    this.refreshGeneralView();
  },

  methods: {
    tabChanged(tabSelected: number | string) {
      switch (tabSelected) {
        case 0:
          this.refreshGeneralView();
          break;
        case 1:
          this.refreshBranchesView();
          break;
        case 2:
          this.refreshMinistriesView();
          break;
        case 3:
          this.refreshValidOptionsView();
          break;
        case 4:
          this.refreshSecurityView();
          break;
        case 5:
          this.refreshRegisteredUsers();
          break;
      }
    },

    submitForm() {},

    showSuccessPanel(message: string, type: string) {
      this.showAlertPanel(message, type);
    },

    refreshGeneralView() {
      const ref: any = this.$refs.generalsection;
      if (ref) {
        ref.getConference();
      }
    },

    refreshBranchesView() {
      const ref: any = this.$refs.listbranches;
      if (ref) {
        ref.getItems();
      }
    },

    refreshMinistriesView() {
      const ref: any = this.$refs.listministries;
      if (ref) {
        ref.getItems();
      }
    },

    refreshValidOptionsView() {
      const ref: any = this.$refs.listvalidoptions;
      if (ref) {
        ref.getItems();
      }
    },

    refreshSecurityView() {
      const ref: any = this.$refs.securitymanager;
      if (ref) {
        ref.getItems();
      }
    },

    refreshRegisteredUsers() {
      const ref: any = this.$refs.listregisteredusers;
      if (ref) {
        ref.getItems();
      }
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>