var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("1")]),
              _vm._v("Contact Details"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " This section is to provide contact information for notifying the user. Notifications are subject to the user's GDPR preferences. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("map-autocomplete", {
                        ref: _vm.viewModel.location.localId,
                        attrs: {
                          id: _vm.viewModel.location.localId,
                          address: _vm.viewModel.location.address,
                          disabled: !_vm.canViewSensitiveData,
                          readonly: !_vm.canViewSensitiveData,
                          placeholder: "Address",
                        },
                        on: { addressChanged: _vm.onAddressChange },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.tmpContactNumbers, function (contact) {
                return _c(
                  "v-row",
                  { key: contact.id },
                  [
                    _c(
                      "v-col",
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: contact.label,
                            placeholder: contact.label,
                            outlined: "",
                            clearable: "",
                            "hide-details": "auto",
                            maxlength: "200",
                            rules: [_vm.rules.phone],
                            disabled: !_vm.canViewSensitiveData,
                            readonly: !_vm.canViewSensitiveData,
                          },
                          model: {
                            value: contact.value,
                            callback: function ($$v) {
                              _vm.$set(contact, "value", $$v)
                            },
                            expression: "contact.value",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ma-2",
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                                icon: "",
                                                disabled:
                                                  !_vm.canViewSensitiveData,
                                                "hide-details": "auto",
                                              },
                                              on: {
                                                click: _vm.addContactNumber,
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v(" mdi-cellphone "),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("add number")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ma-2",
                                              attrs: {
                                                color: "red",
                                                text: "",
                                                icon: "",
                                                disabled:
                                                  !_vm.canViewSensitiveData,
                                                "hide-details": "auto",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeContactNumber(
                                                    contact
                                                  )
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v(" mdi-cellphone-remove "),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("remove number")])]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm._l(_vm.tmpEmails, function (item) {
                return _c(
                  "v-row",
                  { key: item.label },
                  [
                    _c(
                      "v-col",
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: item.label,
                            placeholder: item.label,
                            outlined: "",
                            clearable: "",
                            "hide-details": "auto",
                            maxlength: "200",
                            disabled: !_vm.canViewSensitiveData,
                            readonly: !_vm.canViewSensitiveData,
                            rules: [_vm.rules.email],
                          },
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { md: "3" } },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ma-2",
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                                icon: "",
                                                disabled:
                                                  !_vm.canViewSensitiveData,
                                                "hide-details": "auto",
                                              },
                                              on: { click: _vm.addEmail },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v(" mdi-email-plus-outline "),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("add email")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ma-2",
                                              attrs: {
                                                color: "red",
                                                text: "",
                                                icon: "",
                                                disabled:
                                                  !_vm.canViewSensitiveData,
                                                "hide-details": "auto",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeEmail(item)
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              " mdi-email-remove-outline "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("remove email")])]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("2")]),
              _vm._v("Emergency Contact Details"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " This section is to provide emergency contact information. If the contact is an existing member then start typing their name in the box. If they are not, press the people icon and then start to manually enter their details. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("member-combobox", {
                        attrs: {
                          name: _vm.viewModel.emergencyContactName1,
                          member: _vm.viewModel.emergencyContact1,
                          isMember: _vm.viewModel.emergencyContactName1IsMember,
                          disabled: !_vm.canViewSensitiveData,
                          readonly: !_vm.canViewSensitiveData,
                          label: "Emergency Name 1",
                          placeholder: "Type the name",
                        },
                        on: {
                          "update:name": function ($event) {
                            return _vm.$set(
                              _vm.viewModel,
                              "emergencyContactName1",
                              $event
                            )
                          },
                          "update:member": function ($event) {
                            return _vm.$set(
                              _vm.viewModel,
                              "emergencyContact1",
                              $event
                            )
                          },
                          "update:isMember": function ($event) {
                            return _vm.$set(
                              _vm.viewModel,
                              "emergencyContactName1IsMember",
                              $event
                            )
                          },
                          "update:is-member": function ($event) {
                            return _vm.$set(
                              _vm.viewModel,
                              "emergencyContactName1IsMember",
                              $event
                            )
                          },
                          isMemberChanged: _vm.emergencyContact1TypeChanged,
                          memberChanged: _vm.emergencyContact1MemberChanged,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Emergency Number 1",
                          placeholder: "Emergency Contact Number 1",
                          outlined: "",
                          clearable: !_vm.emergencyContactNumber1IsReadOnly,
                          "hide-details": "auto",
                          maxlength: "200",
                          disabled: !_vm.canViewSensitiveData,
                          readonly:
                            !_vm.canViewSensitiveData ||
                            _vm.emergencyContactNumber1IsReadOnly,
                          rules: _vm.validateEmergencyContactNumber1,
                        },
                        model: {
                          value: _vm.viewModel.emergencyContactNumber1,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.viewModel,
                              "emergencyContactNumber1",
                              $$v
                            )
                          },
                          expression: "viewModel.emergencyContactNumber1",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("member-combobox", {
                        attrs: {
                          name: _vm.viewModel.emergencyContactName2,
                          member: _vm.viewModel.emergencyContact2,
                          isMember: _vm.viewModel.emergencyContactName2IsMember,
                          disabled: !_vm.canViewSensitiveData,
                          readonly: !_vm.canViewSensitiveData,
                          label: "Emergency Name 2",
                          placeholder: "Type the name",
                        },
                        on: {
                          "update:name": function ($event) {
                            return _vm.$set(
                              _vm.viewModel,
                              "emergencyContactName2",
                              $event
                            )
                          },
                          "update:member": function ($event) {
                            return _vm.$set(
                              _vm.viewModel,
                              "emergencyContact2",
                              $event
                            )
                          },
                          "update:isMember": function ($event) {
                            return _vm.$set(
                              _vm.viewModel,
                              "emergencyContactName2IsMember",
                              $event
                            )
                          },
                          "update:is-member": function ($event) {
                            return _vm.$set(
                              _vm.viewModel,
                              "emergencyContactName2IsMember",
                              $event
                            )
                          },
                          isMemberChanged: _vm.emergencyContact2TypeChanged,
                          memberChanged: _vm.emergencyContact2MemberChanged,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Emergency Number 2",
                          placeholder: "Emergency Contact Number 2",
                          outlined: "",
                          clearable: !_vm.emergencyContactNumber2IsReadOnly,
                          "hide-details": "auto",
                          maxlength: "200",
                          disabled: !_vm.canViewSensitiveData,
                          readonly:
                            !_vm.canViewSensitiveData ||
                            _vm.emergencyContactNumber2IsReadOnly,
                          rules: _vm.validateEmergencyContactNumber2,
                        },
                        model: {
                          value: _vm.viewModel.emergencyContactNumber2,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.viewModel,
                              "emergencyContactNumber2",
                              $$v
                            )
                          },
                          expression: "viewModel.emergencyContactNumber2",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("3")]),
              _vm._v("Socials"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " This section is to provide contact any social media or external platforms that the user can be found or contacted on. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            _vm._l(_vm.viewModel.socials, function (social) {
              return _c(
                "v-row",
                { key: social.platform },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.viewModel.externalPlatforms,
                          label: "Platform",
                          placeholder: "type of social platform",
                          "menu-props": "auto",
                          "hide-details": "auto",
                          outlined: "",
                          clearable: "",
                        },
                        model: {
                          value: social.platform,
                          callback: function ($$v) {
                            _vm.$set(social, "platform", $$v)
                          },
                          expression: "social.platform",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Platform Url",
                          placeholder: "The url of the platform",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "200",
                          disabled: !_vm.canViewSensitiveData,
                          readonly: !_vm.canViewSensitiveData,
                          rules: [_vm.rules.url],
                        },
                        model: {
                          value: social.contentUrl,
                          callback: function ($$v) {
                            _vm.$set(social, "contentUrl", $$v)
                          },
                          expression: "social.contentUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { md: "3" } },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ma-2",
                                            attrs: {
                                              color: "primary",
                                              text: "",
                                              icon: "",
                                              disabled:
                                                !_vm.canViewSensitiveData,
                                              "hide-details": "auto",
                                            },
                                            on: {
                                              click: _vm.addExternalPlatform,
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v(" mdi-cellphone "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("add platform")])]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ma-2",
                                            attrs: {
                                              color: "red",
                                              text: "",
                                              icon: "",
                                              disabled:
                                                !_vm.canViewSensitiveData,
                                              "hide-details": "auto",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeExternalPlatform(
                                                  social
                                                )
                                              },
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v(" mdi-cellphone-remove "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("remove platform")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }