import { PeopleSearchFilter } from "@/models/people.model";
import { IUser, IUserProfile } from "@/models/security.model";
import SecureLS from "secure-ls";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const ls = new SecureLS({ isCompression: false });

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {
    user: {
      authenticated: false,
      authenticating: false,
      profile: {
        applicationId: 0,
        displayName: '',
        email: '',
        photoURL: '',
        uid: '',
        token: '',
        permissions: new Array<string>()
      }
    },
    title: '',
    formTab: 0,
    eventCalendarViewType: 0,
    peopleSearchFilterId: 0
  },
  getters: {
    user(state): IUser {
      return state.user;
    },
    profile(state): IUserProfile {
      return state.user.profile;
    },
    authenticated(state): boolean {
      return state.user.authenticated;
    },
    title(state): string {
      return state.title;
    },
    formTab(state): number {
      return state.formTab;
    },
    eventCalendarViewType(state): number {
      return state.eventCalendarViewType;
    },
    peopleSearchFilterId(state): number {
      return state.peopleSearchFilterId;
    }
  },
  mutations: {
    setUser(state, value: IUserProfile) {
      state.user.profile = value;
    },
    setAuthenticated(state, value: boolean) {
      state.user.authenticated = value;
    },
    setAuthenticating(state, value: boolean) {
      state.user.authenticating = value;
    },
    clearUser(state) {
      state.user = {
        authenticated: false,
        authenticating: false,
        profile: {
          applicationId: 0,
          displayName: '',
          email: '',
          photoURL: '',
          uid: '',
          token: '',
          permissions: new Array<string>()
        }
      };
    },
    setTitle(state, value: string) {
      state.title = value;
    },
    setFormTab(state, value: number) {
      state.formTab = value;
    },
    setEventCalendarViewType(state, value: number) {
      state.eventCalendarViewType = value;
    },
    setPeopleSearchFilterId(state, value: number) {
      state.peopleSearchFilterId = value;
    }
  },
  actions: {
    async refreshUser({ commit }, user: IUser | null) {
      if (user) {
        commit("setAuthenticated", user.authenticated);
        commit("setAuthenticating", user.authenticating);
        commit("setUser", user.profile);
      } else {
        commit("clearUser");
      }
    },
  },
  modules: {},
});
