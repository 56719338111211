var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "3", xl: "2" } }, [
            _c("h6", [
              _c("span", { staticClass: "number" }, [_vm._v("1")]),
              _vm._v("Gallery Details"),
            ]),
            _c(
              "p",
              {
                staticClass: "small",
                staticStyle: { "padding-right": "40px" },
              },
              [
                _vm._v(
                  " Name and describe your gallery so that everyone can experience the event with you. "
                ),
              ]
            ),
          ]),
          _c(
            "v-col",
            { attrs: { md: "4" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Title",
                          placeholder: "Title",
                          outlined: "",
                          clearable: "",
                          "hide-details": "auto",
                          maxlength: "200",
                          rules: [_vm.rules.required],
                        },
                        model: {
                          value: _vm.viewModel.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.viewModel, "title", $$v)
                          },
                          expression: "viewModel.title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-textarea", {
                        attrs: {
                          outlined: "",
                          clearable: "",
                          "auto-grow": "",
                          "hide-details": "auto",
                          label: "Description",
                          placeholder: "Description",
                          maxlength: "800",
                          rows: "5",
                          rules: [_vm.rules.required],
                        },
                        model: {
                          value: _vm.viewModel.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.viewModel, "description", $$v)
                          },
                          expression: "viewModel.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }