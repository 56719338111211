<template>
  <v-container fluid>
    <v-row>
      <v-row>
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn outlined color="primary" class="ma-1" @click="onNewEvent">
                New Event
              </v-btn>
              <v-btn
                v-show="isCalendarView"
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="onCalendarTodayButtonClicked"
              >
                Today
              </v-btn>
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="onCalendarPrevIntervalClicked"
              >
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="onCalendarNextIntervalClicked"
              >
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right menu-props="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="isCalendarView"
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ interval }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="onCalendarIntervalChanged('day')">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="onCalendarIntervalChanged('week')">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="onCalendarIntervalChanged('working-week')"
                  >
                    <v-list-item-title>Work Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="onCalendarIntervalChanged('month')">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <div style="width: 12px"></div>
              <v-btn-toggle
                v-model="calendarViewType"
                color="primary"
                @change="onCalendarViewTypeChange"
              >
                <v-btn>Calendar</v-btn>
                <v-btn>Grid</v-btn>
              </v-btn-toggle>
            </v-toolbar>
          </v-sheet>
          <v-sheet>
            <v-row>
              <v-col md="auto">
                <v-date-picker
                  no-title
                  elevation="2"
                  v-model="sideCalendarSelectedDate"
                  @input="onSideCalendarDateSelected"
                  :events="sideCalendarEvents"
                  event-color="green lighten-1"
                >
                </v-date-picker>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-checkbox
                        label="Incl. National Calendar Events"
                        v-model="includeNationalCalendarEvents"
                        @change="onNationalCalendarCheckChanged"
                      />
                    </span>
                  </template>
                  <span
                    >Check this box to include published events <br />from other
                    branches and ministries</span
                  >
                </v-tooltip>
              </v-col>
              <v-col>
                <v-calendar
                  v-show="isCalendarView"
                  ref="calendar"
                  v-model="focus"
                  color="primary"
                  :type="type"
                  :events="events"
                  :event-ripple="true"
                  :weekdays="weekdays"
                  :event-more="false"
                  @change="onDateChange"
                  @click:event="onEventClicked"
                  @click:more="onCalendarDayClicked"
                  @click:date="onCalendarDayClicked"
                  @click:time="onCalendarTimeClicked"
                  @click:day="onCalendarDayClicked"
                  @mouseenter:event="onEventMouseEnter"
                  @mousedown:event="onEventStartDrag"
                  @mousedown:time="onCalendarMouseDown"
                  @mousemove:time="onCalendarMouseMove"
                  @mouseup:time="onEventEndDrag"
                  @mouseleave.native="onEventCancelDrag"
                >
                  <template v-slot:event="{ event, timed, eventSummary }">
                    <div class="v-event-draggable">
                      <component :is="{ render: eventSummary }"></component>
                    </div>
                    <div
                      v-if="timed"
                      class="v-event-drag-bottom"
                      @mousedown.stop="onExtendEventDate(event)"
                    ></div>
                  </template>
                </v-calendar>
                <v-card>
                  <v-data-table
                    v-show="!isCalendarView"
                    :headers="headers"
                    :items="eventData"
                    :single-select="false"
                    :items-per-page="10"
                    :show-select="false"
                    item-key="id"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-row>
                        <v-col>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="error"
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="onDeleteEventItem(item)"
                                :disabled="disableEventToolBar(item)"
                              >
                                <v-icon> mdi-trash-can-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Click here to remove the event</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="primary"
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="onEditEventItem(item)"
                                :disabled="disableEventToolBar(item)"
                              >
                                <v-icon>
                                  mdi-file-document-edit-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Click here to edit the event</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="primary"
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="onNotifyEventItem(item)"
                                :disabled="disableEventToolBar(item)"
                              >
                                <v-icon> mdi-bell-ring </v-icon>
                              </v-btn>
                            </template>
                            <span
                              >Click here to notify others of the event</span
                            >
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-show="!item.isPublished"
                                color="primary"
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="onPublishEventItem(item)"
                                :disabled="disableEventToolBar(item)"
                              >
                                <v-icon> mdi-publish </v-icon>
                              </v-btn>
                            </template>
                            <span>Click here to publish the event</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-show="item.isPublished"
                                color="primary"
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="onUnPublishEventItem(item)"
                                :disabled="disableEventToolBar(item)"
                              >
                                <v-icon> mdi-publish-off </v-icon>
                              </v-btn>
                            </template>
                            <span>Click here to un-publish the event</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-show="!item.isCancelled"
                                color="primary"
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="onCancelEventItem(item)"
                                :disabled="disableEventToolBar(item)"
                              >
                                <v-icon> mdi-calendar-remove </v-icon>
                              </v-btn>
                            </template>
                            <span>Click here to cancel the event</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-show="item.isCancelled"
                                color="primary"
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="onUnCancelEventItem(item)"
                                :disabled="disableEventToolBar(item)"
                              >
                                <v-icon> mdi-calendar </v-icon>
                              </v-btn>
                            </template>
                            <span>Click here to un-cancel the event</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:[`item.startDateTime`]="{ item }">
                      {{ formatDateTime(item.startDateTime) }}
                    </template>
                    <template v-slot:[`item.endDateTime`]="{ item }">
                      {{ formatDateTime(item.endDateTime) }}
                    </template>
                    <template v-slot:[`item.isCancelled`]="{ item }">
                      <v-simple-checkbox
                        v-model="item.isCancelled"
                        disabled
                      ></v-simple-checkbox>
                    </template>
                    <template v-slot:[`item.isPublished`]="{ item }">
                      <v-simple-checkbox
                        v-model="item.isPublished"
                        disabled
                      ></v-simple-checkbox>
                    </template>
                    <template v-slot:[`item.isOnNationalCalendar`]="{ item }">
                      <v-simple-checkbox
                        v-model="item.isOnNationalCalendar"
                        disabled
                      ></v-simple-checkbox>
                    </template>
                    <template
                      v-slot:[`item.isHeldInPhysicalLocation`]="{ item }"
                    >
                      <v-simple-checkbox
                        v-model="item.isHeldInPhysicalLocation"
                        disabled
                      ></v-simple-checkbox>
                    </template>
                    <template v-slot:[`item.isHeldOnline`]="{ item }">
                      <v-simple-checkbox
                        v-model="item.isHeldOnline"
                        disabled
                      ></v-simple-checkbox>
                    </template>
                    <template v-slot:[`item.address`]="{ item }">
                      {{ getAddress(item) }}
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
            <event-info-popup
              :selectedEventInfoDialog="showInfoPopup"
              :nudgeLeft="nudgeLeft"
              :selectedElement="selectedElement"
              :selectedEvent="selectedEvent"
              :maxEventInfoDialogWidth="maxEventInfoDialogWidth"
              :disableToolbar="disableInfoPopupToolbar"
              @showEditEventScreen="showFullEditScreen"
              @deleteEvent="deleteEvent"
              @closeEventInfoDialog="closeEventInfoDialog"
              @cancelEvent="cancelEvent"
              @unCancelEvent="unCancelEvent"
              @publishEvent="publishEvent"
              @unPublishEvent="unPublishEvent"
              @notifyEvent="notifyEvent"
            >
            </event-info-popup>
            <event-info-edit-popup
              :selectedEventInfoEditDialog="showEditPopup"
              :nudgeLeft="nudgeLeft"
              :selectedNewElement="selectedNewElement"
              :model="newEventItem"
              :maxEventInfoDialogWidth="440"
              @save-event="onEventSave"
              @more-options="onMoreOptions"
              @close-dialog="closeEventInfoEditDialog"
            >
            </event-info-edit-popup>
            <notification-sender-dialog
              :dialog="showNotificationDialog"
              :message="notificationMessage"
              :title="notificationTitle"
              :category="notificationCategory"
              :entityId="notificationEventId"
              @dialog-closed="onNotificationDialogClosed"
            >
            </notification-sender-dialog>
          </v-sheet>
        </v-col>
      </v-row>
    </v-row>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <confirmation-dialog
      :dialog="showDeleteDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showCancelDialog"
      :data="dialogMessage"
      :errors="dialogErrors"
      @dialog-option-selected="onCancelDialogSelected"
    ></confirmation-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import eventInfoPopup from "@/components/events/event-info-popup.vue";
import eventInfoEditPopup from "@/components/events/event-info-edit-popup.vue";
import {
  EventListItem,
  ICalendarEvent,
  ICalendarStart,
} from "@/models/events.model";
import { DateTime, Duration } from "luxon";
import { EventsService } from "@/services/events.service";
import {
  DialogMessage,
  DialogResponse,
  NotificationType,
} from "@/models/common.model";
import { CalendarEventStatus, LoadingType } from "@/models/common.model";
import NotificationSenderDialog from "@/components/notifications/notification-sender-dialog.vue";
import DatePicker from "@/components/common/date-picker.vue";
import store from "@/store";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "list-events",
  mixins: [CommonMixin],
  components: {
    eventInfoPopup,
    eventInfoEditPopup,
    "notification-sender-dialog": NotificationSenderDialog,
    "date-picker": DatePicker,
  },

  data: () => ({
    groupId: 0,
    value: "",
    allEvents: Array<ICalendarEvent>(),
    firstTimeIn: true,
    filterStartRange: new Date(),
    filterEndRange: new Date(),
    calendarStartDate: new Date(),
    calendarEndDate: new Date(),
    showDeleteDialog: false,
    newEventItem: new EventListItem(),
    events: Array<ICalendarEvent>(),
    dragEvent: {} as ICalendarEvent | null,
    dragTime: {} as number | null,
    dragEventEndTime: "",
    createEvent: {} as ICalendarEvent | null,
    createStart: {} as number | null,
    extendOriginal: {} as number | null,
    selectedEvent: {} as ICalendarEvent | null,
    selectedElement: null,
    selectedNewEvent: {} as ICalendarEvent | null,
    selectedNewElement: null,
    selectedEventInfoDialog: false,
    selectedEventInfoEditDialog: false,
    nudgeLeft: 10,
    maxEventInfoDialogWidth: 380,
    focus: "",
    type: "week",
    interval: "Week",
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    noDaysToDisplay: 7,
    selectionStatus: CalendarEventStatus.None,
    showInfoPopup: false,
    showEditPopup: false,
    initialised: false,
    showCancelDialog: false,
    showNotificationDialog: false,
    notificationTitle: "",
    notificationMessage: "",
    notificationCategory: NotificationType.event,
    notificationEventId: 0,
    includeNationalCalendarEvents: true,
    disableInfoPopupToolbar: false,
    sideCalendarEvents: [] as string[],
    calendarViewType: 0,
    isCalendarView: true,
    allEventData: Array<EventListItem>(),
    eventData: Array<EventListItem>(),
    headers: [
      { text: "Actions", value: "actions", sortable: false, width: 240 },
      {
        text: "Organiser",
        value: "organiserGroupName",
        sortable: true,
        width: 120,
      },
      { text: "Published", value: "isPublished", sortable: true, width: 120 },
      { text: "Title", value: "title", sortable: true, width: 140 },
      { text: "Start", value: "startDateTime", sortable: true, width: 180 },
      { text: "End", value: "endDateTime", sortable: true, width: 180 },
      { text: "Location", value: "address", sortable: true, width: 340 },
      {
        text: "In Building",
        value: "isHeldInPhysicalLocation",
        sortable: true,
        width: 120,
      },
      { text: "Online", value: "isHeldOnline", sortable: true, width: 120 },
      { text: "Cancelled", value: "isCancelled", sortable: true },
    ],
    sideCalendarSelectedDate: "",
    sideCalendarStartDate: new Date(),
    sideCalendarEndDate: new Date(),
  }),

  watch: {
    selectedEventInfoDialog(val) {
      this.showInfoPopup = val;
    },
    selectedEventInfoEditDialog(val) {
      this.showEditPopup = val;
    },
  },

  created() {
    // Remember the user's last selection of the calendar or grid view
    this.calendarViewType = store.state.eventCalendarViewType;
    this.onCalendarViewTypeChange(this.calendarViewType);
  },

  methods: {
    getItems(groupId: number) {
      this.groupId = groupId;
      this.initialised = true;

      const ref: any = this.$refs.calendar;
      if (ref) {
        ref.checkChange();
        this.$nextTick(() => {
          ref.scrollToTime("01:00");
        });
      }

      this.clearTemporaryEvents();
      this.loadEvents();
    },

    scrollIntoView() {
      // If there are no events on the screen then do not set a default time
      let time: string = "16:00";

      // Determine the time to scroll to based on the visible range of dates and the event contained within that range
      // set it to so that it the top of the calendar is at least an hour and a bit before the first event
      if (this.allEvents.length > 0) {
        let eventsInVisibleRange = this.allEvents.filter(
          (f) =>
            new Date(f.start) >= this.calendarStartDate! &&
            new Date(f.end) <= this.calendarEndDate!
        );
        if (eventsInVisibleRange.length > 0) {
          let firstDate = DateTime.fromJSDate(eventsInVisibleRange[0].start);
          let dateInView =
            firstDate.hour === 0
              ? firstDate
              : firstDate.minus({ hours: 1, minutes: firstDate.minute });
          time = dateInView.toLocaleString(DateTime.TIME_24_SIMPLE);
        }
      }

      if (time !== undefined) {
        const ref: any = this.$refs.calendar;
        if (ref) {
          this.$nextTick(() => {
            ref.scrollToTime(time);
          });
        }
      }
    },

    onNationalCalendarCheckChanged(checked: boolean) {
      this.refreshFilter();
    },

    onDateChange({
      start,
      end,
    }: {
      start: ICalendarStart;
      end: ICalendarStart;
    }) {
      // Reset the selection status and clear out any temporary events
      // here as this is called whenever any interval changes occur
      this.setSelectionStatus(CalendarEventStatus.None, true);

      // Determine whether we need the new range for filtering the events
      // and whether we need to go to the database to get it
      const getData = this.determineFilterRange(start, end);

      // Get any additional information from the server and filter the list
      // to only show the events that fall within the calendar range
      if (getData === true && this.initialised === true) {
        this.loadEvents();
      } else {
        this.refreshFilter();
      }
    },

    loadEvents() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Getting Events, Please Wait..."
      );

      // Include national events by default
      const service = new EventsService();
      service
        .getEvents(
          this.groupId,
          DateTime.fromJSDate(this.filterStartRange).toUTC(),
          DateTime.fromJSDate(this.filterEndRange).toUTC(),
          true
        )
        .then((response) => {
          this.hideProgressIndicator();

          this.allEvents = [];
          this.sideCalendarEvents = [];
          this.allEventData = response.data;

          for (const ev of response.data) {
            const eStart = new Date(ev.startDateTime);
            const eEnd = new Date(ev.endDateTime);
            var isNationalEventButNotForGroup =
              ev.isOnNationalCalendar && ev.ownerId !== this.groupId;

            if (
              ev.ownerId == this.groupId ||
              ev.isOnNationalCalendar == this.includeNationalCalendarEvents
            ) {
              this.sideCalendarEvents.push(eStart.toISOString().substr(0, 10));
            }

            this.allEvents.push({
              id: ev.id,
              name: ev.isCancelled
                ? `(CANCELLED) - ${ev.title}`
                : isNationalEventButNotForGroup
                ? `(${ev.organiserGroupName}) - ${ev.title}`
                : ev.isPublished
                ? ev.title
                : `(UN-PUBLISHED) - ${ev.title}`,
              color: ev.isCancelled
                ? "red"
                : isNationalEventButNotForGroup
                ? "#717d7e"
                : ev.isPublished
                ? "primary"
                : "grey",
              start: eStart,
              end: eEnd,
              timed: true,
              rangeString: this.rangeString(eStart, eEnd),
              summary: "",
              tag: ev,
            });
          }

          this.refreshFilter();
        })
        .catch((error) => this.showErrorDialog(error));
    },

    refreshFilter() {
      var showableEvents = this.allEvents.filter(
        (f) =>
          f.tag.ownerId == this.groupId ||
          f.tag.isOnNationalCalendar == this.includeNationalCalendarEvents
      );

      this.sideCalendarEvents = showableEvents.map((f) =>
        new Date(f.start).toISOString().substr(0, 10)
      );
      this.events = showableEvents.filter(
        (f) =>
          new Date(f.start) >= this.filterStartRange! &&
          new Date(f.end) <= this.filterEndRange!
      );

      this.refreshGridFilter(this.focus);
      this.scrollIntoView();
    },

    determineFilterRange(start: ICalendarStart, end: ICalendarStart): boolean {
      // For the 1st time in we want a date range that covers
      // the previous and next six months of events
      const fetchStart = DateTime.now().minus({ months: 6 });
      const fetchEnd = DateTime.now().plus({ months: 6 });

      // Get the start and end date range from the calendar control
      // If this is the 1st time the date is being set we want to go
      // to the server and get a big range so that we reduce the amount
      // of round trips as the user is navigating the calendar
      this.calendarStartDate = this.firstTimeIn
        ? DateTime.utc(fetchStart.year, fetchStart.month, 1, 0, 0, 0).toJSDate()
        : DateTime.utc(start.year, start.month, start.day, 0, 0, 0).toJSDate();

      this.calendarEndDate = this.firstTimeIn
        ? DateTime.utc(
            fetchEnd.year,
            fetchEnd.month,
            fetchEnd.daysInMonth,
            23,
            59,
            59
          ).toJSDate()
        : DateTime.utc(end.year, end.month, end.day, 23, 59, 59).toJSDate();

      if (this.firstTimeIn) {
        this.filterStartRange = this.calendarStartDate;
        this.filterEndRange = this.calendarEndDate;
        this.firstTimeIn = false;
      }

      // Determine whether we need to get more data from the server
      let getData: boolean = this.allEvents.length == 0;
      if (getData === false) {
        // If the calendar is requesting data that is outside of the range that has been
        // previously requested then expand the range and get the data
        if (this.calendarStartDate < this.filterStartRange) {
          let smatches = this.allEvents.find(
            (f) => new Date(f.start) < this.calendarStartDate
          );
          if (!smatches) {
            this.filterStartRange = this.calendarStartDate;
            getData = true;
          }
        }

        if (this.calendarEndDate > this.filterEndRange) {
          let ematches = this.allEvents.find(
            (f) => new Date(f.end) > this.calendarEndDate
          );
          if (!ematches) {
            this.filterEndRange = this.calendarEndDate;
            getData = true;
          }
        }
      }

      return getData;
    },

    deleteEvent() {
      if (this.selectedEvent) {
        this.showDeleteConfirmationDialog(this.selectedEvent);
      }
    },

    showDeleteConfirmationDialog(item: ICalendarEvent) {
      this.dialogMessage = new DialogMessage(
        `Delete ${item.name}?`,
        "Are you sure you want to delete this event? Deleting an event is IRREVERSIBLE and all related content will be removed as well."
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = item;
      this.dialogMessage.errors = [];
      this.showDeleteDialog = true;
    },

    setSelectionStatus(
      value: CalendarEventStatus,
      clearTempEvents: boolean = false
    ) {
      this.selectionStatus = value;
      this.disableInfoPopupToolbar = false;

      switch (value) {
        case CalendarEventStatus.ExistingEventSelected:
          this.selectedEventInfoDialog = true;
          this.selectedEventInfoEditDialog = false;
          if (
            this.selectedEvent?.tag &&
            this.selectedEvent?.tag.ownerId !== null
          ) {
            this.disableInfoPopupToolbar =
              this.selectedEvent?.tag.ownerId !== this.groupId;
          }
          break;

        case CalendarEventStatus.NewEventSelected:
          this.selectedEventInfoDialog = false;
          this.selectedEventInfoEditDialog = true;
          this.disableInfoPopupToolbar = false;
          break;

        default:
          this.selectedEventInfoDialog = false;
          this.selectedEventInfoEditDialog = false;
          this.disableInfoPopupToolbar = false;
          break;
      }

      if (clearTempEvents) {
        this.clearTemporaryEvents();
      }
    },

    onDeleteOptionSelected(item: DialogResponse) {
      this.showDeleteDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing Event, Please Wait..."
        );
        const service = new EventsService();
        service
          .removeEvent(item.entity.id)
          .then(() => {
            this.hideProgressIndicator();
            this.setSelectionStatus(CalendarEventStatus.None, true);

            let index = this.allEvents.findIndex((f) => f.id == item.entity.id);
            if (index !== -1) {
              this.allEvents.splice(index, 1);
            }
            let index2 = this.allEventData.findIndex(
              (f) => f.id == item.entity.id
            );
            if (index2 !== -1) {
              this.allEventData.splice(index2, 1);
            }
            this.refreshFilter();
          })
          .catch((error) => this.showErrorDialog(error));
      } else {
        this.setSelectionStatus(CalendarEventStatus.None, true);
      }
    },

    onCalendarTimeClicked(item: ICalendarStart) {
      if (this.selectionStatus === CalendarEventStatus.None) {
        // Close any open temporary pop-up and remove any temporary events that
        // may be hanging around due to the mouse enter event not being triggered
        this.clearTemporaryEvents();

        // Determine the date/time that has been selected on the calendar
        const clickedStart = DateTime.fromJSDate(
          new Date(
            item.year,
            item.month > 1 ? item.month - 1 : 0,
            item.day,
            this.toNearestHour(item.hour, item.minute),
            this.toNearestQuarterMinute(item.minute)
          )
        );

        // Populate the new item entity ready for the edit event dialogs
        this.newEventItem = new EventListItem();
        this.newEventItem.id = 0;
        this.newEventItem.title = "New Meeting";
        this.newEventItem.isOnNationalCalendar = true;
        this.newEventItem.startDateTimeDisplay = clickedStart.toISO();
        this.newEventItem.endDateTimeDisplay = clickedStart
          .plus({ hour: 1 })
          .toISO();

        const eStart = new Date(this.newEventItem.startDateTimeDisplay);
        const eEnd = new Date(this.newEventItem.endDateTimeDisplay);

        // Create a calendar event to temporarily add to the calendar
        let newEvent: ICalendarEvent = {
          id: this.newEventItem.id,
          name: this.newEventItem.title,
          start: eStart,
          end: eEnd,
          timed: true,
          rangeString: this.rangeString(eStart, eEnd),
          color: "grey",
          summary: "",
          tag: this.newEventItem,
        };

        // Add to the list of all event
        this.allEvents.push(newEvent);
        this.refreshFilter();
      } else {
        this.setSelectionStatus(CalendarEventStatus.None, true);
      }
    },

    openInfoPopup(nativeEvent: any, event: ICalendarEvent) {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      requestAnimationFrame(() =>
        requestAnimationFrame(() => {
          this.setSelectionStatus(CalendarEventStatus.ExistingEventSelected);
        })
      );
    },

    openEditPopup(nativeEvent: any, event: ICalendarEvent) {
      this.selectedNewEvent = event;
      this.selectedNewElement = nativeEvent.target;
      requestAnimationFrame(() =>
        requestAnimationFrame(() => {
          this.setSelectionStatus(CalendarEventStatus.NewEventSelected);
        })
      );
    },

    onEventClicked({
      nativeEvent,
      event,
    }: {
      nativeEvent: any;
      event: ICalendarEvent;
    }) {
      if (event.id !== 0) {
        if (this.type == "month") {
          if (event.tag.ownerId == this.groupId) {
            this.setSelectionStatus(CalendarEventStatus.None, true);
            this.$emit("event-selected", event.id);
          }
        } else {
          this.setSelectionStatus(CalendarEventStatus.None, true);

          this.newEventItem = new EventListItem();
          this.newEventItem.id = event.id;
          this.newEventItem.title = event.name;
          this.newEventItem.isOnNationalCalendar = true;
          this.newEventItem.startDateTimeDisplay = DateTime.fromISO(
            event.start
          ).toISO();
          this.newEventItem.endDateTimeDisplay = DateTime.fromISO(event.end)
            .plus({ hour: 1 })
            .toISO();

          if (this.selectionStatus === CalendarEventStatus.None) {
            this.openInfoPopup(nativeEvent, event);
          } else {
            this.setSelectionStatus(CalendarEventStatus.None, true);
            requestAnimationFrame(() =>
              requestAnimationFrame(() =>
                this.openInfoPopup(nativeEvent, event)
              )
            );
          }

          nativeEvent.stopPropagation();
        }
      }
    },

    onEventMouseEnter({
      nativeEvent,
      event,
    }: {
      nativeEvent: any;
      event: ICalendarEvent;
    }) {
      if (this.type == "month") {
        return;
      }
      if (event.id === 0) {
        if (this.selectionStatus === CalendarEventStatus.None) {
          this.openEditPopup(nativeEvent, event);
        } else {
          this.setSelectionStatus(CalendarEventStatus.None);
          requestAnimationFrame(() =>
            requestAnimationFrame(() => this.openEditPopup(nativeEvent, event))
          );
        }

        nativeEvent.stopPropagation();
      } else {
        this.setSelectionStatus(CalendarEventStatus.None, true);
      }
    },

    onEventStartDrag({ event, timed }: { event: ICalendarEvent; timed: any }) {
      // TODO: Removed ability to drag events.
      /*
      if (event && timed && event.id !== 0) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
        this.dragEventEndTime = event.end.toString();
      }
      */
    },

    onEventEndDrag() {
      // TODO: Removed ability to drag events.
      /*
      try {
        let data: EventListItem | undefined = undefined;

        // Handle the drag or extension of an event
        if (this.dragEvent) {
          data = this.dragEvent.tag;
          if (data) {
            data.startDateTime = this.getDateFromAny(this.dragEvent.start);
            data.endDateTime = this.getDateFromAny(this.dragEvent.end);
            data.startDateTimeDisplay = DateTime.fromJSDate(
              this.getDateFromAny(this.dragEvent.start)
            ).toISO();
            data.endDateTimeDisplay = DateTime.fromJSDate(
              this.getDateFromAny(this.dragEvent.end)
            ).toISO();
          }
        } else if (this.createEvent) {
          data = this.createEvent.tag;
          if (data) {
            data.startDateTime = this.getDateFromAny(this.createEvent.start);
            data.endDateTime = this.getDateFromAny(this.createEvent.end);
            data.startDateTimeDisplay = DateTime.fromJSDate(
              this.getDateFromAny(this.createEvent.start)
            ).toISO();
            data.endDateTimeDisplay = DateTime.fromJSDate(
              this.getDateFromAny(this.createEvent.end)
            ).toISO();
          }
        }

        // Update the basic properties of the event such as the start date and time
        // Check the drag times to ensure that we are not calling the API unless
        // we need to as a result of an actual drag or extension. A single click
        // should not invoke a call to the service.
        if (data && data.id !== 0) {
          if (data.endDateTime !== null) {
            let match = this.allEvents.find((f) => f.id === data?.id);
            if (match) {
              if (this.dragEventEndTime !== match.end.toString()) {
                data.ownerId = this.groupId;

                // Ensure that the start and end dates for a location have been
                // set in the case where the event is created from the calendar
                if (data.location) {
                  data.location.startDateTime = DateTime.fromISO(
                    data.startDateTimeDisplay
                  ).toJSDate();
                  data.location.endDateTime = DateTime.fromISO(
                    data.endDateTimeDisplay
                  ).toJSDate();
                }

                const service = new EventsService();
                service
                  .saveEventItem(data)
                  .then((response) => {
                    this.setSelectionStatus(CalendarEventStatus.None, true);
                    let match = this.allEvents.find(
                      (f) => f.id === response.data.id
                    );
                    if (match) {
                      const eStart = new Date(response.data.startDateTime);
                      const eEnd = new Date(response.data.endDateTime);
                      match.id = response.data.id;
                      match.name = response.data.isCancelled
                        ? `${response.data.title} - CANCELLED`
                        : response.data.title;
                      match.color = response.data.isCancelled
                        ? "red"
                        : "primary";
                      match.start = eStart;
                      match.end = eEnd;
                      match.rangeString = this.rangeString(eStart, eEnd);
                    }
                  })
                  .catch((error) => this.showErrorDialog(error));
              }
            }
          }
        }
      } finally {
        // Ensure that the variables are cleared regardless of any errors
        this.dragTime = null;
        this.dragEvent = null;
        this.createEvent = null;
        this.createStart = null;
        this.extendOriginal = null;
        this.dragEventEndTime = "";
      }
      */
    },

    onEventCancelDrag() {
      // TODO: Removed ability to drag events.
      /*
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }
      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
      this.dragEventEndTime = "";
      */
    },

    onExtendEventDate(event: ICalendarEvent | null) {
      if (event && event.id !== 0) {
        this.createEvent = event;
        if (event != null) {
          this.createStart = event.start;
          this.extendOriginal = event.end;
          this.dragEventEndTime = event.end.toString();
        }
      }
    },

    onCalendarMouseMove(tms: ICalendarStart) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragEvent.id !== 0 && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (
        this.createEvent &&
        this.createEvent.id !== 0 &&
        this.createStart !== null
      ) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },

    onCalendarMouseDown(tms: ICalendarStart) {
      const mouse = this.toTime(tms);
      if (this.dragEvent && this.dragEvent.id !== 0 && this.dragTime === null) {
        const start = this.dragEvent.start;
        this.dragTime = mouse - start;
      }
    },

    onCalendarDayClicked({ date }: { date: string }) {
      this.focus = date;
      this.onCalendarIntervalChanged("day");
    },

    onCalendarTodayButtonClicked() {
      this.focus = "";
      //  this.scrollIntoView();
    },

    onCalendarPrevIntervalClicked() {
      (this.$refs.calendar as any).prev();
      //  this.scrollIntoView();
    },

    onCalendarNextIntervalClicked() {
      (this.$refs.calendar as any).next();
      //  this.scrollIntoView();
    },

    onCalendarIntervalChanged(intervalName: string) {
      this.type = intervalName;
      this.weekdays = [1, 2, 3, 4, 5, 6, 0];

      switch (intervalName) {
        case "day":
          this.type = "day";
          this.interval = "Day";
          break;
        case "week":
          this.type = "week";
          this.interval = "Week";
          break;
        case "working-week":
          this.type = "week";
          this.interval = "Work Week";
          this.weekdays = [1, 2, 3, 4, 5];
          break;
        case "month":
          this.interval = "Month";
          break;
      }

      //this.scrollIntoView();
    },

    showFullEditScreen() {
      if (this.selectedEvent) {
        this.setSelectionStatus(CalendarEventStatus.None, true);
        this.$emit("event-selected", this.selectedEvent.id);
      }
    },

    onNewEvent() {
      let date = DateTime.now();
      let item: EventListItem = new EventListItem();

      item.title = "New Meeting";
      item.startDateTime = date.toJSDate();
      item.endDateTime = date.plus({ hour: 2 }).toJSDate();

      if (this.groupId !== 0) {
        item.ownerId = this.groupId;
      }
      this.saveEvent(item, true);
    },

    onMoreOptions(entity: EventListItem) {
      if (this.groupId !== 0) {
        entity.ownerId = this.groupId;
      }
      this.saveEvent(entity, true);
    },

    onEventSave(entity: EventListItem) {
      if (this.groupId !== 0) {
        entity.ownerId = this.groupId;
      }
      this.saveEvent(entity, false);
    },

    saveEvent(entity: EventListItem, launchDialog: boolean) {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Saving Event, Please Wait..."
      );

      entity.ownerId = this.groupId;

      // Ensure that the start and end dates for a location have been
      // set in the case where the event is created from the calendar
      if (entity.location) {
        entity.location.startDateTime = DateTime.fromISO(
          entity.startDateTimeDisplay
        ).toJSDate();
        entity.location.endDateTime = DateTime.fromISO(
          entity.endDateTimeDisplay
        ).toJSDate();
      }

      const service = new EventsService();
      service
        .saveEventItem(entity)
        .then((response) => {
          this.hideProgressIndicator();

          const eStart = new Date(response.data.startDateTime);
          const eEnd = new Date(response.data.endDateTime);

          let newEvent: ICalendarEvent = {
            id: response.data.id,
            start: eStart,
            end: eEnd,
            timed: true,
            rangeString: this.rangeString(eStart, eEnd),
            name: response.data.isCancelled
              ? `(CANCELLED) - ${response.data.title}`
              : response.data.isPublished
              ? response.data.title
              : `(UN-PUBLISHED) - ${response.data.title}`,
            color: response.data.isCancelled
              ? "red"
              : response.data.isPublished
              ? "primary"
              : "grey",
            summary: "",
            tag: response.data,
          };

          this.allEvents.push(newEvent);
          this.setSelectionStatus(CalendarEventStatus.None, true);
          this.selectedEvent = newEvent;

          if (launchDialog && this.selectedEvent) {
            this.$emit("event-selected", this.selectedEvent.id);
          }
        })
        .catch((error) => this.showErrorDialog(error));

      this.setSelectionStatus(CalendarEventStatus.None, true);
    },

    closeEventInfoDialog() {
      this.setSelectionStatus(CalendarEventStatus.None);
    },

    closeEventInfoEditDialog() {
      this.setSelectionStatus(CalendarEventStatus.None, true);
    },

    clearTemporaryEvents() {
      // Clean up the calendar by removing any temporary events
      const eventsToKeep = this.allEvents.filter((f) => f.id !== 0);
      if (eventsToKeep) {
        this.allEvents = eventsToKeep;
        this.refreshFilter();
      }
    },

    roundTime(time: number, down = true) {
      const roundTo = 15;
      const roundDownTime = roundTo * 60 * 1000;
      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },

    toTime(tms: ICalendarStart) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },

    cancelEvent() {
      this.showCancelConfirmationDialog();
    },

    showCancelConfirmationDialog() {
      this.dialogMessage = new DialogMessage(
        `Cancel ${this.selectedEvent?.name}?`,
        "Are you sure you want to CANCEL this event? Cancelling an event will result in it being listed as Cancelled and un-available."
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.errors = [];
      this.showCancelDialog = true;
    },

    onCancelDialogSelected(item: DialogResponse) {
      this.showCancelDialog = false;

      if (item.option == true && this.selectedEvent) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Cancelling Event, Please Wait..."
        );

        const service = new EventsService();
        service
          .cancelEvent(this.selectedEvent.tag.id!)
          .then(() => {
            if (this.selectedEvent) {
              this.hideProgressIndicator();
              this.closeEventInfoDialog();
              this.loadEvents();
            }
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    unCancelEvent() {
      if (this.selectedEvent) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Un-cancelling Event, Please Wait..."
        );

        const service = new EventsService();
        service
          .unCancelEvent(this.selectedEvent.tag.id!)
          .then(() => {
            if (this.selectedEvent) {
              this.hideProgressIndicator();
              this.closeEventInfoDialog();
              this.loadEvents();
            }
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    publishEvent() {
      if (this.selectedEvent) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Publishing Event, Please Wait..."
        );

        const service = new EventsService();
        service
          .publishEvent(this.selectedEvent.tag.id!)
          .then(() => {
            if (this.selectedEvent) {
              this.hideProgressIndicator();
              this.closeEventInfoDialog();
              this.loadEvents();
            }
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    unPublishEvent() {
      if (this.selectedEvent) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Un-publishing Event, Please Wait..."
        );

        const service = new EventsService();
        service
          .unPublishEvent(this.selectedEvent.tag.id!)
          .then(() => {
            if (this.selectedEvent) {
              this.hideProgressIndicator();
              this.closeEventInfoDialog();
              this.loadEvents();
            }
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    notifyEvent() {
      if (this.selectedEvent) {
        this.closeEventInfoDialog();
        this.notificationTitle = this.selectedEvent.name;
        this.notificationMessage = this.selectedEvent.summary;
        this.notificationEventId = this.selectedEvent.id;
        this.showNotificationDialog = true;
      }
    },

    onNotificationDialogClosed() {
      this.showNotificationDialog = false;
    },

    onCalendarViewTypeChange(option: number) {
      store.commit("setEventCalendarViewType", option);
      this.isCalendarView = option == 0;
      if (this.isCalendarView) {
        this.onCalendarIntervalChanged("week");
      } else {
        this.onCalendarIntervalChanged("month");
      }
    },

    onSideCalendarDateSelected(input: string) {
      this.focus = input;
    },

    refreshGridFilter(dateToEvaluate: string) {
      // Ensure the side calendar is in sync with the main one
      this.sideCalendarSelectedDate = this.focus;

      // Determine the date to evaluate so that we can extract the month
      const date = dateToEvaluate
        ? DateTime.fromISO(dateToEvaluate)
        : DateTime.now();

      // Extract the start and end of the month
      const startOfMonth = date.startOf("month");
      const endOfMonth = date.endOf("month");

      // Filter the data so that it reflects the selected month
      this.eventData = [];
      for (var item of this.allEventData) {
        if (
          item.ownerId == this.groupId ||
          item.isOnNationalCalendar == this.includeNationalCalendarEvents
        ) {
          var start = DateTime.fromJSDate(new Date(item.startDateTime));
          var end = DateTime.fromJSDate(new Date(item.endDateTime));
          if (start >= startOfMonth && end <= endOfMonth) {
            this.eventData.push(item);
          }
        }
      }
    },

    getAddress(item: EventListItem) {
      if (item.address) {
        return item.address;
      } else {
        return item.location?.address;
      }
    },

    onEditEventItem(item: EventListItem) {
      this.selectedEvent = this.convertToICalendarEvent(item);
      this.showFullEditScreen();
    },

    onDeleteEventItem(item: EventListItem) {
      this.selectedEvent = this.convertToICalendarEvent(item);
      this.deleteEvent();
    },

    onCancelEventItem(item: EventListItem) {
      this.selectedEvent = this.convertToICalendarEvent(item);
      this.cancelEvent();
    },

    onUnCancelEventItem(item: EventListItem) {
      this.selectedEvent = this.convertToICalendarEvent(item);
      this.unCancelEvent();
    },

    onPublishEventItem(item: EventListItem) {
      this.selectedEvent = this.convertToICalendarEvent(item);
      this.publishEvent();
    },

    onUnPublishEventItem(item: EventListItem) {
      this.selectedEvent = this.convertToICalendarEvent(item);
      this.unPublishEvent();
    },

    onNotifyEventItem(item: EventListItem) {
      this.selectedEvent = this.convertToICalendarEvent(item);
      this.notifyEvent();
    },

    disableEventToolBar(item: EventListItem): Boolean {
      return item.ownerId !== this.groupId;
    },

    convertToICalendarEvent(item: EventListItem): ICalendarEvent {
      const eStart = new Date(item.startDateTime);
      const eEnd = new Date(item.endDateTime);

      // Ensure that the selected event is the item selected in the grid and sync's with the calendar
      var calendarEvent: ICalendarEvent = {
        id: item.id,
        start: eStart,
        end: eEnd,
        timed: true,
        rangeString: this.rangeString(eStart, eEnd),
        name: item.isCancelled
          ? `(CANCELLED) - ${item.title}`
          : item.isPublished
          ? item.title
          : `(UN-PUBLISHED) - ${item.title}`,
        color: item.isCancelled ? "red" : item.isPublished ? "primary" : "grey",
        summary: "",
        tag: item,
      };

      return calendarEvent;
    },
  },
});
</script>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
</style>