import Vue from "vue";
import confirmationDialog from "@/components/common/confirmation-dialog.vue";
import progressIndicator from "@/components/common/progress-indicator.vue";
import actionAlert from "@/components/common/action-alert.vue";
import { DialogMessage, IProblemDetails, ValidationError } from "@/models/common.model";
import { AlertType, LoadingType } from "@/models/common.model";
import { DateTime } from "luxon";
import { formatNumber, isValidPhoneNumber } from 'libphonenumber-js';
import store from "@/store";

export default Vue.extend({
  name: "CommonMixin",
  components: {
    confirmationDialog,
    progressIndicator,
    actionAlert
  },
  data() {
    return {
      showDialog: false,
      dialogMessage: new DialogMessage("", ""),
      dialogErrors: null,
      showProgressBar: false,
      showSpinner: false,
      progressMessage: "Fetching data, Please Wait...",
      showAlert: false,
      showingAlertIndicator: false,
      alertMessage: 'The information has been saved',
      alertType: 'success',
      rules: {
        required: (value: any) => !!value || "You must provide a value.",
        email: (value: any) => {
          if (!value)
            return true;
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        phone: (value: string) => {
          if (value) {
            return isValidPhoneNumber(value, 'GB') === true || 'Not a valid phone number';
          } else {
            return true;
          }
        },
        url: (value: string) => {
          if (!value)
            return true;

          const regex = RegExp(
            "(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*(\\?[;&a-z\\d%_.~+=-@]*)?(\\#[-a-z\\d_@]*)?$",
            "i"
          );
          return regex.test(value) || "Invalid web address";
        },
        isNumber: (value: string) => {
          return !isNaN(Number(value)) || "Please enter a number";
        },
        attachmentSize: (values: File[]) => {
          let totalSize: number = 0;
          for (let item of values) {
            totalSize += item.size;
          }
          return (totalSize < 15000000) || 'Total size cannot be more than 15 MB!'
        }
      },
    };
  },
  methods: {
    showExportedFile(response: any, defaultFileName: string = "export-data.csv") {

      let fileName = defaultFileName;

      // Extract the filename from the content-disposition header
      const contentDisposition: string =
        response.headers["content-disposition"];
      
      if (contentDisposition) {
        let elements = contentDisposition.split(";");
        if (elements.length > 0) {
          for (var element of elements) {
            let elementItem = element.trim();
            if (elementItem.startsWith("filename")) {
              fileName = elementItem.split("=")[1];
              break;
            }
          }
        }
      }

      // It is necessary to create a new blob object with mime-type
      // explicitly set otherwise only Chrome works like it should
      let newBlob = new Blob([response.data], { type: "text/csv" });

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      let url = window.URL.createObjectURL(newBlob);
      let link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();

      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(url);
      }, 100);
    },

    formatCurrency(value: number): string {
      return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(value);
    },
    formatDateMin(value: Date) {
      if (value) {
        return DateTime.fromISO(value.toString()).toFormat("d LLL yyyy");
      }
    },
    formatDate(value: Date) {
      if (value) {
        return DateTime.fromISO(value.toString()).toFormat("d LLLL yyyy");
      }
    },
    formatDateTime(value: Date) {
      if (value) {
        return DateTime.fromISO(value.toString()).toLocaleString(DateTime.DATETIME_MED);
      }
    },
    formatNumber(value: number, numberOfDigits: number): string {
      return new Intl.NumberFormat('en-GB', { minimumIntegerDigits: numberOfDigits }).format(value);
    },
    getTabIndex(): number {
      return store.state.formTab;
    },
    setTabIndex(index: number) {
      store.commit("setFormTab", index);
    },
    updateNavTitle(title: string) {
      store.commit("setTitle", title);
    },

    getNavTitle(): string {
      return store.state.title;
    },

    setNewDialogMessage(title: string, message: string, showCancel: boolean, entity?: any, errors?: ValidationError[]) {
      this.dialogMessage = new DialogMessage(title, message);
      this.dialogMessage.showCancel = showCancel;
      this.dialogMessage.entity = entity;
      this.dialogMessage.errors = errors;
    },

    showAlertPanel(message: string, type: string = AlertType.Success) {
      this.hideProgressIndicator();
      if (!this.showingAlertIndicator) {
        this.showingAlertIndicator = true;
        this.showAlert = false;
        this.alertMessage = message;
        this.alertType = type;
        this.showAlert = true;
        window.setTimeout(() => {
          this.showAlert = false;
          this.showingAlertIndicator = false;
        }, 2000);
      }
    },

    hideAlertPanel() {
      this.hideProgressIndicator();
      this.showAlert = false;
    },

    showConfirmationDialog(title: string, message: string) {
      this.hideProgressIndicator();
      this.dialogMessage = new DialogMessage(title, message);
      this.showDialog = true;
    },

    showErrorDialog(error: any) {
      this.hideProgressIndicator();
      if (error.response && error.response.data) {
        const data = error.response.data as IProblemDetails;

        // Set the base dialog message properties from the ProblemDetail response
        this.dialogMessage = new DialogMessage(
          data.title ?? "Error: Something has gone wrong!",
          data.detail ??
          "We are unable to determine the problem, please try again later.");

        // If we have multiple errors then build up a list for display
        if (data.errors) {
          this.dialogMessage.errors = [];
          let id: number = 1;
          let errors: ValidationError[] = [];
          for (let key in data.errors) {
            for (let error of data.errors[key]) {
              let vError = new ValidationError(id, key, error.isWarning, error.message);
              errors.push(vError);
              id = id + 1;
            }
          }
          if (errors.length == 1) {
            this.dialogMessage.message = errors[0].message;
          } else {
            this.dialogMessage.errors.push(...errors);
          }
        }

      } else {
        this.dialogMessage = new DialogMessage(
          "Error: Something has gone wrong!",
          "We are unable to determine the problem, please try again later."
        );
      }

      this.showDialog = true;
    },

    onDialogOptionSelected() {
      this.showDialog = false;
    },

    showProgressIndicator(
      loadingType: number = LoadingType.Panel,
      message = "Fetching data, Please Wait..."
    ) {
      this.hideProgressIndicator();
      if (loadingType === LoadingType.ProgressBar) {
        this.showProgressBar = true;
      } else if (loadingType === LoadingType.Panel) {
        this.showSpinner = true;
      }
      this.progressMessage = message;
    },

    hideProgressIndicator() {
      this.showProgressBar = false;
      this.showSpinner = false;
    },

    isEmail(value: string): boolean {
      if (!value)
        return true;
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value);
    },

    isNumber(value: string): boolean {
      return value ? !isNaN(Number(value)) : false;
    },

    isNotNumber(value: string): boolean {
      return value ? isNaN(Number(value)) : true;
    },

    isUrl(value: string): boolean {
      if (!value)
        return true;

      const regex = RegExp(
        "(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*(\\?[;&a-z\\d%_.~+=-@]*)?(\\#[-a-z\\d_@]*)?$",
        "i"
      );
      return regex.test(value);
    },

    isEmptyString(value: string): boolean {
      return (
        value === null ||
        value === undefined ||
        (typeof value === "string" && value.trim().length === 0)
      );
    },

    limitText(text: string, numberOfChars: number) {
      return text.length > numberOfChars
        ? `${text.substring(0, numberOfChars)}...`
        : text;
    },

    getDateFromAny(value: any): Date {
      const stringValue = value.toString();
      return this.isNotNumber(stringValue)
        ? DateTime.fromISO(stringValue).toUTC().toJSDate()
        : DateTime.fromMillis(value).toUTC().toJSDate()
    },

    toNearestHour(currentHour: number, currentMinute: number): number {
      const hour = (((currentMinute / 105 + 0.5) | 0) + currentHour) % 24;
      return hour;
    },

    toNearestQuarterMinute(currentMinute: number): number {
      const min = ((((currentMinute + 7.5) / 15) | 0) * 15) % 60;
      return min;
    },

    ageInYears(dateOfBirth: Date | null): number | null {
      if (dateOfBirth) {
        return Math.floor(DateTime.utc().diff(DateTime.fromISO(dateOfBirth.toString()), ["years"]).years);
      } else {
        return null;
      }
    },

    rangeString(start: Date, end: Date): string {
      const eStart: DateTime = DateTime.fromJSDate(start);
      const eEnd: DateTime = DateTime.fromJSDate(end);

      const onSameDay: boolean = eStart.hasSame(eEnd, "day");
      const rStr = onSameDay
        ? `${eStart.toLocaleString(DateTime.DATETIME_MED)} - ${eEnd.toLocaleString(DateTime.TIME_SIMPLE)}`
        : `${eStart.toLocaleString(DateTime.DATETIME_MED)} - ${eEnd.toLocaleString(DateTime.DATETIME_MED)}`;

      return rStr;
    },

    getMailLink(email: string): string {
      return `mailto:${email}`;
    },

    getPhoneLink(contactNumber: string): string {
      return `tel:${contactNumber}`;
    },

    validateStartDateRange(start: string | null, end: string | null): string[] {
      let err: string[] = [];

      if (start && !end) {
        err.push("You must enter an end date.");
      } else if (start && end) {
        let st = DateTime.fromISO(start);
        let ed = DateTime.fromISO(end);

        let difference = st.diff(ed, "days").days;
        if (difference > 0) {
          err.push("Start is after end date.");
        }
      }

      return err;
    },

    validateEndDateRange(start: string | null, end: string | null): string[] {
      let err: string[] = [];

      if (!start && end) {
        err.push("You must enter a start date");
      }

      return err;
    },

  },
});
