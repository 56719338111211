<template>
  <v-container fluid>
    <v-row>
      <v-col lg="4">
        <v-btn color="success" @click="onNewItem">New Person</v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-2"
              icon
              outlined
              color="error"
              v-bind="attrs"
              v-on="on"
              @click="onDeleteSelected"
              :disabled="!hasSelectedItems"
              ><v-icon>mdi-delete-sweep-outline</v-icon></v-btn
            >
          </template>
          <span>Remove Selected</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-2"
              icon
              outlined
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="onImportButtonClicked"
              ><v-icon>mdi-cloud-upload</v-icon></v-btn
            >
          </template>
          <span>Import</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-2"
              icon
              outlined
              color="primary"
              v-bind="attrs"
              v-on="on"
              :disabled="!canExportItems"
              @click="onDownloadButtonClicked"
              ><v-icon>mdi-cloud-download</v-icon></v-btn
            >
          </template>
          <span>Export</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-2"
              icon
              outlined
              :color="filterButtonColor"
              v-bind="attrs"
              v-on="on"
              @click="onFilterClicked"
              ><v-icon>{{ filterButtonIcon }}</v-icon></v-btn
            >
          </template>
          <span>Click here to manage your filters.</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-2"
              icon
              outlined
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="onFilterRemoveClicked"
              :disabled="!hasFilters"
              ><v-icon>mdi-filter-off</v-icon></v-btn
            >
          </template>
          <span>Click here to remove any filters that are applied.</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-2"
              icon
              outlined
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="onMessageSelected"
              :disabled="!hasSelectedAndContactableItems"
              ><v-icon>mdi-email-outline</v-icon></v-btn
            >
          </template>
          <span>Send message (email, app, sms)</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-2"
              icon
              outlined
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="onStandardReportExport"
              :disabled="!canExportItems"
              ><v-icon>mdi-chart-pie</v-icon></v-btn
            >
          </template>
          <span>Report Export</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-show="showEmptyView" elevation="0">
          <v-row>
            <v-col md="4">
              It seems that you have not created any records yet (or no members
              match your filters). Create your first one by clicking the New
              Person.
            </v-col>
            <v-col md="3">
              <lottie-player
                src="https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json"
                background="transparent"
                speed="1"
                style="width: 400px; height: 400px"
                loop
                autoplay
              ></lottie-player>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-show="showGrid">
          <v-card-title>
            <v-row>
              <v-col md="auto">
                <v-select
                  :items="allFilters"
                  v-model="filter"
                  placeholder="Congregation"
                  label="Congregation"
                  hide-details="auto"
                  item-text="title"
                  return-object
                  outlined
                  @change="onFilterChanged"
                >
                </v-select
              ></v-col>
              <v-spacer></v-spacer>
              <v-col md="2">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  clearable
                  hide-details
                ></v-text-field>
              </v-col>

              <v-btn color="success" @click="onShowSelectColumnsDialog"
                >Select Columns</v-btn
              >
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :items-per-page="15"
              :headers="headers"
              :items="items"
              :search="search"
              :single-select="false"
              item-key="id"
              :show-select="showMultiSelect"
              :sort-by="[]"
              :sort-desc="[]"
              multi-sort
              v-model="selectedItems"
              fixed-header
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-row>
                  <v-col>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="error"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="onDeleteItem(item)"
                        >
                          <v-icon> mdi-delete </v-icon>
                        </v-btn>
                      </template>
                      <span>Click here to remove</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="onEditItem(item)"
                        >
                          <v-icon> mdi-file-document-edit-outline </v-icon>
                        </v-btn>
                      </template>
                      <span>Click here to edit</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :href="getMailLink(item.email)"
                          color="primary"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          :disabled="isEmptyString(item.email)"
                        >
                          <v-icon> mdi-email-outline </v-icon>
                        </v-btn>
                      </template>
                      <span>Click here to send an email</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`item.imageUrl`]="{ item }">
                <div class="container">
                  <img :src="item.imageUrl" />
                </div>
              </template>
              <template v-slot:[`item.email`]="{ item }">
                <a :href="getMailLink(item.email)">{{ item.email }}</a>
              </template>
              <template v-slot:[`item.webSiteUrl`]="{ item }">
                <a :href="item.webSiteUrl" target="_blank">{{
                  item.webSiteUrl
                }}</a>
              </template>
              <template v-slot:[`item.primaryContactNumber`]="{ item }">
                <a
                  :href="getPhoneLink(item.primaryContactNumber)"
                  target="_blank"
                  >{{ item.contactNumber }}</a
                >
              </template>
              <template v-slot:[`item.contactable`]="{ item }">
                <v-simple-checkbox
                  v-model="item.contactable"
                  disabled
                ></v-simple-checkbox>
              </template>
              <template v-slot:[`item.isMember`]="{ item }">
                <v-simple-checkbox
                  v-model="item.isMember"
                  disabled
                ></v-simple-checkbox>
              </template>
              <template v-slot:[`item.isVisitor`]="{ item }">
                <v-simple-checkbox
                  v-model="item.isVisitor"
                  disabled
                ></v-simple-checkbox>
              </template>
              <template v-slot:[`item.isActive`]="{ item }">
                <v-simple-checkbox
                  v-model="item.isActive"
                  disabled
                ></v-simple-checkbox>
              </template>
              <template v-slot:[`item.isOnline`]="{ item }">
                <v-simple-checkbox
                  v-model="item.isOnline"
                  disabled
                ></v-simple-checkbox>
              </template>
              <template v-slot:[`item.hasPassed`]="{ item }">
                <v-simple-checkbox
                  v-model="item.hasPassed"
                  disabled
                ></v-simple-checkbox>
              </template>
              <template v-slot:[`item.dateOfBirth`]="{ item }">
                {{ formatDateMin(item.dateOfBirth) }}
              </template>
              <template v-slot:[`item.dateOfBaptism`]="{ item }">
                {{ formatDateMin(item.dateOfBaptism) }}
              </template>
              <template v-slot:[`item.fellowshipDate`]="{ item }">
                {{ formatDateMin(item.fellowshipDate) }}
              </template>
              <template v-slot:[`item.resignationDate`]="{ item }">
                {{ formatDateMin(item.resignationDate) }}
              </template>
              <template v-slot:[`item.weddingDate`]="{ item }">
                {{ formatDateMin(item.weddingDate) }}
              </template>
              <template v-slot:[`item.datePassed`]="{ item }">
                {{ formatDateMin(item.datePassed) }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <input
      ref="csvReader"
      class="d-none"
      type="file"
      @change="onImport"
      accept=".csv"
    />
    <confirmation-dialog
      :dialog="showDeleteDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDeleteAllDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDeleteAllOptionSelected"
    ></confirmation-dialog>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <registered-user-filter-dialog
      :dialog="showFilterDialog"
      :data="dialogMessage"
      :canViewSensitiveData="canViewSensitiveData"
      :filterId="filter.id"
      @dialog-option-selected="onFilterDialogOptionSelected"
      @reset-filter="onDialogFilterResetRequest"
    ></registered-user-filter-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <multi-item-selection-dialog
      :dialog="showSelectColumnsDialog"
      :data="dialogMessage"
      :items="allColumns"
      :defaultItems="defaultColumns"
      :selectedItems="selectedColumns"
      @dialog-option-selected="onSelectColumnsDialogOptionSelected"
    >
    </multi-item-selection-dialog>
    <message-sender-dialog
      :groupId="groupId"
      :dialog="showMessageSenderDialog"
      :data="sendMessageViewModel"
      @dialog-closed="onMessageSenderDialogClosed"
    >
    </message-sender-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import {
  GroupType,
  IParsedData,
  ISendMessageViewModel,
  LoadingType,
  MultiItemSelectionItem,
  StateKeyName,
  UserFilterName,
} from "@/models/common.model";
import { DialogMessage, DialogResponse } from "@/models/common.model";
import {
  IUserListItem,
  PermissionArea,
  PermissionsManager,
  PermissionType,
} from "@/models/security.model";
import { GroupService } from "@/services/group.service";
import { IImportExportMember, IImportViewModel } from "@/models/group.model";
import { PeopleSearchFilter } from "@/models/people.model";
import RegisteredUserFilterDialog from "@/views/dialogs/registered-user-filter-dialog.vue";
import multiItemSelectionDialog from "@/components/common/multi-item-selection-dialog.vue";
import { RegisteredUserService } from "@/services/registered-user.service";
import { UserFilterColumnName } from "@/models/people.model";
import Papa from "papaparse";
import store from "@/store";
import MessageSenderDialog from "@/components/common/message-sender-dialog.vue";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  components: {
    "registered-user-filter-dialog": RegisteredUserFilterDialog,
    "multi-item-selection-dialog": multiItemSelectionDialog,
    "message-sender-dialog": MessageSenderDialog,
  },
  name: "ListPeople",
  mixins: [CommonMixin],

  computed: {
    hasSelectedItems(): boolean {
      return this.selectedItems.length > 0;
    },
    canExportItems(): boolean {
      return this.items.length > 0;
    },
    hasFilters(): boolean {
      return this.checkHasFilter();
    },
    hasSelectedAndContactableItems(): boolean {
      let matches = this.selectedItems.filter((f) => f.isContactableByApp || f.isContactableByEmail || f.isContactableBySms);
      return matches.length > 0;
    }
  },

  data() {
    return {
      showDeleteDialog: false,
      showDeleteAllDialog: false,
      showFilterDialog: false,
      showSelectColumnsDialog: false,
      showMessageSenderDialog: false,
      search: "",
      headers: [] as any,
      items: Array<IUserListItem>(),
      selectedItems: Array<IUserListItem>(),
      showMultiSelect: true,
      actionWidth: 4,
      showGrid: false,
      showEmptyView: false,
      groupId: 0,
      attachments: Array<File>(),
      filter: new PeopleSearchFilter(),
      groupType: GroupType.Branch,
      canViewSensitiveData: false,
      allColumns: Array<MultiItemSelectionItem>(),
      defaultColumns: Array<MultiItemSelectionItem>(),
      selectedColumns: Array<MultiItemSelectionItem>(),
      filterButtonColor: "primary",
      filterButtonIcon: "mdi-filter",
      allFilters: [] as PeopleSearchFilter[],
      sendMessageViewModel: {} as ISendMessageViewModel,
    };
  },

  async created() {
    this.allColumns = [];
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.firstName)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.lastName)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.contactable)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.email)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.dateOfBirth)
    );
    this.allColumns.push(new MultiItemSelectionItem(UserFilterColumnName.age));
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.gender)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.member)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.visitor)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.active)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.online)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.image)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.maritalStatus)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.ethnicity)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.nationality)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.profession)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.baptismDate)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.fellowshipDate)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.weddingDate)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.hasPassed)
    );
    this.allColumns.push(
      new MultiItemSelectionItem(UserFilterColumnName.datePassed)
    );

    this.defaultColumns = [];
    this.selectedColumns = [];

    this.refreshFilterList();

    // Refresh the columns so that they reflect those of this filter
    this.getAndShowColumns(this.filter.id);
  },

  methods: {
    checkHasFilter(): boolean {
      let filtersOn: boolean =
        this.filter.maritalStatus.length > 0 ||
        this.filter.ethnicity.length > 0 ||
        this.filter.profession.length > 0 ||
        this.filter.nationality.length > 0 ||
        this.filter.gender.length > 0 ||
        this.filter.contactable !== null ||
        this.filter.ageStart !== null ||
        this.filter.ageEnd !== null ||
        this.filter.dateOfBaptismStart !== null ||
        this.filter.dateOfBaptismEnd !== null ||
        this.filter.fellowshipDateStart !== null ||
        this.filter.fellowshipDateEnd !== null ||
        this.filter.resignationDateStart !== null ||
        this.filter.resignationDateEnd !== null ||
        this.filter.weddingDateStart != null ||
        this.filter.weddingDateEnd !== null ||
        this.filter.datePassedStart !== null ||
        this.filter.datePassedEnd !== null ||
        this.filter.attributes.length > 0 ||
        this.filter.currentStatus.length > 0 ||
        this.filter.groupId !== 0;

      // Do not show the filter indicator when it's set to all by default
      if (this.filter.title == UserFilterName.All) {
        filtersOn = false;
      }

      this.filterButtonColor = filtersOn ? "success" : "primary";
      this.filterButtonIcon = filtersOn
        ? "mdi-filter-check-outline"
        : "mdi-filter";
      return filtersOn;
    },

    onImportButtonClicked() {
      let ref: any = this.$refs.csvReader;
      if (ref) {
        ref.click();
      }
    },

    onDownloadButtonClicked() {
      this.showProgressIndicator(
        LoadingType.Panel,
        `Exporting, Please Wait...`
      );

      const service = new GroupService();
      service
        .exportMembership(this.groupId, this.filter.id)
        .then((response) => {
          this.hideProgressIndicator();
          this.showExportedFile(response, "export-members.csv");
        })
        .catch((error) => this.showErrorDialog(error));
    },

    onStandardReportExport() {
      this.showProgressIndicator(
        LoadingType.Panel,
        `Exporting, Please Wait...`
      );

      const service = new GroupService();
      service
        .exportReport(this.groupId)
        .then((response) => {
          this.hideProgressIndicator();
          this.showExportedFile(response, "export-agm-report.csv");
        })
        .catch((error) => this.showErrorDialog(error));
    },

    onNewItem() {
      this.$emit("new-item-requested");
    },

    onDialogFilterResetRequest() {
      this.showFilterDialog = false;
      this.refreshFilterList();
      this.removeFilter();
    },

    onFilterRemoveClicked() {
      this.removeFilter();
    },

    removeFilter() {
      try {
        let match = this.allFilters.find((f) => f.title == UserFilterName.All);
        if (match) {
          this.filter = match;
        } else {
          this.filter = new PeopleSearchFilter();
        }
        store.commit(StateKeyName.SetPeopleSearchFilterId, this.filter.id);

        this.getItems(this.groupId);
      } catch (error) {
        this.showErrorDialog(error);
      }
    },

    onFilterClicked() {
      this.showFilterSelectionDialog();
    },

    showFilterSelectionDialog() {
      this.hideProgressIndicator();
      this.dialogMessage = new DialogMessage(
        "People Filter",
        "Select from one or more of the options to filter the results."
      );
      this.showFilterDialog = true;
    },

    async onFilterDialogOptionSelected(item: DialogResponse) {
      this.showFilterDialog = false;
      if (item.option) {
        this.filter = item.entity as PeopleSearchFilter;
        store.commit(StateKeyName.SetPeopleSearchFilterId, this.filter.id);
        this.refreshFilterList();
        this.getItems(this.groupId);
      }
    },

    onFilterChanged() {
      store.commit(StateKeyName.SetPeopleSearchFilterId, this.filter.id);
      this.getItems(this.groupId);
    },

    async onShowSelectColumnsDialog() {
      this.hideProgressIndicator();
      this.dialogMessage = new DialogMessage(
        "Select Columns",
        "Select one or more columns to include in the grid."
      );
      this.showSelectColumnsDialog = true;
    },

    async onSelectColumnsDialogOptionSelected(item: DialogResponse) {
      this.showSelectColumnsDialog = false;
      if (item.option) {
        this.selectedColumns = item.entity as MultiItemSelectionItem[];

        let tmpColumns: string[] = [];
        for (let item of this.selectedColumns) {
          tmpColumns.push(item.option);
        }

        try {
          const service = new RegisteredUserService();
          const saveResponse = await service.saveColumns(
            this.filter.id,
            tmpColumns
          );
          if (saveResponse) {
            this.showSelectedColumns();
          }
        } catch (error) {
          this.showErrorDialog(error);
        }
      }
    },

    showSelectedColumns() {
      this.headers = [];
      this.headers.push({
        text: "Actions",
        value: "actions",
        sortable: false,
        width: 120,
      });
      this.showHideColumn(
        UserFilterColumnName.image,
        UserFilterColumnName.image,
        "imageUrl",
        false
      );
      this.showHideColumn(
        UserFilterColumnName.member,
        UserFilterColumnName.member,
        "isMember",
        true,
        "80px"
      );
      this.showHideColumn(
        UserFilterColumnName.visitor,
        UserFilterColumnName.visitor,
        "isVisitor",
        true,
        "80px"
      );
      this.showHideColumn(
        UserFilterColumnName.active,
        UserFilterColumnName.active,
        "isActive",
        true,
        "80px"
      );
      this.showHideColumn(
        UserFilterColumnName.online,
        UserFilterColumnName.online,
        "isOnline",
        true,
        "100px"
      );
      this.showHideColumn(
        UserFilterColumnName.contactable,
        UserFilterColumnName.contactable,
        "contactable",
        true,
        "100px"
      );
      this.showHideColumn(
        UserFilterColumnName.lastName,
        UserFilterColumnName.lastName,
        "lastName",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.firstName,
        UserFilterColumnName.firstName,
        "firstName",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.gender,
        UserFilterColumnName.gender,
        "gender",
        true,
        "120px"
      );
      this.showHideColumn(
        UserFilterColumnName.dateOfBirth,
        UserFilterColumnName.dateOfBirth,
        "dateOfBirth",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.age,
        UserFilterColumnName.age,
        "age",
        true,
        "100px"
      );
      this.showHideColumn(
        UserFilterColumnName.email,
        UserFilterColumnName.email,
        "email",
        true,
        "180px"
      );
      this.showHideColumn(
        UserFilterColumnName.maritalStatus,
        UserFilterColumnName.maritalStatus,
        "maritalStatus",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.ethnicity,
        UserFilterColumnName.ethnicity,
        "ethnicity",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.nationality,
        UserFilterColumnName.nationality,
        "nationality",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.profession,
        UserFilterColumnName.profession,
        "profession",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.baptismDate,
        UserFilterColumnName.baptismDate,
        "dateOfBaptism",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.fellowshipDate,
        UserFilterColumnName.fellowshipDate,
        "fellowshipDate",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.weddingDate,
        UserFilterColumnName.weddingDate,
        "weddingDate",
        true,
        "140px"
      );
      this.showHideColumn(
        UserFilterColumnName.hasPassed,
        UserFilterColumnName.hasPassed,
        "hasPassed",
        true,
        "100px"
      );
      this.showHideColumn(
        UserFilterColumnName.datePassed,
        UserFilterColumnName.datePassed,
        "datePassed",
        true,
        "140px"
      );
    },

    showHideColumn(
      columnName: string,
      header: string,
      value: string,
      sortable: boolean = true,
      width: string = ""
    ) {
      if (this.isSelectedColumn(columnName)) {
        this.headers.push({
          text: header,
          value: value,
          sortable: sortable,
          width: width,
        });
      }
    },

    isSelectedColumn(columnName: string): boolean {
      const index = this.selectedColumns.findIndex(
        (r) => r.option == columnName
      );
      return index != -1;
    },

    onEditItem(item: IUserListItem) {
      this.$emit("show-item", item.id);
    },

    onDeleteItem(item: IUserListItem) {
      this.showDeleteConfirmationDialog(item);
    },

    onDeleteSelected() {
      this.showDeleteAllConfirmationDialog();
    },

    getItems(ownerId: number) {
      if (this.groupId !== ownerId) {
        this.groupId = ownerId;
        this.refreshFilterList();
      }
      if (
        this.filter.id == 0 &&
        this.filter.id != store.state.peopleSearchFilterId
      ) {
        let match = this.allFilters.find(
          (f) => f.id == store.state.peopleSearchFilterId
        );
        if (match) {
          this.filter = match;
        } else {
          match = this.allFilters.find((f) => f.title == UserFilterName.All);
          if (match) {
            this.filter = match;
          } else {
            this.filter.id = store.state.peopleSearchFilterId;
          }
        }
      }

      this.groupId = ownerId;
      this.showGrid = true;
      this.showEmptyView = false;

      this.showProgressIndicator(
        LoadingType.Panel,
        `Getting Attendees, Please Wait...`
      );

      this.canViewSensitiveData = PermissionsManager.hasPermission(
        this.groupId,
        null,
        this.groupType,
        PermissionType.Sensitive,
        PermissionArea.Membership
      );

      const service = new GroupService();
      service
        .getGroupMembers(this.groupId, this.filter.id, this.filter.groupId)
        .then((response) => {
          this.hideProgressIndicator();
          for (let item of response.data) {
            if (!item.imageUrl) {
              item.imageUrl = require("@/assets/images/COG7-logo.png");
            }
            item.age = this.ageInYears(item.dateOfBirth);
          }
          this.items = response.data;
          this.selectedItems = [];
          this.generateNames(this.items);
          this.actionWidth = this.showMultiSelect ? 5 : 4;
          this.showGrid = response.data.length > 0;
          this.showEmptyView = response.data.length == 0;

          // Refresh the columns so that they reflect those of this filter
          this.getAndShowColumns(this.filter.id);
        })
        .catch((error) => this.showErrorDialog(error));
    },

    showDeleteAllConfirmationDialog() {
      this.dialogMessage = new DialogMessage(
        `Remove the selected people from this group?`,
        `This action WILL REMOVE THESE ${this.selectedItems.length} PEOPLE FROM THIS GROUP BUT NOT THE APPLICATION if they have records in other groups. Are you sure you want to remove these people from the group?`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = this.selectedItems;
      this.dialogMessage.errors = [];
      this.showDeleteAllDialog = true;
    },

    onDeleteAllOptionSelected(item: DialogResponse) {
      this.showDeleteAllDialog = false;
      if (item.option) {
        let memberIds: number[] = item.entity.map((f: IUserListItem) => f.id);
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing people, Please Wait..."
        );
        const service = new GroupService();
        service
          .removeGroupMembers(this.groupId, memberIds)
          .then(() => {
            this.hideProgressIndicator();
            for (let memberId of memberIds) {
              let index = this.items.findIndex((f) => f.id == memberId);
              if (index !== -1) {
                this.items.splice(index, 1);
              }
            }
            this.showGrid = this.items.length > 0;
            this.showEmptyView = this.items.length == 0;
            this.selectedItems = [];
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    showDeleteConfirmationDialog(item: IUserListItem) {
      this.dialogMessage = new DialogMessage(
        `Delete ${item.firstName} ${item.lastName}?`,
        `Are you sure you want to delete ${item.firstName} ${item.lastName}?`
      );
      this.dialogMessage.showCancel = true;
      this.dialogMessage.entity = item;
      this.dialogMessage.errors = [];
      this.showDeleteDialog = true;
    },

    onDeleteOptionSelected(item: DialogResponse) {
      this.showDeleteDialog = false;
      if (item.option) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Removing Attendee, Please Wait..."
        );
        const service = new GroupService();
        service
          .removeGroupMember(this.groupId, item.entity.id)
          .then(() => {
            this.hideProgressIndicator();
            let index = this.items.findIndex((f) => f.id == item.entity.id);
            if (index !== -1) {
              this.items.splice(index, 1);
            }
            this.showGrid = this.items.length > 0;
            this.showEmptyView = this.items.length == 0;
          })
          .catch((error) => this.showErrorDialog(error));
      }
    },

    onImport($event: any) {
      let text = [];
      let files = $event.srcElement.files;

      if (this.isCSVFile(files[0])) {
        let input = $event.target;
        let reader = new FileReader();
        reader.readAsText(input.files[0]);

        reader.onload = () => {
          let csvData = reader.result;

          let parsed: any = Papa.parse(<string>csvData, {
            header: false,
            skipEmptyLines: true,
            delimiter: ",",
          });

          if (parsed.errors.length > 0) {
            this.showConfirmationDialog(
              "Invalid File Format",
              "The format of the file you have chosen to import is not valid. Are the headers correct?"
            );
          } else {
            let headersRow = this.getHeaderArray(parsed.data[0].toString());
            if (!this.isValidHeaders(headersRow)) {
              this.showConfirmationDialog(
                "Invalid File Format",
                "The format of the file you have chosen to import is not valid. Are the headers correct?"
              );
            } else {
              let records = this.getDataRecordsArrayFromCSVFile(
                parsed.data,
                headersRow.length
              );

              let importViewModel: IImportViewModel = {
                groupId: this.groupId,
                members: records,
                overwriteExisting: false,
              };

              this.showProgressIndicator(
                LoadingType.Panel,
                `Importing people, Please Wait...`
              );

              const service = new GroupService();
              service
                .importMembership(this.groupId, importViewModel)
                .then(() => {
                  this.fileReset();
                  this.hideProgressIndicator();
                  this.getItems(this.groupId);
                })
                .catch((error) => {
                  this.fileReset();
                  this.showErrorDialog(error);
                });
              reader.onerror = function () {};
            }
          }
        };
      } else {
        this.showConfirmationDialog(
          "Invalid File",
          "Please import a valid .csv file"
        );
        this.fileReset();
      }
    },

    isCSVFile(file: any): boolean {
      return file.name.endsWith(".csv");
    },

    isValidHeaders(headers: string[]): boolean {
      let isValid = false;

      isValid =
        headers.includes("OurRef") &&
        headers.includes("IsMember") &&
        headers.includes("FirstName") &&
        headers.includes("LastName") &&
        headers.includes("Gender") &&
        headers.includes("DateOfBirth") &&
        headers.includes("DateOfBaptism") &&
        headers.includes("DateOfFellowship") &&
        headers.includes("DateOfPassing") &&
        headers.includes("Address") &&
        headers.includes("PrimaryContactNo") &&
        headers.includes("SecondaryContactNo") &&
        headers.includes("PrimaryEmail") &&
        headers.includes("SecondaryEmail") &&
        headers.includes("Ethnicity") &&
        headers.includes("Nationality") &&
        headers.includes("MaritalStatus");

      return isValid;
    },

    getHeaderArray(header: string): string[] {
      let headers = header.split(",");
      let headerArray = [];
      for (let j = 0; j < headers.length; j++) {
        headerArray.push(headers[j]);
      }
      return headerArray;
    },

    getDataRecordsArrayFromCSVFile(
      csvRecordsArray: Array<any>,
      headerLength: any
    ): IImportExportMember[] {
      let csvArr = [];
      for (let i = 1; i < csvRecordsArray.length; i++) {
        let currentRecord = csvRecordsArray[i].toString().split(",");
        if (currentRecord.length == headerLength) {
          let firstName = currentRecord[2].trim();
          let lastName = currentRecord[3].trim();
          let gender = currentRecord[4].trim();

          if (firstName && lastName && gender) {
            let csvRecord: IImportExportMember = {
              ourRef: currentRecord[0].trim(),
              isMember: currentRecord[1].trim(),
              firstName: firstName,
              lastName: lastName,
              gender: gender,
              dateOfBirth: currentRecord[5].trim(),
              dateOfBaptism: currentRecord[6].trim(),
              dateOfFellowship: currentRecord[7].trim(),
              dateOfPassing: currentRecord[8].trim(),
              address: currentRecord[9].trim(),
              primaryContactNo: currentRecord[10].trim(),
              secondaryContactNo: currentRecord[11].trim(),
              primaryEmail: currentRecord[12].trim(),
              secondaryEmail: currentRecord[13].trim(),
              ethnicity: currentRecord[14].trim(),
              nationality: currentRecord[15].trim(),
              maritalStatus: currentRecord[16].trim(),
              currentStatus: currentRecord[17].trim(),
            };
            csvArr.push(csvRecord);
          }
        }
      }

      return csvArr;
    },

    fileReset() {
      let ref: any = this.$refs.csvReader;
      if (ref) {
        ref.value = "";
      }
    },

    async refreshFilterList() {
      const service = new RegisteredUserService();
      service
        .getPeopleAndGroupFilters(this.groupId)
        .then((userResponse) => {
          if (userResponse.data) {
            // Capture all the available filters for the user
            this.allFilters = userResponse.data;

            // Attempt to determine which was the previous people filter used so it can be displayed
            if (store.state.peopleSearchFilterId !== 0) {
              let match = userResponse.data.find(
                (f) => f.id == store.state.peopleSearchFilterId
              );
              if (match) {
                this.filter = match;
              } else {
                this.filter = userResponse.data[0];
              }
            } else {
              this.filter = userResponse.data[0];
            }

            // Set the filter id in state so it can be set next time
            store.commit(StateKeyName.SetPeopleSearchFilterId, this.filter.id);
          } else {
            this.filter = new PeopleSearchFilter();
            store.commit(StateKeyName.SetPeopleSearchFilterId, 0);
          }
        })
        .catch((error) => this.showErrorDialog(error));
    },

    async getAndShowColumns(currentFilterId: number) {
      try {
        const service = new RegisteredUserService();
        let selectedColumnsResponse = await service.getSelectedColumns(
          currentFilterId
        );

        this.selectedColumns = [];
        for (var item of selectedColumnsResponse.data) {
          this.selectedColumns.push(new MultiItemSelectionItem(item));
        }

        this.showSelectedColumns();
      } catch (error) {
        this.showErrorDialog(error);
      }
    },

    onMessageSenderDialogClosed() {
      this.showMessageSenderDialog = false;
    },

    onMessageSelected() {
      this.sendMessageViewModel = {
        recipients: this.selectedItems,
        subject: "",
        message: "",
        app: false,
        email: true,
        sms: false,
        attachmentUrls: [],        
      };

      this.showMessageSenderDialog = true;
    },

    generateNames(users: IUserListItem[]) {
      for (let user of users) {
        user.name = `${user.firstName} ${user.lastName}`;
      }
    },
  },
});
</script>
<style scoped>
img {
  border: 1px solid rgb(177, 172, 172);
  border-radius: 4px;
  padding: 5px;
  object-fit: cover;
  max-height: 64px;
  max-width: 64px;
  margin-left: 4px;
  margin-bottom: 4px;
}

.img-container {
  width: 64px;
  height: 64px;
  border: 1px solid rgb(124, 120, 120);
}
</style>