<template>
  <v-container fluid>
    <v-form ref="manageForm" v-model="valid" @submit.prevent="submitForm">
      <v-row>
        <v-col>
          <section class="page-header">
            <v-row>
              <v-col md="2">
                <v-text-field
                  outlined
                  filled
                  v-model="displayName"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="primary"
                      icon
                      @click="onCancel"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-arrow-left-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Back to list</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ma-2"
                      icon
                      outlined
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="refreshScreen"
                      ><v-icon>mdi-refresh</v-icon></v-btn
                    >
                  </template>
                  <span>Refresh</span>
                </v-tooltip>
                <v-btn
                  color="primary"
                  type="submit"
                  :disabled="!valid"
                  large
                  class="ma-1"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </section>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs v-model="tab" @change="onTabClick">
            <v-tab>General</v-tab>
            <v-tab :disabled="!canAddImages">Images</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs-items v-model="tab">
            <v-tab-item transition="false" eager>
              <galleries-general-section
                ref="galleriesgeneralsection"
                :id="id"
                :ownerId="ownerId"
                :formData="formData"
              >
              </galleries-general-section>
            </v-tab-item>
            <v-tab-item transition="false" eager>
              <galleries-image-section
                ref="galleriesimagesection"
                :galleryId="id"
                :groupId="ownerId"
                :formData="formData"
              >
              </galleries-image-section>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col md="auto">
        <action-alert
          :show="showAlert"
          :message="alertMessage"
          :type="alertType"
        ></action-alert>
      </v-col>
    </v-row>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      :errors="dialogErrors"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { ManageGroupGalleryViewModel } from "@/models/group.model";
import { AlertType, GroupType, LoadingType } from "@/models/common.model";
import { GroupService } from "@/services/group.service";
import EditorGalleriesGeneral from "@/views/editors/editor-galleries-general.vue";
import EditorGalleriesImages from "@/views/editors/editor-galleries-images.vue";
import ActionAlert from "@/components/common/action-alert.vue";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "HostGalleries",
  mixins: [CommonMixin],
  components: {
    "action-alert": ActionAlert,
    "galleries-general-section": EditorGalleriesGeneral,
    "galleries-image-section": EditorGalleriesImages
  },
  data() {
    return {
      tab: 0,
      valid: true,
      formData: new ManageGroupGalleryViewModel(),
      id: 0,
      ownerId: 0,
      branchId: 0,
      groupId: 0,
      groupType: "",
      tabIndex: 0,
    };
  },
  computed: {
    displayName(): string {
      let name: string = "";
      if (this.formData) {
        name = this.formData.title;
      }
      return name;
    },
    canAddImages(): boolean {
      return (this.id !== 0 && this.valid);
    }
  },
  created() {
    if (this.$route.query.branchId) {
      this.branchId = parseInt(this.$route.query.branchId.toString());
    }
    if (this.$route.query.groupId) {
      this.groupId = parseInt(this.$route.query.groupId.toString());
    }
    if (this.$route.query.id) {
      this.id = parseInt(this.$route.query.id.toString());
    }
    if (this.$route.query.groupType) {
      this.groupType = this.$route.query.groupType.toString();
    }
    if (this.$route.query.tab) {
      this.tabIndex = this.getTabIndex(this.$route.query.tab.toString());
    }

    this.ownerId =
      this.groupType === GroupType.Branch ||
      this.groupType == GroupType.NationalMinistry
        ? this.branchId
        : this.groupId;

    let title = this.getNavTitle();
    if (!title.endsWith("(Gallery)")) {
      this.updateNavTitle(`${this.getNavTitle()} (Gallery)`);
    }
    this.getItem();
  },
  methods: {
    refreshScreen() {
      this.getItem();
    },

    getTabIndex(name: string): number {
      let index = 0;

      switch (name) {
        case "general":
          index = 0;
          break;
        case "images":
          index = 1;
          break;
      }

      return index;
    },

    resetData() {
      this.tab = this.tabIndex;
      this.formData = new ManageGroupGalleryViewModel();

      const ref: any = this.$refs.manageForm;
      if (ref) {
        ref.validate();
      }
    },

    getItem() {
      this.resetData();

      this.showProgressIndicator(
        LoadingType.Panel,
        "Getting Gallery Details, Please Wait..."
      );

      const service = new GroupService();
      service
        .getGallery(this.ownerId, this.id)
        .then((response) => {
          this.hideProgressIndicator();
          this.formData = response.data;
        })
        .catch((error) => this.showErrorDialog(error));
    },

    submitForm() {
      this.showProgressIndicator(
        LoadingType.Panel,
        "Saving Gallery, Please Wait..."
      );

      const service = new GroupService();
      service
        .saveGallery(this.ownerId, this.formData)
        .then((response) => {
          this.formData = response.data;
          this.id = response.data.galleryId;
          this.showAlertPanel(
            "The gallery was saved successfully",
            AlertType.Success
          );
        })
        .catch((error) => this.showErrorDialog(error));
    },

    onCancel() {
      if (this.groupType == GroupType.NationalMinistry) {
        this.$router.replace({
          name: "host-national-ministries",
          query: {
            ministryId: this.branchId.toString(),
            groupId: this.groupId.toString(),
            groupType: this.groupType,
            tab: "galleries",
          },
        });
      } else {
        this.$router.replace({
          name: "host-groups",
          query: {
            branchId: this.branchId.toString(),
            groupId: this.groupId.toString(),
            groupType: this.groupType,
            tab: "galleries",
          },
        });
      }
    },

    onTabClick(index: any) {
      switch (index) {
        case 0:
          break;
        case 1:
          break;
      }
      this.tab = index;
    },
  },
});
</script>

<style scoped src="@/assets/css/wg.css"></style>