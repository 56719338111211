<template>
  <v-container fluid>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">1</span>Gallery Details</h6>
        <p class="small" style="padding-right: 40px">
          Name and describe your gallery so that everyone can experience the
          event with you.
        </p>
      </v-col>
      <v-col md="4">
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="viewModel.title"
              label="Title"
              placeholder="Title"
              outlined
              clearable
              hide-details="auto"
              maxlength="200"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              outlined
              clearable
              auto-grow
              hide-details="auto"
              label="Description"
              placeholder="Description"
              maxlength="800"
              rows="5"
              :rules="[rules.required]"
              v-model="viewModel.description"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { ManageGroupGalleryViewModel } from "@/models/group.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "EventsGeneralSection",
  mixins: [CommonMixin],
  props: {
    id: {
      type: Number,
      required: true,
    },
    ownerId: {
      type: Number,
      required: true,
    },
    formData: {
      type: Object,
      default: () => new ManageGroupGalleryViewModel(),
      required: true,
    },
  },

  watch: {
    formData: {
      deep: true,
      handler(val: ManageGroupGalleryViewModel) {
        this.viewModel = val;
      },
    },
  },

  data() {
    return {
      viewModel: new ManageGroupGalleryViewModel(),
    };
  },
});
</script>