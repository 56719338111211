<template>
  <div>
    <v-hover v-slot="{ hover }" open-delay="200">
      <v-card
        :elevation="hover ? 12 : 3"
        :outlined="hover"
        :class="{ 'on-hover': hover }"
      >
        <v-img
          height="192"
          width="192"
          :src="mainImageUrl"
          :lazy-src="placeholder"
          class="imageCursor"
          @click="showImageSelector"
        >
        </v-img>
      </v-card>
    </v-hover>
    <div style="height: 8px" />
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          outlined
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="$refs.file.click()"
          hide-details="auto"
        >
          <v-icon> mdi-file-upload-outline </v-icon>
        </v-btn>
      </template>
      <span>upload image</span>
    </v-tooltip>
    <input
      hidden
      type="file"
      ref="file"
      @change="uploadImage($event)"
      accept="image/*"
    />
    <approved-image-selector
      :dialog="showApprovedImageSelector"
      :multiSelect="false"
      :items="approvedImages"
      @dialog-option-selected="onApprovedImageSelection"
    >
    </approved-image-selector>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import ApprovedImageSelector from "@/components/common/approved-image-selector.vue";

import { IApprovedImage } from "@/models/conference.model";
import { ApprovedImages, LoadingType } from "@/models/common.model";
import { MediaService } from "@/services/media.service";
import { ConferenceService } from "@/services/conference.service";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ImageSelector",
  mixins: [CommonMixin],
  components: {
    "approved-image-selector": ApprovedImageSelector,
  },
  props: {
    imageUrl: {
      type: String,
      required: false,
    },
  },
  watch: {
    imageUrl(val: string) {
      this.mainImageUrl = val ? val : val ?? this.placeholder;
    },
  },
  data() {
    return {
      approvedImages: Array<IApprovedImage>(),
      showApprovedImageSelector: false,
      placeholder: require("@/assets/images/image-placeholder.png"),
      mainImageUrl: "",
    };
  },
  methods: {
    async uploadImage(event: any) {
      // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      URL.revokeObjectURL(this.mainImageUrl);

      const { files } = event.target;

      if (files && files[0]) {
        let fileAttachment = files[0];
        if (fileAttachment.size > 0) {
          this.showProgressIndicator(
            LoadingType.Panel,
            `Uploading image ${fileAttachment.name} for use, Please Wait...`
          );

          try {
            this.mainImageUrl = "";
            const service = new MediaService();
            const result = await service.uploadFile(
              fileAttachment,
              fileAttachment.name
            );
            if (result.status === 200) {
              let url = result.data;
              this.mainImageUrl = url;
            }
            this.hideProgressIndicator();
            if (this.mainImageUrl) {
              this.$emit("uploaded-image-selected", this.mainImageUrl);
            }
          } catch (error) {
            this.showErrorDialog(error);
            return;
          }
        }
      }
    },

    onApprovedImageSelection(items: IApprovedImage[]) {
      this.showApprovedImageSelector = false;
      if (items.length == 0) {
        this.$emit("approved-image-cancelled");
      } else {

        let url = "";
        if (items.length > 0) {
          url = items[0].imageUrl;
        }
        this.mainImageUrl = url ? url : this.mainImageUrl ?? this.placeholder;
        this.$emit("approved-image-selected", this.mainImageUrl);
      }
    },

    showImageSelector() {
      this.getApprovedImages();
    },

    getApprovedImages() {
      if (this.approvedImages.length === 0) {
        this.showProgressIndicator(
          LoadingType.Panel,
          "Getting Approved Images, Please Wait..."
        );

        // Get the list of approved images from the web platform as they are easier to see
        // in the CMS and the equivalent ones will be used when used in the mobile application
        const service = new ConferenceService();
        service
          .getApprovedImages(
            ApprovedImages.Platform.Web,
            ApprovedImages.Category.Events
          )
          .then((response) => {
            this.hideProgressIndicator();
            this.approvedImages = response.data;
            this.showApprovedImageSelector = true;
          })
          .catch((error) => this.showErrorDialog(error));
      } else {
        for (let item of this.approvedImages) {
          item.selected = false;
        }
        this.showApprovedImageSelector = true;
      }
    },
  },
});
</script>