<template>
  <v-container fluid>
    <v-row>
      <v-col lg="3" xl="2">
        <h6><span class="number">1</span>Position</h6>
        <p class="small" style="padding-right: 40px">
          This section details the position for a member within this group.
        </p>
      </v-col>
      <v-col md="auto">
        <v-row>
          <v-col>
            <v-checkbox
              v-model="formData.isMainPosition"
              label="Foremost Position Held"
              dense
              hide-details="auto"
            >
            </v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              v-model="formData.isPublic"
              label="Publicly Visible"
              dense
              hide-details="auto"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-if="isNationalMinistry"
              :disabled="!canEditMemberNameAndPosition"
              :items="members"
              item-text="name"
              item-value="id"
              v-model="selectedMember"
              outlined
              clearable
              label="Member"
              placeholder="Select a member"
              menu-props="auto"
              hide-details="auto"
              @change="onMemberChanged"
              return-object
            ></v-select>
            <v-autocomplete
              v-if="!isNationalMinistry"
              :disabled="!canEditMemberNameAndPosition"
              :items="members"
              :loading="isMatching"
              :search-input.sync="matching"
              item-text="name"
              item-value="id"
              v-model="selectedMember"
              outlined
              clearable
              label="Member"
              placeholder="Type the name"
              menu-props="auto"
              hide-details="auto"
              :error-messages="validateMemberSelection"
              @change="onMemberChanged"
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-select
              :disabled="!canEditMemberNameAndPosition"
              :items="formData.positions"
              v-model="formData.title"
              outlined
              clearable
              label="Position"
              placeholder="Position"
              menu-props="auto"
              hide-details="auto"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              label="Profile"
              placeholder="Provide a profile of this member in this position."
              auto-grow
              outlined
              counter
              clearable
              rows="4"
              hide-details="auto"
              maxlength="4000"
              v-model="formData.profile"
              :rules="[rules.required]"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <date-picker
              :date.sync="formData.dateStarted"
              :dateRules="validateDateStarted"
              label="Date Started"
            ></date-picker>
          </v-col>
          <v-col>
            <date-picker
              :date.sync="formData.expirationDate"
              :dateRules="validateDateExpires"
              label="Due To Expire On"
            ></date-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <date-picker
              :date.sync="formData.dateCompleted"
              :dateRules="validateDateCompleted"
              label="Date Completed"
            ></date-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              label="Reason for Completion"
              placeholder="Provide a reason for the completion of this position."
              auto-grow
              outlined
              counter
              clearable
              rows="3"
              hide-details="auto"
              maxlength="4000"
              v-model="formData.reasonForCompletion"
              :error-messages="validateReasonForCompletion"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <confirmation-dialog
      :dialog="showDialog"
      :data="dialogMessage"
      @dialog-option-selected="onDialogOptionSelected"
    ></confirmation-dialog>
    <progress-indicator
      :showProgressBar="showProgressBar"
      :showSpinner="showSpinner"
      :message="progressMessage"
    >
    </progress-indicator>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import dateTimePicker from "@/components/common/date-time-picker.vue";
import { DateTime } from "luxon";
import DatePicker from "@/components/common/date-picker.vue";
import { GroupService } from "@/services/group.service";
import {
  ManagePositionViewModel,
  MemberSearchSuggestion,
} from "@/models/group.model";
import { GroupType } from "@/models/common.model";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ManagePosition",
  mixins: [CommonMixin],
  components: {
    dateTimePicker,
    "date-picker": DatePicker,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    ownerId: {
      type: Number,
      required: true,
    },
    groupType: {
      type: String,
      required: true,
    },
    formData: {
      type: Object,
      default: () => new ManagePositionViewModel(),
      required: true,
    },
  },
  created() {
    this.onCreation = true;
  },

  data() {
    return {
      valid: true,
      isMatching: false,
      matching: null,
      canEditMemberNameAndPosition: true,
      selectedMember: new MemberSearchSuggestion(),
      members: new Array<MemberSearchSuggestion>(),
      onCreation: false,
    };
  },
  watch: {
    formData: {
      deep: true,
      handler(val: ManagePositionViewModel) {
        if (val) {
          if (val.id !== 0 && val.heldById !== 0) {
            const suggestion: MemberSearchSuggestion =
              new MemberSearchSuggestion();
            suggestion.id = val.heldById;
            suggestion.name = val.heldBy;
            this.selectedMember = suggestion;
            this.members = [suggestion];
          } else if (this.isNationalMinistry) {
            const service = new GroupService();
            service
              .getGroupMembers(this.ownerId, 0, null)
              .then((response) => {
                this.hideProgressIndicator();
                this.members = response.data.map((m) => {
                  var item = new MemberSearchSuggestion();
                  item.id = m.id;
                  item.name = m.knownAs;
                  return item;
                });
              })
              .catch((error) => {
                this.showErrorDialog(error);
              });
          }
          this.canEditMemberNameAndPosition = val.id == 0;
        }
      },
    },
    matching(val: string) {
      if (this.onCreation) {
        this.onCreation = false;
        return;
      }
      if (this.isMatching) {
        return;
      }

      if (val) {
        this.isMatching = true;
        const service = new GroupService();
        service
          .getMemberSuggestion(this.ownerId, val)
          .then((response) => {
            this.hideProgressIndicator();
            this.members = response.data;
          })
          .catch((error) => {
            this.showErrorDialog(error);
          })
          .finally(() => (this.isMatching = false));
      } else {
        this.members = [];
      }
    },
  },
  computed: {
    validateMemberSelection(): string[] {
      let err: string[] = [];
      if (this.selectedMember.id === 0) {
        err.push("You must select a member for the position");
      }
      return err;
    },

    validateReasonForCompletion(): string[] {
      let err: string[] = [];
      if (this.formData.reasonForCompletion) {
        if (!this.formData.dateCompleted) {
          err.push("A completion date must be given");
        }
      }
      return err;
    },
    validateDateStarted(): string[] {
      let err: string[] = [];
      if (this.formData.dateStarted) {
        let start = DateTime.fromISO(this.formData.dateStarted.toString());
        if (start > DateTime.now()) {
          err.push("Date position started cannot be in the future.");
        }
      } else {
        err.push("You must provide a value");
      }
      return err;
    },
    validateDateCompleted(): string[] {
      let err: string[] = [];
      if (this.formData.dateCompleted) {
        let start = DateTime.fromISO(this.formData.dateStarted.toString());
        let end = DateTime.fromISO(this.formData.dateCompleted.toString());
        if (start > end) {
          err.push("The date the position ends is before the start date.");
        } else if (end > DateTime.now()) {
          err.push("Date the position ended cannot be in the future.");
        }
      }
      return err;
    },
    validateDateExpires(): string[] {
      let err: string[] = [];
      if (this.formData.expirationDate) {
        let start = DateTime.fromISO(this.formData.dateStarted.toString());
        let end = DateTime.fromISO(this.formData.expirationDate.toString());
        if (start > end) {
          err.push("The date the position expires is before the start date.");
        }
      }
      return err;
    },
    isNationalMinistry(): boolean {
      return this.groupType == GroupType.NationalMinistry;
    },
  },
  methods: {
    onMemberChanged(item: any) {
      if (item) {
        this.formData.heldById = item.id;
        this.formData.heldBy = item.name;
      }
    },
  },
});
</script>
