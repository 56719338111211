var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-click": false,
        "close-on-content-click": false,
        activator: _vm.selectedElement,
        "nudge-left": _vm.nudgeLeft,
        "max-width": _vm.maxEventInfoDialogWidth,
        rounded: "lg",
        transition: "slide-x-transition",
        "offset-x": "",
        left: "",
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        {
          attrs: {
            width: _vm.maxEventInfoDialogWidth,
            "min-height": "180px",
            elevation: "5",
          },
        },
        [
          _c(
            "v-toolbar",
            {
              attrs: { flat: "", dense: "", elevation: "1", color: "#E5E3E3" },
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    icon: "",
                                    disabled: _vm.disableToolbar,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showEditEventScreen()
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v(" mdi-pencil-outline ")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Edit event")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.eventIsCancelled,
                                      expression: "!eventIsCancelled",
                                    },
                                  ],
                                  attrs: {
                                    icon: "",
                                    disabled: _vm.disableToolbar,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelEvent()
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v(" mdi-calendar-remove ")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Cancel event")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.eventIsCancelled,
                                      expression: "eventIsCancelled",
                                    },
                                  ],
                                  attrs: {
                                    icon: "",
                                    disabled: _vm.disableToolbar,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.unCancelEvent()
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v(" mdi-calendar ")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Un-Cancel event")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.isPublished,
                                      expression: "!isPublished",
                                    },
                                  ],
                                  attrs: {
                                    icon: "",
                                    disabled: _vm.disableToolbar,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.publishEvent()
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v(" mdi-publish ")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Publish event")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isPublished,
                                      expression: "isPublished",
                                    },
                                  ],
                                  attrs: {
                                    icon: "",
                                    disabled: _vm.disableToolbar,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.unPublishEvent()
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v(" mdi-publish-off ")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Un-Publish event")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isPublished,
                                      expression: "isPublished",
                                    },
                                  ],
                                  attrs: {
                                    icon: "",
                                    disabled: _vm.disableToolbar,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.notifyEvent()
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v(" mdi-bell-ring ")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Notify event")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    icon: "",
                                    disabled: _vm.disableToolbar,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteEvent()
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v(" mdi-trash-can-outline ")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Delete event")])]
              ),
              _c("v-spacer"),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.closeEventInfoDialog()
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v(" mdi-close ")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Close")])]
              ),
            ],
            1
          ),
          _c("v-card-title", [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.name) } }),
          ]),
          _c("v-card-subtitle", [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.rangeString) } }),
          ]),
          _c("v-card-text", [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.summary) } }),
            _c("span", [
              _c("a", { attrs: { href: _vm.url, target: "_blank" } }, [
                _vm._v(_vm._s(_vm.location)),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }