var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "2" } },
            [
              _c(
                "v-btn",
                { attrs: { color: "success" }, on: { click: _vm.onNewItem } },
                [_vm._v("New Quarterly Return Record")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.years,
                  label: "Year",
                  placeholder: "Select the year",
                  "menu-props": "auto",
                  "hide-details": "auto",
                  outlined: "",
                  clearable: "",
                },
                on: { change: _vm.onFilterOptionsChange },
                model: {
                  value: _vm.selectedYear,
                  callback: function ($$v) {
                    _vm.selectedYear = $$v
                  },
                  expression: "selectedYear",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showEmptyView,
                      expression: "showEmptyView",
                    },
                  ],
                  attrs: { elevation: "0" },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { md: "4" } }, [
                        _vm._v(
                          " It seems that you have not created a quarterly return record yet. Create your first one by clicking the New Record. "
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c("lottie-player", {
                            staticStyle: { width: "400px", height: "400px" },
                            attrs: {
                              src: "https://assets3.lottiefiles.com/private_files/lf30_bn5winlb.json",
                              background: "transparent",
                              speed: "1",
                              loop: "",
                              autoplay: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showGrid,
                      expression: "showGrid",
                    },
                  ],
                },
                [
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { md: "4" } }, [
                            _vm._v(
                              " Quarterly Returns - " + _vm._s(_vm.heading)
                            ),
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { md: "1" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "success" },
                                  on: { click: _vm.onDownloadButtonClicked },
                                },
                                [_vm._v("Export To Excel")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      "item-key": "id",
                      headers: _vm.headers,
                      items: _vm.items,
                      "single-select": false,
                      "show-select": _vm.showMultiSelect,
                      "items-per-page": _vm.itemsPerPage,
                      "footer-props": _vm.footerOptions,
                      options: _vm.options,
                      "server-items-length": _vm.totalItems,
                    },
                    on: {
                      "update:itemsPerPage": function ($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:items-per-page": function ($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.actions",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              color: "error",
                                                              icon: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onDeleteItem(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            " mdi-delete "
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Click here to remove"),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                              icon: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onEditItem(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            " mdi-file-document-edit-outline "
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Click here to edit"),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.quarter",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formatNumber(item.quarter, 2)) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.currentAccountsTotalBalance",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      item.currentAccountsTotalBalance
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.depositAccountsTotalBalance",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      item.depositAccountsTotalBalance
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.creditUnionAccountBalance",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      item.creditUnionAccountBalance
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.otherAccountsBalance",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      item.otherAccountsBalance
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.buildingValuationInsuranceValue",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      item.buildingValuationInsuranceValue
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.buildingValuationMarketValue",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      item.buildingValuationMarketValue
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.otherAssetsValuation",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      item.otherAssetsValuation
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.otherLongTermInvestments",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      item.otherLongTermInvestments
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.totalTithesAndOffering",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      item.totalTithesAndOffering
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.totalOtherIncome",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatCurrency(item.totalOtherIncome)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.totalGiftAidReceipts",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      item.totalGiftAidReceipts
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.totalLoansRecovered",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatCurrency(item.totalLoansRecovered)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.totalRunningExpenses",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      item.totalRunningExpenses
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.extraordinaryProjectsExpenditure",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      item.extraordinaryProjectsExpenditure
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.totalConferenceContributions",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      item.totalConferenceContributions
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.conventionFees",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatCurrency(item.conventionFees)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.loansIssued",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formatCurrency(item.loansIssued)) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.selectedItems,
                      callback: function ($$v) {
                        _vm.selectedItems = $$v
                      },
                      expression: "selectedItems",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDeleteDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDeleteOptionSelected },
      }),
      _c("confirmation-dialog", {
        attrs: { dialog: _vm.showDialog, data: _vm.dialogMessage },
        on: { "dialog-option-selected": _vm.onDialogOptionSelected },
      }),
      _c("progress-indicator", {
        attrs: {
          showProgressBar: _vm.showProgressBar,
          showSpinner: _vm.showSpinner,
          message: _vm.progressMessage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }