<template>
  <v-container fluid>
    <list-galleries
      v-show="showListView"
      ref="listitems"
      @new-item-requested="onNewItem"
      @show-item="onShowItem"
    ></list-galleries>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import CommonMixin from "@/mixins/common.mixin";
import { GroupType } from "@/models/common.model";
import ListGalleries from "@/views/lists/list-galleries.vue";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CommonMixin>>
).extend({
  name: "ListManagerGalleries",
  mixins: [CommonMixin],
  components: {
    "list-galleries": ListGalleries,
  },
  data() {
    return {
      showListView: true,
      groupType: GroupType.Branch,
      branchId: 0,
      groupId: 0,
      id: 0,
    };
  },
  methods: {
    showSuccessPanel(message: string, type: string) {
      this.$emit("show-alert-panel", message, type);
    },

    getItems(branchId: number, groupId: number, groupType: string) {
      this.groupType = groupType;
      this.branchId = branchId;
      this.groupId = groupId;

      this.showListView = true;

      const ref: any = this.$refs.listitems;
      if (ref) {
        ref.getItems(
          groupType == GroupType.Branch ||
            groupType == GroupType.NationalMinistry
            ? this.branchId
            : this.groupId
        );
      }
    },

    onNewItem() {
      this.id = 0;
      this.showListView = false;
      this.$router.push({
        name: "host-galleries",
        query: {
          branchId: this.branchId.toString(),
          groupId: this.groupId.toString(),
          id: this.id.toString(),
          groupType: this.groupType,
        },
      });
    },

    onShowItem(id: number) {
      this.id = id;
      this.showListView = false;
      this.$router.push({
        name: "host-galleries",
        query: {
          branchId: this.branchId.toString(),
          groupId: this.groupId.toString(),
          id: this.id.toString(),
          groupType: this.groupType,
        },
      });
    },
  },
});
</script>