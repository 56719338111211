<template>
  <v-container fluid>
    <div style="padding: 5px; text-align: left">
      <v-btn click="addPanel" color="primary">Add Panel</v-btn>
    </div>
    <ejs-dashboardlayout
      ref="dashboard"
      :allowResizing="true"
      :columns="6"
      :cellSpacing="[10, 10]"
      :resizableHandles="[
        'e-south-east',
        'e-east',
        'e-west',
        'e-north',
        'e-south',
      ]"
    >
      <dashboard-panel
        v-for="(panel, index) in panels"
        :key="index"
        :id="panel.id"
        :title="panel.title"
        :row="panel.row"
        :col="panel.col"
        :rowSpan="panel.rowSpan"
        :colSpan="panel.colSpan"
      >
      </dashboard-panel>
      <!--
      <div
        id="panel-1"
        class="e-panel"
        data-row="0"
        data-col="0"
        data-sizeX="1"
        data-sizeY="1"
      >
        <div class="e-panel-container">
          <div class="text-align" style="height: 100%">
            <demographics-panel :queryId="3028"> </demographics-panel>
          </div>
        </div>
      </div>
      <div
        id="panel-2"
        class="e-panel"
        data-row="0"
        data-col="1"
        data-sizeX="1"
        data-sizeY="1"
      >
        <div class="e-panel-container">
          <div class="text-align" style="height: 100%">
            <demographics-panel :queryId="3027"> </demographics-panel>
          </div>
        </div>
      </div>
      <div
        id="panel-3"
        class="e-panel"
        data-row="0"
        data-col="2"
        data-sizeX="1"
        data-sizeY="1"
      >
        <div class="e-panel-container">
          <div class="text-align" style="height: 100%">
            <demographics-panel :queryId="3030"> </demographics-panel>
          </div>
        </div>
      </div>

      <div
        id="panel-4"
        class="e-panel"
        data-row="1"
        data-col="0"
        data-sizeX="1"
        data-sizeY="1"
      >
        <div class="e-panel-container">
          <div class="text-align" style="height: 100%">
            <demographics-panel :queryId="3029"> </demographics-panel>
          </div>
        </div>
      </div>
    -->
    </ejs-dashboardlayout>
  </v-container>
</template>

<script>
import Vue from "vue";
import {
  PanelsDirective,
  PanelDirective,
  DashboardLayoutPlugin,
} from "@syncfusion/ej2-vue-layouts";
import DemographicsQueryDashboardPanel from "@/components/dashboard/demographics-query-dashboard-panel.vue";
import DashboardPanel from "@/components/dashboard/dashboard-panel.vue";

Vue.use(DashboardLayoutPlugin);

export default Vue.extend({
  name: "HostDashboard",
  components: {
    "demographics-panel": DemographicsQueryDashboardPanel,
    "e-panel": PanelDirective,
    "e-panels": PanelsDirective,
    "dashboard-panel": DashboardPanel,
  },
  data() {
    return {
      panels: [
        { id: "panel-1", title: 'Panel-1', row: 0, col: 0, colSpan: 1, rowSpan: 1 },
        { id: "panel-2", title: 'Panel-2', row: 0, col: 1, colSpan: 1, rowSpan: 1 },
        { id: "panel-3", title: 'Panel-3', row: 1, col: 0, colSpan: 1, rowSpan: 1 },
        { id: "panel-4", title: 'Panel-4', row: 1, col: 1, colSpan: 1, rowSpan: 1 },
      ],
    };
  },
  created() {
    this.buildDashboard();
  },

  methods: {
    buildDashboard() {},
  },
});
</script>

<style>
.dashboard-default
  #defaultLayout.e-dashboardlayout.e-control
  .e-panel:hover
  span {
  display: block;
}

.dashboard-default #defaultLayout .e-panel .e-panel-container .text-align {
  vertical-align: middle;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.dashboard-default .e-template-icon {
  padding: 10px;
  float: right;
  display: none;
}

.dashboard-default .e-clear-icon::before {
  content: "\e7a7";
  font-size: 12px;
  font-family: "e-icons";
}
.bootstrap4 .dashboard-default .e-clear-icon::before {
  content: "\e745";
}

.tailwind .dashboard-default .e-clear-icon::before,
.tailwind-dark .dashboard-default .e-clear-icon::before {
  content: "\e771";
  font-size: 16px;
}

.bootstrap5 .dashboard-default .e-clear-icon::before,
.bootstrap5-dark .dashboard-default .e-clear-icon::before {
  content: "\e7e7";
  font-size: 16px;
}

.dashboard-default .text-align {
  line-height: 160px;
}

.dashboard-default .e-clear-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
}
/* high contrast style */
body.highcontrast
  .dashboard-default
  #defaultLayout.e-dashboardlayout.e-control
  .e-panel {
  background: #000;
}

body.tailwind-dark .e-dashboardlayout.e-control .e-panel {
  border: 1px solid #d7d7d7;
}
</style>
