import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";

export class EventViewModel {
  ownerId: number | null;
  id: number | null;
  title: string;
  subtitle: string;
  description: string;
  startDateTime: Date;
  endDateTime: Date;
  lastEntryTime: Date | null;
  mainImageUrl: string;
  videoUrl: string | null;
  isCancelled: boolean;
  dateCancelled: Date | null;
  isPublished: boolean;
  isOnNationalCalendar: boolean;
  promotionStart: Date | null;
  promotionEnd: Date | null;
  instagramHashTag: string;
  surveyUrl: string;
  registrationUrl: string;
  letters: EventLetter[];
  organisedBy: EventOrganiser | null;
  contacts: EventContact[];
  locations: EventLocation[];
  onlineLocations: EventOnlineLocation[];
  isHeldInPhysicalLocation: boolean;
  isHeldOnline: boolean;
  eventType: string;
  category: string | null;
  isRecurring: boolean;
  recurrenceDayOfMonth: number;
  recurrenceSunday: boolean;
  recurrenceMonday: boolean;
  recurrenceTuesday: boolean;
  recurrenceWednesday: boolean;
  recurrenceThursday: boolean;
  recurrenceFriday: boolean;
  recurrenceSaturday: boolean;
  recurrenceInstance: number;
  recurrenceInterval: number;
  recurrenceMonthOfYear: number;
  recurrenceOccurrences: number;
  recurrenceStartDate: Date | null;
  recurrenceEndDate: Date | null;
  recurrenceType: number;
  platforms: string[];
  eventTypes: string[];
  potentialOrganisers: EventOrganiser[];
  updatedOn: Date | null;

  constructor() {
    this.ownerId = null;
    this.id = null;
    this.title = "";
    this.subtitle = "";
    this.description = "";
    this.category = "";
    this.eventType = "";
    this.eventTypes = [];
    this.mainImageUrl = "";
    this.startDateTime = DateTime.now().toJSDate();
    this.endDateTime = DateTime.now().plus({ hours: 1 }).toJSDate();
    this.lastEntryTime = null;
    this.videoUrl = '';
    this.isCancelled = false;
    this.dateCancelled = null;
    this.isPublished = false;
    this.isOnNationalCalendar = false;
    this.promotionStart = null;
    this.promotionEnd = null;
    this.instagramHashTag = '';
    this.surveyUrl = '';
    this.registrationUrl = '';
    this.letters = [];
    this.organisedBy = null;
    this.contacts = [];
    this.locations = [];
    this.isRecurring = false;
    this.isHeldOnline = false;
    this.isHeldInPhysicalLocation = false;
    this.platforms = [];
    this.onlineLocations = [];
    this.recurrenceSunday = false;
    this.recurrenceMonday = false;
    this.recurrenceTuesday = false;
    this.recurrenceWednesday = false;
    this.recurrenceThursday = false;
    this.recurrenceFriday = false;
    this.recurrenceSaturday = false;
    this.recurrenceInterval = 0;
    this.recurrenceInstance = 0;
    this.recurrenceDayOfMonth = 0;
    this.recurrenceMonthOfYear = 0;
    this.recurrenceType = 0;
    this.recurrenceOccurrences = 0;
    this.recurrenceStartDate = null;
    this.recurrenceEndDate = null;
    this.potentialOrganisers = [];
    this.updatedOn = null;
  }
}

export class EventLocation {
  id: number;
  isPrimaryLocation: boolean;
  category: string;
  title: string;
  houseNameOrNumber: string;
  street: string;
  city: string;
  region: string;
  country: string;
  postCode: string;
  url: string;
  longitude: number | null;
  latitude: number | null;
  startDateTime: Date | null;
  endDateTime: Date | null;
  address: string;
  isOnline: boolean;
  platform: string;
  linkUrl: string;
  dialInNumber: string;
  dialInCode: string;
  meetingCode: string;
  meetingId: string;  
  
  localId: string;
  allowDialIn: boolean;

  constructor() {
    this.id = 0;
    this.isPrimaryLocation = false;
    this.category = '';
    this.title = '';
    this.houseNameOrNumber = '';
    this.street = '';
    this.city = '';
    this.region = '';
    this.country = '';
    this.postCode = '';
    this.url = '';
    this.longitude = null;
    this.latitude = null;
    this.startDateTime = null;
    this.endDateTime = null;
    this.address = '';
    this.isOnline = false;
    this.platform = '';
    this.linkUrl = '';
    this.dialInNumber = '';
    this.dialInCode = '';
    this.meetingCode = '';
    this.meetingId = '';    
    
    this.localId = uuidv4();
    this.allowDialIn = false;
  }
}

export class EventOnlineLocation {
  id: number;
  platform: string;
  linkUrl: string;
  dialInNumber: string;
  dialInCode: string;
  meetingCode: string;
  meetingId: string;
  startDateTime: Date | null;
  endDateTime: Date | null;

  localId: string;
  allowDialIn: boolean;

  constructor() {
    this.id = 0;
    this.platform = '';
    this.linkUrl = '';
    this.dialInNumber = '';
    this.dialInCode = '';
    this.meetingCode = '';
    this.meetingId = '';
    this.startDateTime = null;
    this.endDateTime = null;

    this.localId = uuidv4();
    this.allowDialIn = false;
  }
}

export class EventOrganiser {
  id: number | null;
  organisation: string;
  contactName: string;
  imageurl: string;

  constructor() {
    this.id = null;
    this.organisation = '';
    this.contactName = '';
    this.imageurl = '';
  }
}

export class EventContact {
  id: string;
  email: string;
  contactNumber: string;

  constructor() {
    this.id = uuidv4();
    this.email = '';
    this.contactNumber = '';
  }
}

export class EventListItem {
  id: number;
  ownerId?: number | undefined;
  title: string;
  subtitle: string;
  address: string;
  startDateTime: Date;
  endDateTime: Date;
  startDateTimeDisplay: string;
  endDateTimeDisplay: string;
  location?: EventLocation;
  isRecurrenceCreator: boolean;
  recurrenceCreatorId: number;
  isCancelled: boolean;
  isPublished: boolean;
  isOnNationalCalendar: boolean;
  updatedOn: Date | null;
  isHeldInPhysicalLocation: boolean;
  isHeldOnline: boolean;
  organiserGroupName: string;

  constructor() {
    this.id = 0;
    this.title = '';
    this.subtitle = '';
    this.address = '';
    this.startDateTime = new Date();
    this.endDateTime = new Date();
    this.startDateTimeDisplay = '';
    this.endDateTimeDisplay = '';
    this.isRecurrenceCreator = false;
    this.recurrenceCreatorId = 0;
    this.isCancelled = false;
    this.isPublished = false;
    this.isOnNationalCalendar = false;
    this.updatedOn = null;
    this.isHeldInPhysicalLocation = false;
    this.isHeldOnline = false;
    this.organiserGroupName = '';
  }
}

export class EventLetter {
  id: number;
  title: string;
  publishedOn: Date | null;
  letterUrl: string | null;
  updatedOn: Date | null;

  constructor() {
    this.id = 0;
    this.title = '';
    this.publishedOn = null;
    this.letterUrl = null;
    this.updatedOn = null;
  }
}

export interface IPlaceResultData {
  address_components: [
    {
      long_name: string;
      short_name: string;
      types: string[];
    }
  ];
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
    viewport: {
      south: number;
      west: number;
      north: number;
      east: number;
    };
  };
  url: string;
  formatted_address: string;
  name: string;
  reference: string;
}

export interface ICalendarStart {
  date: string;
  time: string;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  weekday: number;
  hasDay: boolean;
  hasTime: boolean;
  past: boolean;
  present: boolean;
  future: boolean;
}

export interface ICalendarEvent {
  id: number;
  name: string;
  start: any;
  end: any;
  color: string;
  timed: boolean;
  rangeString: string;
  summary: string;
  tag: EventListItem;
}

export interface IImageListItem {
  id: number;
  imageUrl: string;
  selected: boolean | null | undefined;
}

export interface IUploadImagesCommand {
  id: number;
  imageUrls: string[];
}

export interface IPatchEventCommand {
  id: number;
}

export interface IRemoveSelectedGalleryImagesCommand {
  id: number;
  imageIds: number[];
}

export interface IUpdateImageCommand {
  id: number;
  imageUrl: string;
}

export interface ISaveEventLetterCommand {
  id: number;
  letter: EventLetter;
}

export interface IRemoveEventLetterCommand {
  id: number;
  letterId: number;
}