var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticStyle: { padding: "5px", "text-align": "left" } },
        [
          _c("v-btn", { attrs: { click: "addPanel", color: "primary" } }, [
            _vm._v("Add Panel"),
          ]),
        ],
        1
      ),
      _c(
        "ejs-dashboardlayout",
        {
          ref: "dashboard",
          attrs: {
            allowResizing: true,
            columns: 6,
            cellSpacing: [10, 10],
            resizableHandles: [
              "e-south-east",
              "e-east",
              "e-west",
              "e-north",
              "e-south",
            ],
          },
        },
        _vm._l(_vm.panels, function (panel, index) {
          return _c("dashboard-panel", {
            key: index,
            attrs: {
              id: panel.id,
              title: panel.title,
              row: panel.row,
              col: panel.col,
              rowSpan: panel.rowSpan,
              colSpan: panel.colSpan,
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }